import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import GenderScreen from './screens/GenderScreen';
import IntroScreen from './screens/IntroScreens';
import PurposeScreen from './screens/PurposeSceen';
import BadgeSelectionScreen from './screens/BadgeSelectionScreen';
import ImageUploadScreen from './screens/ImageUploadScreen';
import InfoScreen from './screens/InfoScreen';
import ExistingUserCheckScreen from './screens/ExistingUserCheckScreen';
import PhoneVerificationScreen from './screens/PhoneVerificationScreen';
import { updateUserDocument } from '../../../shared/services/userService';
import { useAuth } from '../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { standardizeUserId } from '../../../shared/utils/userHelpers';

const Wizard = () => {
    const location = useLocation();
    const { id, name, date } = location.state?.data || {};
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isExistingUser, setIsExistingUser] = useState<boolean | null>(null);

    const [formData, setFormData] = useState({
        firstName: /^[+]?[\d\s()-]{7,}$/.test(user?.multiFactor?.user?.displayName || '')
            ? ''
            : user?.multiFactor?.user?.displayName || '',
        birthday: { day: '', month: '', year: '' },
        phoneNumber: user?.multiFactor?.user?.phoneNumber || user?.multiFactor?.user?.displayName || '',
        gender: '',
        purpose: [] as string[],
        badges: [] as string[],
        image: user?.multiFactor?.user?.photoURL && !user?.multiFactor?.user?.photoURL.includes('cdn.auth0.com/avatars/')
            ? user.multiFactor.user.photoURL
            : null,
        bio: '',
        socialMediaUrl: '',
        occupation: ''
    });

    // Restore state from localStorage on component mount
    useEffect(() => {
        const savedStep = localStorage.getItem('wizardStep');
        const savedFormData = localStorage.getItem('wizardFormData');
        const savedIsExistingUser = localStorage.getItem('isExistingUser');

        if (savedStep !== null) {
            setStep(JSON.parse(savedStep));
        }

        if (savedFormData !== null) {
            const parsedData = JSON.parse(savedFormData);
            parsedData.socialMediaUrl = '';
            setFormData(parsedData);
        }

        if (savedIsExistingUser !== null) {
            setIsExistingUser(JSON.parse(savedIsExistingUser));
        }
    }, []);

    // Save current state to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('wizardStep', JSON.stringify(step));
        localStorage.setItem('wizardFormData', JSON.stringify(formData));
        localStorage.setItem('isExistingUser', JSON.stringify(isExistingUser));
        localStorage.setItem('OnboardingProcess', 'true');
    }, [step, formData, isExistingUser]);

    const updateFormData = (field: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleExistingUserCheck = async (isExisting: boolean) => {
        setIsExistingUser(isExisting);
        if (isExisting) {
            // Show phone verification modal
            setStep(1); // Move to phone verification step
        } else {
            // Continue with regular onboarding
            setStep(2); // Skip to regular onboarding steps
        }
    };

    const handlePhoneVerification = async (phoneNumber: string) => {
        try {
            setLoading(true);
            // Check if user exists with this phone number
            const db = getFirestore();
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('phoneNumber', '==', phoneNumber));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // User exists, load their data
                const userData = querySnapshot.docs[0].data();
                
                // Update the current user's document with the existing user's data
                const currentUserId = user?.multiFactor?.user?.uid;
                const standardizedUserId = standardizeUserId(currentUserId);
                if (standardizedUserId) {
                    await updateUserDocument(standardizedUserId, {
                        ...userData,
                        phoneNumber: phoneNumber // Ensure phone number is updated
                    });
                }
                
                // Update local user state
                updateUser(userData);
                
                // Clear wizard data since we're using existing account
                localStorage.removeItem('wizardStep');
                localStorage.removeItem('wizardFormData');
                localStorage.removeItem('isExistingUser');
                localStorage.setItem('OnboardingProcess', 'true');

                // Navigate to events
                const savedEventId = id || localStorage.getItem('selectedEventId');
                if (savedEventId) {
                    navigate(`/events/${savedEventId}`, { state: { isRegister: true } });
                } else {
                    navigate('/events');
                }
            } else {
                // User doesn't exist, save phone number and continue with regular onboarding
                setFormData(prev => ({
                    ...prev,
                    phoneNumber: phoneNumber
                }));
                setStep(2);
            }
        } catch (error) {
            console.error('Error checking existing user:', error);
        } finally {
            setLoading(false);
        }
    };

    const registerUser = async () => {
        setLoading(true);
        try {
            let phoneNumber = user?.multiFactor?.user?.phoneNumber || user?.multiFactor?.user?.displayName || "";

            if (phoneNumber) {
                formData.phoneNumber = phoneNumber;
            }

            const response = await updateUserDocument(user.multiFactor.user.uid, formData);
            updateUser(response);
            
            // Clear wizard data
            localStorage.removeItem('wizardStep');
            localStorage.removeItem('wizardFormData');
            localStorage.removeItem('isExistingUser');
            localStorage.setItem('OnboardingProcess', 'true');

            // Navigate to events
            const savedEventId = id || localStorage.getItem('selectedEventId');
            if (savedEventId) {
                navigate(`/events/${savedEventId}`, { state: { isRegister: true } });
            } else {
                navigate('/events');
            }
        } catch (error) {
            console.error('Error registering user:', error);
        } finally {
            setLoading(false);
        }
    };

    const steps = [
        <ExistingUserCheckScreen
            onYes={() => handleExistingUserCheck(true)}
            onNo={() => handleExistingUserCheck(false)}
        />,
        <PhoneVerificationScreen
            onVerify={handlePhoneVerification}
            onBack={() => setStep(step - 1)}
        />,
        <IntroScreen
            onNext={() => setStep(step + 1)}
            setFirstName={(value) => updateFormData('firstName', value)}
            setBirthday={(value) => updateFormData('birthday', value)}
            firstName={formData.firstName}
            birthday={formData.birthday}
            skipButton={<Button onClick={() => setStep(step + 1)}>Skip</Button>}
        />,
        <GenderScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setGender={(value) => updateFormData('gender', value)}
            gender={formData.gender}
            name={formData.firstName}
            skipButton={<Button onClick={() => setStep(step + 1)}>Skip</Button>}
        />,
        <PurposeScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setPurpose={(value) => updateFormData('purpose', value)}
            selectedPurpose={formData.purpose}
            skipButton={<Button onClick={() => setStep(step + 1)}>Skip</Button>}
        />,
        <BadgeSelectionScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            setBadges={(value) => updateFormData('badges', value)}
            selectedBadges={formData.badges || []}
            skipButton={<Button onClick={() => setStep(step + 1)}>Skip</Button>}
        />,
        <ImageUploadScreen
            onNext={() => setStep(step + 1)}
            onBack={() => setStep(step - 1)}
            onImageUpload={(value) => updateFormData('image', value)}
            uploadedImage={formData.image}
        />,
        <InfoScreen
            onNext={registerUser}
            onBack={() => setStep(step - 1)}
            onUpdate={(field, value) => updateFormData(field, value)}
            bio={formData.bio}
            occupation={formData.occupation}
            socialMediaUrl={formData.socialMediaUrl}
        />,
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowX: 'hidden',
                position: 'relative',
                minHeight: 'calc(100vh - 56px)',
                marginTop: '56px',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                steps[step]
            )}
        </Box>
    );
};

export default Wizard;