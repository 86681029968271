import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken, onAuthStateChanged, updateProfile } from "firebase/auth";
import { fetchUserByDocumentId, updateUserDocument } from '../services/userService';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

// Add phone number validation function
const isValidPhoneNumber = (str: string): boolean => {
    // Remove any non-digit characters
    const cleaned = str.replace(/\D/g, '');
    // Check if it's a valid length (assuming international format)
    return cleaned.length >= 10 && cleaned.length <= 15;
};

// Add function to normalize user ID
const normalizeUserId = (userId: string): string => {
    // Replace underscore with pipe in the ID
    return userId.replace('_', '|');
};

interface Auth0ToFirebaseResponse {
  firebaseToken: string;
}

const AuthCallback = () => {
  console.log('🚀 AuthCallback Component Mounted');
  const location = useLocation();
  const { isAuthenticated, user, isLoading, error, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [isNewUser, setIsNewUser] = useState<boolean | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [previousAuthState, setPreviousAuthState] = useState<any>(null);
  const [isAuthStateChanging, setIsAuthStateChanging] = useState(false);
  const db = getFirestore();
  const functions = getFunctions();
  const auth = getAuth();

  // Log URL parameters on mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('🔍 URL Parameters:', {
      code: params.get('code'),
      state: params.get('state'),
      error: params.get('error'),
      error_description: params.get('error_description'),
      pathname: location.pathname
    });

    // If we're on the root path and have a code, we're handling the callback
    if (location.pathname === '/' && params.get('code')) {
      console.log('🎯 Handling Auth0 callback');
    }
  }, [location]);

  // Log initial Auth0 state
  useEffect(() => {
    console.log('📊 Initial Auth0 State:', {
      isAuthenticated,
      isLoading,
      user,
      error,
      location: location.pathname + location.search,
      hasCode: !!new URLSearchParams(location.search).get('code')
    });
  }, [isAuthenticated, isLoading, user, error, location]);

  // Set up Firebase auth state listener with debounce
  useEffect(() => {
    console.log('🔐 Setting up Firebase Auth Listener');

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (isAuthStateChanging) {
        console.log('⏳ Auth state change in progress, skipping...');
        return;
      }

      console.log('🔥 Firebase Auth State Change Detected');
      const previousUser = previousAuthState;
      const previousState = previousUser ? {
        uid: previousUser.uid,
        email: previousUser.email,
        displayName: previousUser.displayName
      } : 'null';
      
      const currentState = currentUser ? {
        uid: currentUser.uid,
        email: currentUser.email,
        displayName: currentUser.displayName
      } : 'null';

      console.log('🔥 Firebase Auth State Change:', {
        before: previousState,
        after: currentState,
        change: previousState === 'null' && currentState !== 'null' 
          ? 'User logged in' 
          : currentState === 'null' && previousState !== 'null'
          ? 'User logged out'
          : 'User state updated'
      });

      setPreviousAuthState(currentUser);
    });

    return () => {
      console.log('🔓 Cleaning up Firebase Auth Listener');
      unsubscribe();
    };
  }, [auth, previousAuthState, isAuthStateChanging]);

  // Log current state whenever it changes
  useEffect(() => {
    console.log('🔄 AuthCallback State Updated:', {
      isAuthenticated,
      isLoading,
      user,
      isNewUser,
      error,
      isProcessing,
      currentPath: window.location.pathname,
      previousAuthState: previousAuthState ? {
        uid: previousAuthState.uid,
        email: previousAuthState.email
      } : 'null'
    });
  }, [isAuthenticated, isLoading, user, isNewUser, error, isProcessing, previousAuthState]);

  const handleFirebaseAuth = useCallback(async (auth0Token: string) => {
    console.log('🔑 Starting Firebase Auth Process');
    try {
      setIsAuthStateChanging(true);
      // Call Firebase Cloud Function to get custom token
      console.log('🔥 Calling Firebase Cloud Function...');
      const auth0ToFirebaseToken = httpsCallable<{ token: string; userId: string }, Auth0ToFirebaseResponse>(functions, 'auth0ToFirebaseToken');
      
      // Log the token being sent (first few characters only for security)
      console.log('📤 Sending Auth0 token:', auth0Token.substring(0, 10) + '...');
      
      // Ensure the token is a valid JWT
      if (!auth0Token.includes('.')) {
        console.error('❌ Invalid Auth0 token format: not a JWT');
        return false;
      }

      // Send both the token and user ID to the Cloud Function
      const { data } = await auth0ToFirebaseToken({ 
        token: auth0Token,
        userId: user?.sub || '' // Use the Auth0 user ID
      });
      console.log('✅ Firebase custom token received');

      if (!data.firebaseToken) {
        console.error('❌ No Firebase token received from Cloud Function');
        return false;
      }

      // Sign in to Firebase with custom token
      console.log('🔐 Signing in to Firebase...');
      const userCredential = await signInWithCustomToken(auth, data.firebaseToken);
      console.log('✅ Firebase sign-in successful:', {
        uid: userCredential.user.uid,
        email: userCredential.user.email
      });

      // Update Firebase user profile with Auth0 data
      console.log('🔄 Updating Firebase user profile with Auth0 data...');
      const isPhoneNumber = user?.name && isValidPhoneNumber(user.name);
      
      // Get profile picture - for LinkedIn users, we need to handle it specially
      let profilePicture = "https://cdn.auth0.com/avatars/default.png";
      
      if (user?.picture) {
        if (user.sub?.startsWith('linkedin') && user.picture.includes('licdn.com')) {
          try {
            // Store LinkedIn image in Firebase Storage
            const storage = getStorage();
            const imageRef = ref(storage, `profile-pictures/${user.sub.replace('|', '_')}`);
            
            // Fetch image as blob
            const response = await fetch(user.picture);
            if (!response.ok) throw new Error('Failed to fetch LinkedIn image');
            
            const blob = await response.blob();
            const contentType = response.headers.get('content-type') || 'image/jpeg';
            
            // Upload blob directly
            const metadata = {
              contentType,
              customMetadata: {
                'uploadedAt': new Date().toISOString(),
                'source': 'linkedin'
              }
            };
            
            await uploadString(imageRef, await blobToBase64(blob), 'data_url');
            profilePicture = await getDownloadURL(imageRef);
            
            console.log('✅ LinkedIn profile picture stored in Firebase Storage:', profilePicture);
          } catch (error) {
            console.error('⚠️ Error storing LinkedIn profile picture:', error);
            // Fall back to default avatar if storage fails
            profilePicture = "https://cdn.auth0.com/avatars/default.png";
          }
        } else {
          // For non-LinkedIn pictures, use as is
          profilePicture = user.picture;
        }
      }

      console.log('🖼️ Setting user profile picture:', profilePicture);
      
      try {
        await updateProfile(userCredential.user, {
          displayName: isPhoneNumber ? user.name : (user?.name ?? "Unknown User"),
          photoURL: profilePicture
        });
        
        console.log('✅ Firebase user profile updated:', {
          displayName: userCredential.user.displayName,
          photoURL: userCredential.user.photoURL
        });
      } catch (error) {
        console.error('⚠️ Error updating Firebase user profile:', error);
        // Continue even if profile update fails
      }

      // Wait for auth state to update
      console.log('⏳ Waiting for Firebase auth state to update...');
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Verify the current user matches our credentials
      const currentUser = auth.currentUser;
      if (!currentUser || currentUser.uid !== userCredential.user.uid) {
        console.error('❌ Firebase auth state mismatch');
        return false;
      }

      // Log complete current user object
      console.log('🔍 Complete CurrentUser object:', {
        uid: currentUser.uid,
        email: currentUser.email,
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
        phoneNumber: currentUser.phoneNumber,
        metadata: currentUser.metadata,
        providerData: currentUser.providerData,
        raw: currentUser
      });

      // Log Auth0 user object for comparison
      console.log('🔍 Auth0 User object:', {
        sub: user?.sub,
        email: user?.email,
        name: user?.name,
        picture: user?.picture,
        phoneNumber: user?.phoneNumber,
        displayName: user?.displayName,
        raw: user
      });

      console.log('✅ Firebase auth state confirmed');

      return true;
    } catch (error) {
      console.error('❌ Error during Firebase authentication:', error);
      if (error instanceof Error) {
        console.error('Error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack
        });
      }
      return false;
    } finally {
      setIsAuthStateChanging(false);
    }
  }, [functions, auth, user]);

  const handleUserCreation = useCallback(async (userId: string) => {
    console.log('👤 Starting User Creation Process');
    try {
        setIsAuthStateChanging(true);
        // Normalize the user ID before searching
        const normalizedUserId = normalizeUserId(userId);
        console.log('📚 Checking Firestore for user by ID:', normalizedUserId);
        
        // Get phone number from various possible sources
        const userPhoneNumber = user?.phoneNumber || 
            (user?.name && isValidPhoneNumber(user.name) ? user.name : null) || 
            (user?.displayName && isValidPhoneNumber(user.displayName) ? user.displayName : null);

        if (userPhoneNumber) {
            console.log('📱 Checking for existing user by phone number:', userPhoneNumber);
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("phoneNumber", "==", userPhoneNumber));
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const existingUser = querySnapshot.docs[0];
                console.log('👤 Existing user found by phone number:', existingUser.data());
                
                // Update the current user's document with the existing user's data
                await updateUserDocument(normalizedUserId, {
                    ...existingUser.data(),
                    phoneNumber: userPhoneNumber // Ensure phone number is updated
                });
                
                setIsNewUser(false);
                return true;
            }
        }

        // If no existing user found by phone number, check by ID
        try {
            const userData = await fetchUserByDocumentId(normalizedUserId);
            console.log('📄 User document data:', userData);
            
            if (userData) {
                console.log('👤 Existing user found in Firestore by ID:', userData);
                setIsNewUser(false);
                return true;
            }
        } catch (error) {
            console.error('❌ Error fetching user document:', error);
        }

        // If no existing user found, create new user
        console.log('✨ Creating new user in Firestore');
        setIsNewUser(true);
        
        // Check if name is actually a phone number
        const isNamePhone = user?.name && isValidPhoneNumber(user.name);
        
        // Get profile picture URL from Firebase Auth
        const profilePicture = auth.currentUser?.photoURL || "https://cdn.auth0.com/avatars/default.png";
        console.log('🖼️ Using profile picture from Firebase Auth:', profilePicture);
        
        const userData = {
            uid: normalizedUserId,
            email: user?.email ?? "",
            name: isNamePhone ? "Unknown User" : (user?.name ?? "Unknown User"),
            createdAt: new Date(),
            phoneNumber: userPhoneNumber ?? "",
            displayName: isNamePhone ? userPhoneNumber : (user?.name ?? "Unknown User"),
            photoURL: profilePicture,
            isNewUser: true
        };
        
        console.log('📝 Creating user document with data:', userData);
        await updateUserDocument(normalizedUserId, userData);
        console.log('✅ New user created in Firestore');
        return true;
    } catch (error) {
        console.error('❌ Error during user creation:', error);
        if (error instanceof Error) {
            console.error('Error details:', {
                name: error.name,
                message: error.message,
                stack: error.stack
            });
            
            // Check if it's a permission error
            if (error.message.includes('permission-denied')) {
                console.error('🔒 Permission denied error - check Firestore rules');
            }
        }
        return false;
    } finally {
        setIsAuthStateChanging(false);
    }
}, [db, user]);

  useEffect(() => {
    const handleAuth = async () => {
      console.log('🔄 Starting Auth Process');
      if (!isAuthenticated || !user || !user.sub || isProcessing) {
        console.log('⏳ Waiting for authentication or already processing...', {
          isAuthenticated,
          hasUser: !!user,
          userId: user?.sub,
          isProcessing,
          location: location.pathname + location.search
        });
        return;
      }

      setIsProcessing(true);
      console.log('🚀 Starting authentication process:', {
        userId: user.sub,
        email: user.email,
        previousAuthState: previousAuthState ? {
          uid: previousAuthState.uid,
          email: previousAuthState.email
        } : 'null',
        location: location.pathname + location.search
      });

      try {
        // Get Auth0 access token
        console.log('🔑 Getting Auth0 access token...');
        const auth0Token = await getAccessTokenSilently();
        console.log('✅ Auth0 token received');

        if (!auth0Token) {
          throw new Error('No Auth0 token received');
        }

        // Handle Firebase authentication
        const firebaseSuccess = await handleFirebaseAuth(auth0Token);
        if (!firebaseSuccess) {
          throw new Error('Firebase authentication failed');
        }

        // Handle user creation/verification
        const userSuccess = await handleUserCreation(user.sub);
        if (!userSuccess) {
          throw new Error('User creation/verification failed');
        }

      } catch (error) {
        console.error('❌ Error during authentication:', error);
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          });
        }
        setAuthError("Failed to complete authentication. Please try again.");
      } finally {
        setIsProcessing(false);
      }
    };

    handleAuth();
  }, [isAuthenticated, user, getAccessTokenSilently, handleFirebaseAuth, handleUserCreation, isProcessing, previousAuthState, location]);

  useEffect(() => {
    if (error) {
      console.error('❌ Auth0 Error:', error);
      setAuthError("Authentication failed. Please try again.");
      return;
    }

    if (isAuthenticated && user && isNewUser !== null && !isProcessing) {
      console.log('🎉 Authentication successful, preparing navigation');
      console.log('🎉 Authentication successful:', {
        userId: user.sub,
        isNewUser,
        email: user.email,
        previousAuthState: previousAuthState ? {
          uid: previousAuthState.uid,
          email: previousAuthState.email
        } : 'null',
        location: location.pathname + location.search
      });

      // Don't redirect if we're on the payment processing page
      if (location.pathname === '/payment-processing') {
        console.log('💰 Staying on payment processing page');
        return;
      }

      // Otherwise redirect to events page after successful login
      console.log('👤 Redirecting to events page');
      navigate('/events');
    }
  }, [isAuthenticated, user, isNewUser, navigate, error, isProcessing, previousAuthState, location]);

  if (isLoading || isProcessing) {
    console.log('⏳ Loading state active');
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (authError) {
    console.log('❌ Error state active:', authError);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{authError}</Typography>
      </Box>
    );
  }

  return null;
};

// Helper function to convert blob to base64
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export default AuthCallback;
