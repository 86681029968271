import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const InstagramLandingPage: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#fff',
      }}
    >
      <Container 
        maxWidth="sm"
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            px: 2,
            gap: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              color: '#1E293B',
              fontWeight: 500,
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            Click on the 3 dots in the top right and click "Open in external browser" to go to salonim.live app
          </Typography>

          <Box
            component="img"
            src="/instagram-browser-help.png"
            alt="Instagram browser help"
            sx={{
              width: '100%',
              maxWidth: '300px',
              height: 'auto',
              borderRadius: '12px',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default InstagramLandingPage; 