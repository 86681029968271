import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  CardMedia,
  Chip,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Divider,
  GlobalStyles,
  Modal,
  Fade,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateUserDocument, deleteUserAndData } from '../../shared/services/userService';
import { getAuth, deleteUser } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Cropper from 'react-easy-crop';
import imageCompression from 'browser-image-compression';
import { useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CloseIcon from '@mui/icons-material/Close';
import StagingIndicator from '../../shared/components/StagingIndicator';
import { useAuth } from '../../context/AuthContext';
import { standardizeUserId } from '../../shared/utils/userHelpers';

interface User {
  user: any;
  firstName: string;
  purpose: string[];
  socialMediaUrl: string;
  occupation: string;
  birthday: any;
  bio: string;
  badges: string[];
  image: string;
  gender: string;
  email: string;
  uid: string;
  phoneNumber?: string;
  displayName?: string;
}

interface ProfilePageProps {
  user: User;
}

// Available options for Goals.
const availableGoals = [
  { label: "Connect with interesting people", icon: "💡" },
  { label: "Share knowledge", icon: "📚" },
  { label: "Find a romantic partner", icon: "💛" },
  { label: "Meet co-founders", icon: "🤝" },
  { label: "Learn & grow", icon: "🎓" },
];

// Updated available options for Values I Cherish.
const availableValues = [
  { label: 'Book Lover', icon: '📚' },
  { label: 'Science Enthusiast', icon: '🔬' },
  { label: 'Nature Lover', icon: '🌳' },
  { label: 'Philosophy & Thought', icon: '🧠' },
  { label: 'Art Enthusiast', icon: '🎭' },
  { label: 'Dreamer', icon: '🌌' },
  { label: 'World Traveler', icon: '🌍' },
  { label: 'Inventor & Creator', icon: '💡' },
  { label: 'Film & Series Enthusiast', icon: '🎥' },
  { label: 'Space Explorer', icon: '🚀' },
  { label: 'Spiritual Seeker', icon: '🧘‍♂️' },
  { label: 'Poetry & Words', icon: '✍️' },
  { label: 'Tech & Gadgets', icon: '💻' },
  { label: 'Design & Aesthetics', icon: '🎨' },
];

// Add phone number normalization function
const normalizePhoneNumber = (phone: string): string => {
  // Remove all non-digit characters
  const cleaned = phone.replace(/\D/g, '');
  
  // If the number starts with 0, replace it with 972
  if (cleaned.startsWith('0')) {
    return '972' + cleaned.slice(1);
  }
  
  // If the number doesn't start with a country code, add 972
  if (!cleaned.startsWith('972')) {
    return '972' + cleaned;
  }
  
  return cleaned;
};

interface ButtonState {
  loading: boolean;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }): JSX.Element => {
  const isStaging = window.location.hostname === 'staging.salonim.live';
  
  // Remove unused state
  const [bioEditMode, setBioEditMode] = useState(false);
  const [goalsEditMode, setGoalsEditMode] = useState(false);
  const [valuesEditMode, setValuesEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({
    ...user,
    purpose: user.purpose || [],
    badges: user.badges || [],
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(user.image);

  const auth = getAuth();

  const getUserUID = (user: User): string => {
    return user?.uid || 
           user?.user?.uid || 
           (user as any)?.multiFactor?.user?.uid ||  // ✅ Cast to 'any' to bypass TypeScript error
           "";
};

  // Add new state for phone verification
  const [showVerificationOverlay, setShowVerificationOverlay] = useState(false);
  const [openPhoneVerificationModal, setOpenPhoneVerificationModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(10);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const [generatedCode, setGeneratedCode] = useState<string>('');
  const [buttonState, setButtonState] = useState<ButtonState>({ loading: false });

  // Move countdown timer function inside component
  const startResendCountdown = () => {
    let count = 10;
    const timer = setInterval(() => {
      count--;
      setResendCountdown(count);
      if (count === 0) {
        clearInterval(timer);
        setIsResendDisabled(false);
      }
    }, 1000);
  };

  // Update useEffect to check phone number from both sources
  useEffect(() => {
    const checkPhoneNumber = async () => {
      const userPhoneNumber = user?.phoneNumber;
      console.log('🔍 Checking phone number:', userPhoneNumber);

      if (!userPhoneNumber) {
        // Check Firestore for existing phone number
        try {
          const db = getFirestore();
          const userDoc = await getDoc(doc(db, "users", getUserUID(user)));
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('📄 Found user data in Firestore:', userData);
            
            if (userData.phoneNumber) {
              console.log('✅ Found phone number in Firestore:', userData.phoneNumber);
              // Update the local user object with the phone number
              if (user.user) {
                user.user.phoneNumber = userData.phoneNumber;
              } else {
                user.phoneNumber = userData.phoneNumber;
              }
              return; // Don't show verification overlay if phone exists in Firestore
            }
          }
        } catch (error) {
          console.error('❌ Error checking Firestore:', error);
        }
        
        console.log('⚠️ No phone number found, showing verification overlay');
        setShowVerificationOverlay(true);
      }
    };

    checkPhoneNumber();
  }, [user]);

  // Update a field in state.
  const handleChange = (field: string, value: string) => {
    setUpdatedUser((prev) => ({ ...prev, [field]: value }));
  };

    // Generate cropped image
    const getCroppedImage = async (): Promise<File | null> => {
      if (!imageSrc || !croppedAreaPixels) return null;
    
      const image = new Image();
      image.src = imageSrc;
      await new Promise((resolve) => (image.onload = resolve));
    
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return null;
    
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
    
      return new Promise<File | null>((resolve) => {
        canvas.toBlob(async (blob) => {
          if (!blob) return resolve(null);
    
          // ✅ Convert Blob to File
          const file = new File([blob], `cropped-profile-${Date.now()}.jpg`, { type: 'image/jpeg' });
    
          // ✅ Compress Image using browser-image-compression
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          });
    
          resolve(compressedFile);
        }, 'image/jpeg');
      });
    };
    

    
  // Upload cropped image to Firebase
  const uploadCroppedImage = async () => {
    setLoading(true);

    try {
        const croppedFile = await getCroppedImage();
        if (!croppedFile) {
            console.error('Failed to generate cropped image.');
            alert('Error cropping image. Please try again.');
            setLoading(false);
            return;
        }

        // ✅ Upload to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `profile-images/${croppedFile.name}`);
        const snapshot = await uploadBytes(storageRef, croppedFile);
        let downloadURL = await getDownloadURL(snapshot.ref);

        console.log('downloadURL:', downloadURL);
        if (!downloadURL || typeof downloadURL !== 'string') {
            console.error('Invalid image URL:', downloadURL);
            return;
        }

        // ✅ Update profile image in Firestore
        await updateUserDocument(getUserUID(user), { image: downloadURL });

        // ✅ Update State
        setUpdatedUser((prev) => ({ ...prev, image: downloadURL }));
        setIsDialogOpen(false); // Close cropping modal
        setImageSrc(null);
    } catch (error) {
        console.error('Error uploading image:', error);
        alert('Upload failed. Try again.');
    } finally {
        setLoading(false);
    }
  };
  
  

    // Capture cropped area
    const onCropComplete = useCallback((_: unknown, croppedPixels: any) => {
      setCroppedAreaPixels(croppedPixels);
    }, []);
    

  // Toggle About Me (Bio) edit mode.
  const toggleBioEditMode = async () => {
    if (bioEditMode) {
      try {
        if (updatedUser.bio.trim() === "") {
          console.warn("Bio cannot be empty!");
          return;
        }
        await updateUserDocument(getUserUID(user), { bio: updatedUser.bio });
  
        console.log("Bio updated successfully!");
  
        // ✅ Refetch latest user data after updating bio
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, "users", getUserUID(user)));
        if (userDoc.exists()) {
          setUpdatedUser(userDoc.data() as User);
        }
      } catch (error) {
        console.error("Error updating bio:", error);
      }
    }
    setBioEditMode(!bioEditMode);
  };
  
  
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result as string);
        setIsDialogOpen(true); // ✅ Open the cropping dialog
      };
      reader.readAsDataURL(file);
    }
  };
  

  // Toggle a goal option.
  const handleToggleGoal = (goalLabel: string) => {
    setUpdatedUser((prev) => {
      const isSelected = prev.purpose.includes(goalLabel);
      return {
        ...prev,
        purpose: isSelected
          ? prev.purpose.filter((g) => g !== goalLabel) // Remove goal
          : [...prev.purpose, goalLabel], // Add goal
      };
    });
  };
  

  const toggleGoalsEditMode = async () => {
    if (goalsEditMode) {
      try {
        // Ensure goals are properly updated
        await updateUserDocument(getUserUID(user), { purpose: updatedUser.purpose });
  
        console.log("Goals updated successfully!");
      } catch (error) {
        console.error("Error updating goals:", error);
      }
    }
    setGoalsEditMode(!goalsEditMode);
  };
  
  // Toggle a value option.
  const handleToggleValue = (valueLabel: string) => {
    setUpdatedUser((prev) => {
      const isSelected = prev.badges.includes(valueLabel);
      return {
        ...prev,
        badges: isSelected
          ? prev.badges.filter((v) => v !== valueLabel) // Remove skill
          : [...prev.badges, valueLabel], // Add skill
      };
    });
  };
  

  // Toggle Values section edit mode.
  const toggleValuesEditMode = async () => {
    if (valuesEditMode) {
      try {
        // Update only the badges (skills) field in Firestore
        await updateUserDocument(getUserUID(user), { badges: updatedUser.badges });
  
        console.log("Skills updated successfully!");
      } catch (error) {
        console.error("Error updating skills:", error);
      }
    }
    setValuesEditMode(!valuesEditMode);
  };
  
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setImageSrc(null); // Reset the image state before opening the dialog
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setImageSrc(null); // Ensure the dialog resets when closed
  };
  

  const handleDeleteUser = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No authenticated user found.');
        return;
      }
      await currentUser.getIdToken(true);
      await deleteUserAndData(currentUser.uid);
      await deleteUser(currentUser);
      handleCloseDialog();
    } catch (error) {
      console.error('Error during user deletion:', error);
    }
  };

  // Add handler functions
  const handleClosePhoneModal = () => {
    setOpenPhoneVerificationModal(false);
    setShowVerificationInput(false);
    setPhoneNumber('');
    setVerificationCode('');
    setVerificationError('');
    setGeneratedCode('');
    setButtonState(prev => ({ ...prev, loading: false }));
  };

  const handlePhoneSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('📱 handlePhoneSubmit called with phone:', phoneNumber);

    if (!phoneNumber) {
      console.log('❌ No phone number provided');
      setVerificationError('Please enter a phone number');
      return;
    }

    try {
      console.log('🔄 Starting phone verification process');
      setIsVerifying(true);
      setVerificationError('');

      // Generate a random 6-digit code
      const code = Math.floor(100000 + Math.random() * 900000).toString();
      console.log('🔑 Generated verification code:', code);
      setGeneratedCode(code);

      // Format phone number to international format
      const formattedPhone = normalizePhoneNumber(phoneNumber);
      console.log('📞 Formatted phone number:', formattedPhone);

      // Send verification code
      console.log('📤 Sending verification code...');
      const response = await fetch('https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: formattedPhone,
          text: `Your verification code is: ${code}`
        })
      });

      if (!response.ok) {
        console.error('❌ Failed to send verification code. Response:', response);
        throw new Error('Failed to send verification code');
      }

      console.log('✅ Verification code sent successfully');
      setShowVerificationInput(true);
      setIsResendDisabled(true);
      startResendCountdown();
      // Replace toast with console.log for now
      console.log('Verification code sent!');
    } catch (error) {
      console.error('❌ Error in phone verification process:', error);
      setVerificationError('Failed to send verification code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleVerificationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('🔍 handleVerificationSubmit called with code:', verificationCode);

    if (!verificationCode) {
      console.log('❌ No verification code provided');
      return;
    }

    try {
      setIsVerifying(true);
      setVerificationError('');

      console.log('🔄 Comparing codes:', {
        entered: verificationCode,
        generated: generatedCode
      });

      if (verificationCode !== generatedCode) {
        console.log('❌ Code mismatch');
        throw new Error('Invalid verification code');
      }

      console.log('✅ Code verified successfully');

      // Format phone number to international format
      const formattedPhone = normalizePhoneNumber(phoneNumber);
      console.log('📞 Using formatted phone:', formattedPhone);

      // Update user's phone number in your auth context and database
      if (user) {
        const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
        const standardizedUserId = standardizeUserId(userId);
        console.log('👤 Updating user:', standardizedUserId);
        
        // Get the user's document first
        const userDocRef = doc(getFirestore(), 'users', standardizedUserId);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          console.log('📝 Updating user document with phone number');
          await updateDoc(userDocRef, { 
            phoneNumber: formattedPhone,
            displayName: user.displayName || formattedPhone
          });

          // Update the local user object
          if (user.user) {
            user.user.phoneNumber = formattedPhone;
          } else {
            user.phoneNumber = formattedPhone;
          }

          console.log('✅ User document updated successfully');
          setOpenPhoneVerificationModal(false);
          setShowVerificationOverlay(false);
          
          // Fetch user data associated with this phone number
          const usersRef = collection(getFirestore(), 'users');
          const q = query(usersRef, where('phoneNumber', '==', formattedPhone));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            console.log('Found existing user data:', userData);
            setUpdatedUser(prev => ({
              ...prev,
              ...userData,
              purpose: userData.purpose || [],
              badges: userData.badges || [],
            }));
          }
        }
      }
    } catch (error) {
      console.error('❌ Error in verification process:', error);
      setVerificationError('Invalid verification code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <>
      {isStaging && <StagingIndicator />}
      {/* GlobalStyles to enforce the "Poppins" font */}
      <GlobalStyles
        styles={{
          body: { fontFamily: "'Poppins', sans-serif !important" },
          '*': { fontFamily: "'Poppins', sans-serif !important" },
        }}
      />

      {/* Add verification overlay */}
      {showVerificationOverlay && (
        <Box
          sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundColor: '#fff',
            pt: '56px',
            pb: '20px',
            px: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: '120px',
              height: '120px',
              borderRadius: '50%',
              backgroundColor: '#E3F2FD',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 3,
              animation: 'pulse 2s infinite',
            }}
          >
            <PhoneIphoneIcon sx={{ fontSize: 60, color: '#1976d2' }} />
          </Box>

          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              mb: 2,
              color: '#1E293B',
            }}
          >
            Verify Your Phone Number
          </Typography>

          <Typography
            sx={{
              color: '#64748B',
              mb: 4,
              fontSize: '16px',
              maxWidth: '400px',
              mx: 'auto',
            }}
          >
            To access your profile and ensure a seamless experience, please verify your phone number.
          </Typography>

          <Button
            variant="contained"
            onClick={() => setOpenPhoneVerificationModal(true)}
            sx={{
              backgroundColor: '#1976d2',
              color: 'white',
              py: 1.5,
              px: 4,
              borderRadius: '12px',
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#1565c0'
              }
            }}
          >
            Verify Phone Number
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#fff',
          pt: '56px',
          pb: '20px',
          px: 2,
          position: 'relative',
          display: showVerificationOverlay ? 'none' : 'block',
        }}
      >
        {/* Profile Image Section with fixed height, rounded corners, and padding */}
        <Box
          sx={{
            width: '100%',
            height: 300,
            position: 'relative',
            backgroundColor: '#f8f9fa',
            borderRadius: '12px',
            overflow: 'hidden',
            mb: 2,
            cursor: 'pointer',
            border: '2px dashed #e2e8f0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: '#f1f5f9'
            }
          }}
          onClick={() => fileInputRef.current?.click()}
        >
          {!imageLoaded && !updatedUser.image ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                color: '#64748b'
              }}
            >
              <Box
                sx={{
                  width: '64px',
                  height: '64px',
                  borderRadius: '50%',
                  backgroundColor: '#e2e8f0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: '#cbd5e1'
                  }
                }}
              >
                <CameraAltIcon sx={{ fontSize: '32px' }} />
              </Box>
              <Typography sx={{ 
                fontSize: '16px',
                fontWeight: 500,
                textAlign: 'center'
              }}>
                Click to upload profile picture
              </Typography>
            </Box>
          ) : (
            <>
              {!imageLoaded && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <CardMedia
                component="img"
                image={updatedUser.image || "/profileDefualt.png"}
                alt={updatedUser.firstName || "Default Profile"}
                onLoad={() => setImageLoaded(true)}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  display: imageLoaded ? 'block' : 'none',
                }}
              />
              {/* Camera Icon Overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)'
                  }
                }}
              >
                <CameraAltIcon sx={{ color: 'white', fontSize: '20px' }} />
              </Box>
            </>
          )}

          {/* Hidden File Input */}
          <input 
            ref={fileInputRef} 
            type="file" 
            accept="image/*" 
            style={{ display: 'none' }} 
            onChange={handleFileChange}
          />
        </Box>


        {/* Profile Content */}
        <Box sx={{ px: 2, mt: 2 }}>
          <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
            {updatedUser.firstName}
          </Typography>

          {/* About Me Section */}
          <Box mb={2}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
    <Typography variant="h6" fontWeight="bold">
      About me
    </Typography>
    {bioEditMode ? (
      <Chip
        label="✔️ Save"
        variant="outlined"
        color="primary"
        sx={{
          cursor: 'pointer',
          fontSize: '0.85rem',
          px: 2,
          py: 0.5,
          borderRadius: '8px',
          '&:hover': { backgroundColor: 'primary.light' },
        }}
        onClick={toggleBioEditMode}
      />
    ) : (
      <IconButton onClick={toggleBioEditMode} size="small">
        <EditIcon color="action" />
      </IconButton>
    )}
  </Box>

  {bioEditMode ? (
    <TextField
      fullWidth
      multiline
      rows={6} // ✅ Larger text area
      variant="outlined"
      value={updatedUser.bio}
      onChange={(e) => handleChange('bio', e.target.value)}
      sx={{
        backgroundColor: '#f8f9fa', // ✅ Light background
        borderRadius: 2,
        p: 1,
        maxWidth: '90%', // ✅ Ensures it doesn't overflow
        minWidth: '90%', // ✅ Prevents cutting off
        '& .MuiOutlinedInput-root': {
          fontSize: '1.1rem',
          direction: 'rtl', // ✅ Right-to-left text for Hebrew
          fontFamily: "'Heebo', sans-serif", // ✅ Modern Hebrew font
          padding: '12px', // ✅ Adds spacing
        },
        '& textarea': {
          lineHeight: '1.8', // ✅ Better readability
          textAlign: 'right', // ✅ Ensures RTL input
        },
      }}
      placeholder="כתוב משהו על עצמך..."
    />
  ) : (
    <Typography variant="body1" color="textSecondary" sx={{ direction: 'rtl', fontSize: '1.1rem' }}>
      {updatedUser.bio || 'לא נכתבה ביוגרפיה'}
    </Typography>
  )}
</Box>


          <Divider sx={{ my: 2 }} />

          {/* Goals Section */}
          <Box mb={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6" fontWeight="bold">
                Goals
              </Typography>
             
              {goalsEditMode ? (
  <Chip
       label="✔️ Save"
    variant="outlined"
    color="primary"
    sx={{
      cursor: 'pointer',
      fontSize: '0.85rem',
      px: 2,
      py: 0.5,
      borderRadius: '8px',
      '&:hover': { backgroundColor: 'primary.light' },
    }}
    onClick={toggleGoalsEditMode}
  />
) : (
  <IconButton onClick={toggleGoalsEditMode} size="small">
    <EditIcon color="action" />
  </IconButton>
)}

            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {goalsEditMode
                ? availableGoals.map((goalOption) => {
                    const isSelected = updatedUser.purpose.includes(goalOption.label);
                    return (
                      <Chip
                        key={goalOption.label}
                        label={`${goalOption.icon} ${goalOption.label}`}
                        color={isSelected ? 'primary' : 'default'}
                        variant={isSelected ? 'filled' : 'outlined'}
                        onClick={() => handleToggleGoal(goalOption.label)}
                        sx={{ cursor: 'pointer', fontSize: '0.85rem' }}
                      />
                    );
                  })
                : updatedUser.purpose.map((goal, index) => (
                    <Chip key={index} label={goal} sx={{ fontSize: '0.85rem' }} />
                  ))}
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

         {/* My Skills Section */}
<Box mb={2}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
    <Typography variant="h6" fontWeight="bold">
      My Skills
    </Typography>

    {/* Toggle between Edit & Done */}
    {valuesEditMode ? (
      <Chip
          label="✔️ Save"
        variant="outlined"
        color="primary"
        sx={{
          cursor: 'pointer',
          fontSize: '0.85rem',
          px: 2,
          py: 0.5,
          borderRadius: '8px',
          '&:hover': { backgroundColor: 'primary.light' },
        }}
        onClick={toggleValuesEditMode}
      />
    ) : (
      <IconButton onClick={toggleValuesEditMode} size="small">
        <EditIcon color="action" />
      </IconButton>
    )}
  </Box>

  {/* Display Skills */}
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    {valuesEditMode
      ? availableValues.map((valueOption) => {
          const isSelected = updatedUser.badges?.includes(valueOption.label);
          return (
            <Chip
              key={valueOption.label}
              label={`${valueOption.icon} ${valueOption.label}`}
              color={isSelected ? 'primary' : 'default'}
              variant={isSelected ? 'filled' : 'outlined'}
              onClick={() => handleToggleValue(valueOption.label)}
              sx={{ cursor: 'pointer', fontSize: '0.85rem' }}
            />
          );
        })
      : updatedUser.badges?.map((badge, index) => (
          <Chip key={index} label={badge} sx={{ fontSize: '0.85rem' }} />
        ))}
  </Box>
</Box>

          
        </Box>

        <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    gap: 3,
    width: '100%',
    mt: 4, // Adds margin-top for spacing
    pb: 2,
  }}
>
  <Typography
    variant="body2"
    sx={{
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: '0.85rem',
      color: 'text.secondary', // Subtle color
    }}
    onClick={async () => {
      try {
        await getAuth().signOut();
        window.location.reload(); // Refresh or redirect after logout
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }}
  >
    Logout
  </Typography>

</Box>



        {/* Delete Confirmation Dialog */}
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete your account? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={handleCloseDialog} size="small">
              <Typography variant="button" color="primary">
                Cancel
              </Typography>
            </IconButton>
            <IconButton onClick={handleDeleteUser} size="small">
              <Typography variant="button" color="error">
                Yes, Delete
              </Typography>
            </IconButton>
          </DialogActions>
        </Dialog>
        {/* Image Cropping Dialog */}
{/* Image Cropping Dialog */}
<Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm" disableEnforceFocus>
  <DialogContent
    sx={{
      position: "relative",
      width: "100%",
      height: "70vh", // Make it fill the screen
      backgroundColor: "#000", // Dark background for contrast
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      padding: 0,
    }}
  >
    {imageSrc && (
      <Cropper
        image={imageSrc || undefined}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
        style={{
          containerStyle: {
            width: "100%",
            height: "100%",
          },
          cropAreaStyle: {
            borderRadius: "50%", // Make it slightly rounded
            background: "rgba(0, 0, 0, 0.5)", // Subtle dark overlay
          },
        }}
      />
    )}

    {/* Floating Action Buttons */}
    <Box
      sx={{
        position: "absolute",
        bottom: 20,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        px: 3,
      }}
    >
      <button
        onClick={handleCloseDialog}
        style={{
          padding: "12px 16px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "30px",
          border: "none",
          fontSize: "1rem",
          cursor: "pointer",
          width: "48%",
        }}
      >
        Cancel
      </button>
      <button
        onClick={uploadCroppedImage}
        style={{
          padding: "12px 16px",
          backgroundColor: "#007bff",
          color: "white",
          borderRadius: "30px",
          border: "none",
          fontSize: "1rem",
          cursor: "pointer",
          width: "48%",
        }}
        disabled={loading}
      >
        {loading ? "Uploading..." : "Save"}
      </button>
    </Box>
  </DialogContent>
</Dialog>


      </Box>

      {/* Phone Verification Modal */}
      <Modal
        open={openPhoneVerificationModal}
        onClose={() => !isVerifying && handleClosePhoneModal()}
        closeAfterTransition
      >
        <Fade in={openPhoneVerificationModal}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '400px',
            bgcolor: 'background.paper',
            borderRadius: '16px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            p: 4,
            outline: 'none'
          }}>
            <IconButton
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
              onClick={() => !isVerifying && handleClosePhoneModal()}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" sx={{ 
              fontWeight: 600,
              mb: 2,
              color: '#1E293B',
              textAlign: 'center'
            }}>
              Verify Your Phone Number
            </Typography>

            <Typography sx={{ 
              color: '#64748B',
              mb: 3,
              fontSize: '14px',
              lineHeight: 1.6,
              textAlign: 'center'
            }}>
              Please enter your phone number to receive a verification code.
            </Typography>

            {!showVerificationInput ? (
              <form onSubmit={handlePhoneSubmit}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter your phone number"
                  sx={{ mb: 3 }}
                  disabled={isVerifying}
                />
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isVerifying || !phoneNumber}
                  sx={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    py: 1.5,
                    borderRadius: '12px',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#1565c0'
                    }
                  }}
                >
                  {isVerifying ? 'Sending...' : 'Send Code'}
                </Button>
              </form>
            ) : (
              <form onSubmit={handleVerificationSubmit}>
                <TextField
                  fullWidth
                  label="Verification Code"
                  variant="outlined"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter 6-digit code"
                  sx={{ mb: 3 }}
                  disabled={isVerifying}
                />
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isVerifying || !verificationCode}
                  sx={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    py: 1.5,
                    borderRadius: '12px',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'none',
                    mb: 2,
                    '&:hover': {
                      backgroundColor: '#1565c0'
                    }
                  }}
                >
                  {isVerifying ? 'Verifying...' : 'Verify Code'}
                </Button>
                <Button
                  fullWidth
                  variant="text"
                  onClick={handlePhoneSubmit}
                  disabled={isResendDisabled || isVerifying}
                  sx={{
                    color: '#1976d2',
                    fontSize: '14px',
                    textTransform: 'none',
                  }}
                >
                  {isResendDisabled ? `Resend code in ${resendCountdown}s` : 'Resend Code'}
                </Button>
              </form>
            )}

            {verificationError && (
              <Typography sx={{ 
                color: '#EF4444',
                mt: 2,
                textAlign: 'center',
                fontSize: '14px'
              }}>
                {verificationError}
              </Typography>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ProfilePage;
