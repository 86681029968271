// Import required libraries and components
import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Divider } from '@mui/material';

// Define the props interface for ImageList
interface ImageListProps {
    imageCards: {
        image: string;
        title: string;
        description: string;
    }[]; // Array of image card objects
}

// ImageList functional component
const ImageList = ({ imageCards }: ImageListProps) => {
    return (
        <Box 
            sx={{ 
                padding: '20px', 
                maxWidth: '600px', // Optional: Limit the width for better readability
                margin: '0 auto' // Center align the list
            }}
        >
            {imageCards.map((card, index) => (
                <React.Fragment key={index}>
                    {/* Card container */}
                    <Card
                        sx={{
                            marginBottom: '0px 0px 40px 0px', // Space between cards
                            boxShadow: 'none', // Optional: Remove shadow
                            borderRadius: '10px', // Rounded corners
                            overflow: 'hidden', // Ensure content fits within the card
                        }}
                    >
                        {/* Card image */}
                        <CardMedia
                            component="img"
                            image={card.image} // Image URL
                            alt={card.title || 'Image'} // Alt text for the image
                            sx={{
                                objectFit: 'cover', // Maintain aspect ratio and crop if necessary
                                height: '200px', // Fixed height for images
                                width: '100%', // Full width
                            }}
                        />

                        {/* Card content */}
                        <CardContent>
                            {/* Card title */}
                            <Typography 
                                variant="h6" 
                                component="div" 
                                sx={{
                                    fontWeight: 'bold', 
                                    marginBottom: '10px',
                                    direction: /[\u0590-\u05FF]/.test(card.title) ? 'rtl' : 'ltr' // RTL or LTR based on content
                                }}
                                dangerouslySetInnerHTML={{ __html: card.title }} // Render HTML content in title
                            />

                            {/* Card description */}
                            {/* <Typography 
                                variant="body2" 
                                component="div" 
                                sx={{
                                    color: '#555', // Gray color for description
                                    direction: /[\u0590-\u05FF]/.test(card.description) ? 'rtl' : 'ltr' // RTL or LTR based on content
                                }}
                                dangerouslySetInnerHTML={{ __html: card.description }} // Render HTML content in description
                            /> */}
                        </CardContent>
                    </Card>

                    {/* Divider */}
                    {index < imageCards.length - 1 && (
                        <Divider sx={{ margin: '20px 0' }} /> // Add divider between cards
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

// Export the ImageList component for use in other files
export default ImageList;