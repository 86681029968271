import React, { CSSProperties } from 'react';

const isMobile = typeof window !== "undefined" && (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768);

const AccountDeletionPolicy: React.FC = () => {
  const containerStyle: CSSProperties = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: isMobile ? '20px' : '70px',
  };

  const titleStyle: CSSProperties = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  };

  const sectionTitleStyle: CSSProperties = {
    fontSize: isMobile ? '1.8rem' : '1.2rem',
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '15px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Account Deletion Policy</h1>
      <p>Salonim Live values your privacy and provides an easy way for users to delete their accounts and associated data.</p>
      
      <h2 style={sectionTitleStyle}>How to Request Account Deletion</h2>
      <p>You can request to delete your account by following these steps:</p>
      <ol>
        <li>Open the Salonim Live app and go to <strong>Profile</strong>.</li>
        <li>Click on the <strong>Delete My Account</strong> button.</li>
        <li>Confirm your request by following the on-screen instructions.</li>
        <li>Your account will be scheduled for deletion, and you will receive a confirmation email.</li>
      </ol>
      
      <h2 style={sectionTitleStyle}>What Data is Deleted?</h2>
      <p>When you request account deletion, the following data will be removed:</p>
      <ul>
        <li>Personal profile information (name, email, phone number, etc.).</li>
        <li>Event participation history.</li>
        <li>Messages and interactions with other users.</li>
      </ul>
      
      <h2 style={sectionTitleStyle}>What Data is Retained?</h2>
      <p>Some data may be retained for legal and security reasons, including:</p>
      <ul>
        <li>Transaction records (if applicable) for compliance with financial regulations.</li>
        <li>Security logs and fraud prevention data.</li>
        <li>Data backups, which are automatically deleted within 30 days.</li>
      </ul>
      
      <h2 style={sectionTitleStyle}>Retention Period</h2>
      <p>Once your account is deleted, your data will be permanently erased within 30 days. However, certain legal records may be retained for up to 6 months as required by law.</p>
      
      <h2 style={sectionTitleStyle}>Contact Us</h2>
      <p>If you have any questions, need further assistance, or want to verify your account deletion, please contact our support team at:</p>
      <p>Email: <a href="mailto:support@salonim.live">support@salonim.live</a> or <a href="mailto:info@salonim.live">info@salonim.live</a></p>
    </div>
  );
};

export default AccountDeletionPolicy;
