// src/serviceWorkerRegistration.ts

// This function registers a service worker
export function register(config?: { onUpdate?: (registration: ServiceWorkerRegistration) => void }) {
  if ('serviceWorker' in navigator) {
    const swUrl = `./public/service-worker.js`;

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        const swRegistration = navigator.serviceWorker.register(swUrl)
          .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
            if (config && config.onUpdate) {
              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker) {
                  installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                      // New or updated content is available
                      if (config.onUpdate) {
                        config.onUpdate(registration);
                      }
                      window.location.reload(); // <-- Force reload when new version is ready
                      
                    }
                  };
                }
              };
            }
          })
          .catch((error) => {
            console.error('Error during service worker registration:', error);
          });
      });
    }
  }
}

// This function unregisters a service worker
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error('Error during service worker unregistration:', error);
      });
  }
}
