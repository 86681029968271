// src/eventsService.js
import { db } from './firebaseConfig';
import {
    collection,
    addDoc,
    getDocs,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    query,
    where,
    orderBy,
    Timestamp,
} from 'firebase/firestore';
import axios from 'axios';
import { getStandardizedUser } from '../utils/userHelpers';

// Reference to the 'events' collection
const eventsCollection = collection(db, 'Events');
// Create a new event
export const createEvent = async (eventData: any) => {
    try {
        const docRef = await addDoc(eventsCollection, eventData);
        return docRef.id;
    } catch (error) {
        console.error('Error adding event: ', error);
        throw error;
    }
};

interface EventData {
    id: string;
    event_name: string;
    event_date: any;
    event_image: string;
    location: string;
    locationName: string;
    event_time?: string;
    guests?: Array<{
        user_id: string;
        status: string;
        username: string;
        image: string;
    }>;
    [key: string]: any;
}

export const getEventById = async (id: string): Promise<EventData | null> => {
    try {
        // Create a query to find the document by its ID
        const eventDocRef = doc(db, 'Events', id);
        const eventDocSnap = await getDoc(eventDocRef);
        if (eventDocSnap.exists()) {
            return { id: eventDocSnap.id, ...eventDocSnap.data() } as EventData;
        } else {
            return null;
        }

    } catch (error) {
        console.error('Error fetching event by ID:', error);
        throw error;
    }
};

// Approve user for event
export const approveUserForEvent = async (eventId: string, userId: string, status: string) => {
    try {
        const q = query(eventsCollection, where('event_id', '==', eventId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const eventDoc = querySnapshot.docs[0];
            const eventDocRef = doc(db, 'Events', eventDoc.id);
            const eventDocSnap = await getDoc(eventDocRef);

            if (eventDocSnap.exists()) {
                const guests = eventDocSnap.data()?.guests || [];
                const updatedGuests = guests.filter((guest: any) => guest.user_id !== userId);
                const userToApprove = guests.find((guest: any) => guest.user_id === userId);
                if (userToApprove) {
                    userToApprove.status = status;
                    updatedGuests.push(userToApprove);
                }
                await updateDoc(eventDocRef, {
                    guests: updatedGuests
                });
                return true;
            }
            // Rest of the code...
        } else {
            console.error('No event found with the specified event_id');
        }
        console.log('User approved for event successfully');
    } catch (error) {
        console.error('Error approving user for event:', error);
    }
}

// Add user to event
export const addUserToEvent = async (eventId: string, user: any) => {
    try {
        // Reference to the event document by its ID
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        console.log('addUserToEvent ad user');
        console.log(user);

        if (eventDocSnap.exists()) {
            const eventDoc = eventDocSnap.data();
            const users = eventDoc?.guests || [];

            // Get standardized user data
            const standardUser = getStandardizedUser(user);
            
            // Check if the user is already registered for the event
            const isUserRegistered = users.some((guest: any) => guest.user_id === standardUser.uid);

            if (!isUserRegistered) {
                const userItem = {
                    user_id: standardUser.uid,
                    status: 'pending',
                    username: standardUser.displayName || '',
                    image: standardUser.photoURL || ''
                };
                users.push(userItem);

                // Ensure no undefined values in the userItem
                const sanitizedUsers = users.map((guest: any) => ({
                    ...guest,
                    image: guest.image || ''
                }));

                // Update the document with the new guest list
                await updateDoc(eventDocRef, {
                    guests: sanitizedUsers
                });

                // Debug logs for user data
                console.log('User data:', {
                    user: {
                        firstName: user?.firstName,
                        displayName: user?.displayName,
                        fullUser: user
                    },
                    standardUser: {
                        displayName: standardUser?.displayName,
                        fullStandardUser: standardUser
                    }
                });

                // Safer null checking for user data
                const firstName = user?.firstName || 
                                standardUser?.displayName || 
                                user?.displayName || 
                                '';

                // Log the resolved firstName
                console.log('Resolved firstName:', firstName);
                
                const phoneNumber = standardUser?.phoneNumber || user?.phoneNumber;
                
                if (phoneNumber) {
                    const message = `היי ${firstName}, הבקשה שלך לאירוע התקבלה. אם תאושר, תקבל הודעה כאן.`;
                    const payload = { to: phoneNumber, message };

                    try {
                        await axios.post(`https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json`, payload);
                    } catch (error) {
                        console.error('Failed to send message:', error);
                    }
                }

                console.log('User added to event successfully');
            } else {
                console.log('User is already registered for this event');
            }
        } else {
            console.error('No event found with the specified event_id');
        }

    } catch (error) {
        console.error('Error adding user to event:', error);
        throw error;
    }
};

// Remove user from event
export const removeUserFromEvent = async (eventId: string, userId: string) => {
    try {
        // Reference to the event document by its ID
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
            const eventDoc = eventDocSnap.data();
            const users = eventDoc?.guests || [];

            // Filter out the user to be removed
            const updatedUsers = users.filter((guest: any) => guest.user_id !== userId);

            // Update the document with the new guest list
            await updateDoc(eventDocRef, {
                guests: updatedUsers
            });

            console.log('User removed from event successfully');
        } else {
            console.error('No event found with the specified event_id');
        }

    } catch (error) {
        console.error('Error removing user from event:', error);
        throw error;
    }
};

interface Event {
    id: string;
    event_name: string;
    event_date: Date;
    event_image: string;
    location: string;
    locationName: string;
    locationDetails?: string;
    event_time?: string;
    description?: string;
    guests?: Array<{
        user_id: string;
        status: string;
        username: string;
        image: string;
    }>;
    status?: string;
    isUpcoming?: boolean;
    daysRemaining?: number | null;
}

// Helper function to convert Timestamp to Date
const convertToDate = (timestamp: Timestamp | Date): Date => {
    if (timestamp instanceof Timestamp) {
        return timestamp.toDate();
    }
    return timestamp;
};

// Debug function to check system time
export const debugSystemTime = () => {
    const now = new Date();
    console.log('System time:', {
        date: now,
        timestamp: now.getTime(),
        isoString: now.toISOString(),
        localString: now.toLocaleString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    return now;
};

// Debug function to check events in database
export const debugEvents = async () => {
    try {
        const snapshot = await getDocs(eventsCollection);
        console.log('Total events in database:', snapshot.docs.length);
        
        snapshot.docs.forEach(doc => {
            const data = doc.data();
            console.log('Event:', {
                id: doc.id,
                name: data.event_name,
                date: data.event_date,
                dateType: typeof data.event_date,
                isTimestamp: data.event_date instanceof Timestamp,
                rawDate: data.event_date
            });
        });
    } catch (error) {
        console.error('Error debugging events:', error);
    }
};

// Read events (fetch all events)
export const getEvents = async (id?: string, type?: string, page: number = 1, limitCount: number = 10): Promise<Event[]> => {
    try {
        let q = query(eventsCollection);

        if (id) {
            q = query(q, where('event_id', '==', id));
        }

        if (type) {
            q = query(q, where('type', '==', type));
        }

        const querySnapshot = await getDocs(q);
        const events = querySnapshot.docs.map(doc => {
            const event = doc.data();
            // Remove guests from the initial load
            const { guests, ...eventWithoutGuests } = event;
            
            // Debug log for event date
            console.log('Raw event date:', event.event_date);
            console.log('Event date type:', typeof event.event_date);
            
            // Ensure we have a proper Date object
            let eventDate: Date;
            if (event.event_date instanceof Timestamp) {
                eventDate = event.event_date.toDate();
            } else if (event.event_date instanceof Date) {
                eventDate = event.event_date;
            } else if (typeof event.event_date === 'string') {
                eventDate = new Date(event.event_date);
            } else {
                console.error('Invalid event date format:', event.event_date);
                eventDate = new Date(); // Fallback to current date
            }

            return {
                id: doc.id,
                event_name: event.event_name,
                event_image: event.event_image,
                location: event.location,
                locationName: event.locationName,
                event_time: event.event_time,
                description: event.description,
                status: event.status,
                ...eventWithoutGuests,
                event_date: eventDate
            } as Event;
        });

        // Process events to add isUpcoming and daysRemaining
        const now = new Date();
        const processedEvents = events.map(event => {
            const eventDate = event.event_date;
            
            // Calculate days remaining for upcoming events
            const isUpcoming = eventDate >= now;
            let daysRemaining = null;

            if (isUpcoming) {
                // Set both dates to start of day for accurate day calculation
                const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                const startOfEvent = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
                
                // Calculate difference in days
                const diffTime = startOfEvent.getTime() - startOfToday.getTime();
                daysRemaining = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            }

            return {
                ...event,
                isUpcoming,
                daysRemaining
            } as Event;
        });

        // Sort events: upcoming first, then by date
        const sortedEvents = processedEvents.sort((a, b) => {
            // First sort by upcoming status
            if (a.isUpcoming !== b.isUpcoming) {
                return b.isUpcoming ? 1 : -1; // Upcoming events come first
            }
            
            // For upcoming events, sort by earliest date first
            if (a.isUpcoming && b.isUpcoming) {
                return a.event_date.getTime() - b.event_date.getTime();
            }
            
            // For past events, sort by most recent date first
            if (!a.isUpcoming && !b.isUpcoming) {
                return b.event_date.getTime() - a.event_date.getTime();
            }
            
            return 0;
        });

        return sortedEvents;
    } catch (error) {
        console.error('Error fetching events:', error);
        return [];
    }
};

// Get events by user ID
export const getEventsByUserId = async (userId: string) => {
    try {
        const q = query(eventsCollection, where('uid', '==', userId));
        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events by user ID:', error);
        throw error;
    }
};

// Read events with conditions (e.g., upcoming or past)
export const getEventsByCondition = async (condition: string) => {
    try {
        let q;
        if (condition === 'upcoming') {
            q = query(
                eventsCollection,
                where('date', '>=', new Date()),
                orderBy('date', 'asc')
            );
        } else if (condition === 'past') {
            q = query(
                eventsCollection,
                where('date', '<', new Date()),
                orderBy('date', 'desc')
            );
        } else {
            q = eventsCollection;
        }

        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events: ', error);
        throw error;
    }
};

// Update an event
export const updateEvent = async (eventId: string, updatedData: any) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await updateDoc(eventDoc, updatedData);
    } catch (error) {
        console.error('Error updating event: ', error);
        throw error;
    }
};

// Delete an event
export const deleteEvent = async (eventId: string) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await deleteDoc(eventDoc);
    } catch (error) {
        console.error('Error deleting event: ', error);
        throw error;
    }
};
