import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PersonAdd } from '@mui/icons-material';
import { CircularProgress, Chip, Menu, MenuItem, Checkbox, FormControlLabel, Box, Typography, Divider } from '@mui/material';
import { fetchUsers } from '../shared/services/userService';
import { useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { PeopleAlt } from '@mui/icons-material';
import { format, isToday } from 'date-fns';

type StatusType = 'connect' | 'connected' | 'pending';

interface Person {
  name: string;
  title: string;
  image: string;
  backgroundImage?: string;
  status: StatusType;
  uid: string; // Add uid to the interface
  gender?: string;
  profession?: string;
  skills?: string[];
  goals?: string[];
}

interface FilterOption {
  label: string;
  value: string;
}

const genderOptions: FilterOption[] = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' }
];

const skillsOptions: FilterOption[] = [
  { label: 'Book Lover', value: 'Book Lover' },
  { label: 'Science Enthusiast', value: 'Science Enthusiast' },
  { label: 'Nature Lover', value: 'Nature Lover' },
  { label: 'Philosophy & Thought', value: 'Philosophy & Thought' },
  { label: 'Art Enthusiast', value: 'Art Enthusiast' },
  { label: 'Dreamer', value: 'Dreamer' },
  { label: 'World Traveler', value: 'World Traveler' },
  { label: 'Inventor & Creator', value: 'Inventor & Creator' },
  { label: 'Film & Series Enthusiast', value: 'Film & Series Enthusiast' },
  { label: 'Space Explorer', value: 'Space Explorer' },
  { label: 'Spiritual Seeker', value: 'Spiritual Seeker' },
  { label: 'Poetry & Words', value: 'Poetry & Words' },
  { label: 'Tech & Gadgets', value: 'Tech & Gadgets' },
  { label: 'Design & Aesthetics', value: 'Design & Aesthetics' }
];

const goalsOptions: FilterOption[] = [
  { label: 'Connect with interesting people', value: 'Connect with interesting people' },
  { label: 'Share knowledge', value: 'Share knowledge' },
  { label: 'Find a romantic partner', value: 'Find a romantic partner' },
  { label: 'Meet co-founders', value: 'Meet co-founders' },
  { label: 'Learn & grow', value: 'Learn & grow' }
];

// Add this function at the top level
const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    if (!src) {
      resolve(false);
      return;
    }
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = src;
  });
};

const ConnectionsPage: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [people, setPeople] = useState<Person[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  
  // Filter states
  const [selectedGender, setSelectedGender] = useState<string[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  
  // Menu anchor states
  const [genderAnchorEl, setGenderAnchorEl] = useState<null | HTMLElement>(null);
  const [skillsAnchorEl, setSkillsAnchorEl] = useState<null | HTMLElement>(null);
  const [goalsAnchorEl, setGoalsAnchorEl] = useState<null | HTMLElement>(null);

  // Create a ref to store the current filters
  const filtersRef = useRef({
    gender: selectedGender,
    skills: selectedSkills,
    goals: selectedGoals
  });

  // Update the ref when filters change
  useEffect(() => {
    filtersRef.current = {
      gender: selectedGender,
      skills: selectedSkills,
      goals: selectedGoals
    };
  }, [selectedGender, selectedSkills, selectedGoals]);

  // Menu handlers
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, setAnchorEl: (el: HTMLElement | null) => void) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl: (el: HTMLElement | null) => void) => {
    setAnchorEl(null);
  };

  // Filter handlers
  const handleFilterSelect = (
    value: string,
    selectedValues: string[],
    setSelectedValues: (values: string[]) => void,
    setAnchorEl: (el: HTMLElement | null) => void
  ) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter(v => v !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedValues);
    setAnchorEl(null);
  };

  const getFilterLabel = (filterName: string, selectedValues: string[], options: FilterOption[]) => {
    if (selectedValues.length === 0) return filterName;
    if (selectedValues.length === 1) {
      return options.find(opt => opt.value === selectedValues[0])?.label || filterName;
    }
    return `${filterName} (${selectedValues.length})`;
  };

  // Data fetching
  const fetchUserData = useCallback(async (currentPage: number) => {
    if (loading || !hasMore) return;
    setLoading(true);

    try {
      const filters = filtersRef.current;
      const fetchedUsers: Person[] = await fetchUsers(currentPage, filters);
      
      if (fetchedUsers.length > 0) {
        setPeople(prev => {
          // If it's the first page, replace the entire array
          if (currentPage === 1) {
            return fetchedUsers;
          }
          // Otherwise append to existing array
          return [...prev, ...fetchedUsers];
        });
        setPage(prev => prev + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore]);

  // Reset and fetch data when filters change
  useEffect(() => {
    setPeople([]);
    setPage(1);
    setHasMore(true);
    fetchUserData(1);
  }, [selectedGender, selectedSkills, selectedGoals]);

  const handleUserClick = (uid: string) => {
    navigate(`/user/${uid}`);
  };

  // Create a ref for the scrollable container
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  // Throttling function to limit the number of scroll events
  const throttle = (func: Function, delay: number) => {
    let timeout: NodeJS.Timeout | null = null;
    return function (...args: any[]) {
      if (!timeout) {
        timeout = setTimeout(() => {
          func(...args);
          timeout = null;
        }, delay);
      }
    };
  };

  // Handle scroll with throttling
  const handleScroll = useCallback(
    throttle(() => {
      if (scrollContainerRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && !loading && hasMore) {
          fetchUserData(page);
        }
      }
    }, 500),
    [loading, hasMore, page, fetchUserData]
  );

  // Use the window scroll event listener to detect when the user reaches the bottom of the page
  useEffect(() => {
    const currentRef = scrollContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading, hasMore, handleScroll]);

  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());

  // Add this effect to preload images when people array changes
  useEffect(() => {
    const preloadImages = async () => {
      const newLoadedImages = new Set(loadedImages);
      
      for (const person of people) {
        if (person.image && !loadedImages.has(person.image)) {
          const loaded = await preloadImage(person.image);
          if (loaded) {
            newLoadedImages.add(person.image);
          }
        }
        if (person.backgroundImage && !loadedImages.has(person.backgroundImage)) {
          const loaded = await preloadImage(person.backgroundImage);
          if (loaded) {
            newLoadedImages.add(person.backgroundImage);
          }
        }
      }
      
      setLoadedImages(newLoadedImages);
    };

    preloadImages();
  }, [people]);

  return (
    <div>
      <div className="fixed inset-x-0 top-[56px] bottom-[56px] bg-gray-100">
        {/* Filter Bar */}
        <Box 
          sx={{ 
            backgroundColor: 'white',
            padding: '12px 16px',
            borderBottom: '1px solid #eee',
            display: 'flex',
            gap: '8px',
            overflowX: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
        >
          <Chip
            icon={<FilterListIcon />}
            label={getFilterLabel('Gender', selectedGender, genderOptions)}
            onClick={(e) => handleMenuOpen(e, setGenderAnchorEl)}
            variant={selectedGender.length > 0 ? 'filled' : 'outlined'}
            color={selectedGender.length > 0 ? 'primary' : 'default'}
            sx={{
              backgroundColor: selectedGender.length > 0 ? '#007AFF' : 'transparent',
              color: selectedGender.length > 0 ? 'white' : 'inherit',
              '&:hover': {
                backgroundColor: selectedGender.length > 0 ? '#005BBB' : '#f5f5f5',
              },
              borderColor: selectedGender.length > 0 ? '#007AFF' : '#e0e0e0',
            }}
          />
          <Menu
            anchorEl={genderAnchorEl}
            open={Boolean(genderAnchorEl)}
            onClose={() => handleMenuClose(setGenderAnchorEl)}
            PaperProps={{
              sx: {
                mt: 1.5,
                borderRadius: '16px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                minWidth: 280,
                padding: '12px',
                '& .MuiList-root': {
                  padding: 0
                }
              }
            }}
          >
            {genderOptions.map((option) => (
              <MenuItem 
                key={option.value}
                onClick={() => handleFilterSelect(option.value, selectedGender, setSelectedGender, setGenderAnchorEl)}
                sx={{
                  borderRadius: '10px',
                  mb: 0.5,
                  '&:last-child': {
                    mb: 0
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 122, 255, 0.06)'
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={selectedGender.includes(option.value)}
                      size="small"
                      sx={{
                        padding: '6px',
                        color: '#007AFF',
                        '&.Mui-checked': {
                          color: '#007AFF'
                        }
                      }}
                    />
                  }
                  label={
                    <Typography 
                      sx={{ 
                        fontSize: '0.9rem',
                        color: selectedGender.includes(option.value) ? '#007AFF' : '#333',
                        fontWeight: selectedGender.includes(option.value) ? 500 : 400
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  sx={{
                    margin: 0,
                    width: '100%',
                    padding: '4px 0'
                  }}
                />
              </MenuItem>
            ))}
            {selectedGender.length > 0 && (
              <>
                <Divider sx={{ my: 1 }} />
                <MenuItem 
                  onClick={() => {
                    setSelectedGender([]);
                    setGenderAnchorEl(null);
                  }}
                  sx={{
                    borderRadius: '10px',
                    justifyContent: 'center',
                    color: '#FF3B30',
                    fontWeight: 500,
                    fontSize: '0.9rem',
                    py: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 59, 48, 0.06)'
                    }
                  }}
                >
                  Clear Filter
                </MenuItem>
              </>
            )}
          </Menu>

          <Chip
            icon={<FilterListIcon />}
            label={getFilterLabel('Skills', selectedSkills, skillsOptions)}
            onClick={(e) => handleMenuOpen(e, setSkillsAnchorEl)}
            variant={selectedSkills.length > 0 ? 'filled' : 'outlined'}
            color={selectedSkills.length > 0 ? 'primary' : 'default'}
            sx={{
              backgroundColor: selectedSkills.length > 0 ? '#007AFF' : 'transparent',
              color: selectedSkills.length > 0 ? 'white' : 'inherit',
              '&:hover': {
                backgroundColor: selectedSkills.length > 0 ? '#005BBB' : '#f5f5f5',
              },
              borderColor: selectedSkills.length > 0 ? '#007AFF' : '#e0e0e0',
            }}
          />
          <Menu
            anchorEl={skillsAnchorEl}
            open={Boolean(skillsAnchorEl)}
            onClose={() => handleMenuClose(setSkillsAnchorEl)}
            PaperProps={{
              sx: {
                mt: 1.5,
                borderRadius: '16px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                minWidth: 280,
                maxHeight: '80vh',
                padding: '12px',
                '& .MuiList-root': {
                  padding: 0
                },
                '&::-webkit-scrollbar': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f5f5f5',
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ddd',
                  borderRadius: '10px',
                  '&:hover': {
                    background: '#ccc'
                  }
                }
              }
            }}
          >
            {skillsOptions.map((option) => (
              <MenuItem 
                key={option.value}
                onClick={() => handleFilterSelect(option.value, selectedSkills, setSelectedSkills, setSkillsAnchorEl)}
                sx={{
                  borderRadius: '10px',
                  mb: 0.5,
                  '&:last-child': {
                    mb: 0
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 122, 255, 0.06)'
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={selectedSkills.includes(option.value)}
                      size="small"
                      sx={{
                        padding: '6px',
                        color: '#007AFF',
                        '&.Mui-checked': {
                          color: '#007AFF'
                        }
                      }}
                    />
                  }
                  label={
                    <Typography 
                      sx={{ 
                        fontSize: '0.9rem',
                        color: selectedSkills.includes(option.value) ? '#007AFF' : '#333',
                        fontWeight: selectedSkills.includes(option.value) ? 500 : 400
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  sx={{
                    margin: 0,
                    width: '100%',
                    padding: '4px 0'
                  }}
                />
              </MenuItem>
            ))}
            {selectedSkills.length > 0 && (
              <>
                <Divider sx={{ my: 1 }} />
                <MenuItem 
                  onClick={() => {
                    setSelectedSkills([]);
                    setSkillsAnchorEl(null);
                  }}
                  sx={{
                    borderRadius: '10px',
                    justifyContent: 'center',
                    color: '#FF3B30',
                    fontWeight: 500,
                    fontSize: '0.9rem',
                    py: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 59, 48, 0.06)'
                    }
                  }}
                >
                  Clear Filter
                </MenuItem>
              </>
            )}
          </Menu>

          <Chip
            icon={<FilterListIcon />}
            label={getFilterLabel('Goals', selectedGoals, goalsOptions)}
            onClick={(e) => handleMenuOpen(e, setGoalsAnchorEl)}
            variant={selectedGoals.length > 0 ? 'filled' : 'outlined'}
            color={selectedGoals.length > 0 ? 'primary' : 'default'}
            sx={{
              backgroundColor: selectedGoals.length > 0 ? '#007AFF' : 'transparent',
              color: selectedGoals.length > 0 ? 'white' : 'inherit',
              '&:hover': {
                backgroundColor: selectedGoals.length > 0 ? '#005BBB' : '#f5f5f5',
              },
              borderColor: selectedGoals.length > 0 ? '#007AFF' : '#e0e0e0',
            }}
          />
          <Menu
            anchorEl={goalsAnchorEl}
            open={Boolean(goalsAnchorEl)}
            onClose={() => handleMenuClose(setGoalsAnchorEl)}
            PaperProps={{
              sx: {
                mt: 1.5,
                borderRadius: '16px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                minWidth: 280,
                maxHeight: '80vh',
                padding: '12px',
                '& .MuiList-root': {
                  padding: 0
                },
                '&::-webkit-scrollbar': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f5f5f5',
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ddd',
                  borderRadius: '10px',
                  '&:hover': {
                    background: '#ccc'
                  }
                }
              }
            }}
          >
            {goalsOptions.map((option) => (
              <MenuItem 
                key={option.value}
                onClick={() => handleFilterSelect(option.value, selectedGoals, setSelectedGoals, setGoalsAnchorEl)}
                sx={{
                  borderRadius: '10px',
                  mb: 0.5,
                  '&:last-child': {
                    mb: 0
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 122, 255, 0.06)'
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={selectedGoals.includes(option.value)}
                      size="small"
                      sx={{
                        padding: '6px',
                        color: '#007AFF',
                        '&.Mui-checked': {
                          color: '#007AFF'
                        }
                      }}
                    />
                  }
                  label={
                    <Typography 
                      sx={{ 
                        fontSize: '0.9rem',
                        color: selectedGoals.includes(option.value) ? '#007AFF' : '#333',
                        fontWeight: selectedGoals.includes(option.value) ? 500 : 400
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  sx={{
                    margin: 0,
                    width: '100%',
                    padding: '4px 0'
                  }}
                />
              </MenuItem>
            ))}
            {selectedGoals.length > 0 && (
              <>
                <Divider sx={{ my: 1 }} />
                <MenuItem 
                  onClick={() => {
                    setSelectedGoals([]);
                    setGoalsAnchorEl(null);
                  }}
                  sx={{
                    borderRadius: '10px',
                    justifyContent: 'center',
                    color: '#FF3B30',
                    fontWeight: 500,
                    fontSize: '0.9rem',
                    py: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 59, 48, 0.06)'
                    }
                  }}
                >
                  Clear Filter
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>

        <div
          ref={scrollContainerRef}
          className="h-full overflow-y-auto px-4 no-scrollbar overscroll-none"
        >
          <div className="max-w-6xl mx-auto grid grid-cols-2 gap-4 py-4">
            {people.map((person, idx) => (
              <div
                key={idx}
                className="relative bg-white rounded-xl shadow-md overflow-hidden flex flex-col items-center cursor-pointer hover:shadow-lg transition-shadow duration-200"
                onClick={() => handleUserClick(person.uid)}
              >
                {/* Top banner */}
                <div
                  className="h-20 w-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${person.backgroundImage})` }}
                />

                {/* Dismiss (X) button */}
                <button
                  className="absolute top-2 right-2 bg-black bg-opacity-60 rounded-full text-white w-6 h-6 flex items-center justify-center text-sm hover:bg-opacity-80 z-10"
                  title="Dismiss"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Add dismiss functionality here if needed
                  }}
                >
                  ×
                </button>

                {/* Profile picture */}
                <div className="-mt-12 relative">
                  <div className="w-24 h-24 rounded-full border-4 border-white overflow-hidden">
                    <img
                      src={person.image || '/profileDefault.png'}
                      alt={person.name}
                      className={`w-full h-full object-cover transition-opacity duration-200 ${
                        loadedImages.has(person.image || '') ? 'opacity-100' : 'opacity-0'
                      }`}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = '/profileDefault.png';
                      }}
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                </div>

                {/* Info */}
                <div className="text-center px-4 pb-5 pt-2 w-full">
                  <h3 className="font-semibold text-gray-800 text-sm truncate">{person.name}</h3>
                  <p className="text-xs text-gray-600 truncate mt-1">{person.title}</p>
                  <p className="text-xs text-gray-400 mt-1">Based on your profile</p>

                  {/* Connect Button */}
                  <button
                    className="mt-3 w-full border border-blue-600 text-blue-600 hover:bg-blue-50 font-medium text-sm py-1.5 rounded-full flex items-center justify-center gap-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Add connect functionality here
                    }}
                  >
                    <PersonAdd style={{ fontSize: 16 }} />
                    Connect
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Loading and no more users messages */}
          <div className="py-4">
            {loading && (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
            {!hasMore && !loading && (
              <div className="text-center text-gray-500">No more users to load.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsPage;
