import React, { CSSProperties } from 'react';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const CSAEPolicy: React.FC = () => {
  const containerStyle: CSSProperties = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: isMobile ? '20px' : '70px',
  };

  const titleStyle: CSSProperties = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  };

  const updatedStyle: CSSProperties = {
    fontSize: '1rem',
    color: '#666',
    textAlign: 'center',
    marginBottom: '20px',
  };

  const sectionTitleStyle: CSSProperties = {
    fontSize: isMobile ? '1.8rem' : '1.2rem',
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '15px',
  };

  const subsectionStyle: CSSProperties = {
    marginBottom: '20px',
  };

  const subsectionTitleStyle: CSSProperties = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Child Sexual Abuse and Exploitation (CSAE) Policy</h1>
      <p style={updatedStyle}><strong>Last Updated: 26/11/2024</strong></p>
      
      <h2 style={sectionTitleStyle}>1. Our Commitment</h2>
      <p style={subsectionStyle}>Salonim Live is committed to preventing and addressing child sexual abuse and exploitation (CSAE) in any form. We have a zero-tolerance policy towards any content, activity, or behavior that violates this principle.</p>
      
      <h2 style={sectionTitleStyle}>2. Prohibited Content and Behavior</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>2.1</strong> Users are strictly prohibited from sharing, uploading, or engaging in any content that involves child exploitation or abuse.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>2.2</strong> Any attempt to groom, solicit, or harm minors through our platform is strictly forbidden and will be reported to the relevant authorities.</p>
      
      <h2 style={sectionTitleStyle}>3. Reporting and Action</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>3.1</strong> If CSAE content is detected or reported, we take immediate action, including but not limited to:</p>
      <ul>
        <li>Removing the content</li>
        <li>Suspending or banning the user involved</li>
        <li>Reporting to law enforcement and child protection organizations</li>
      </ul>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>3.2</strong> Users can report any suspected CSAE activity via our official email: <strong>abuse@salonim.live</strong>.</p>
      
      <h2 style={sectionTitleStyle}>4. Prevention Measures</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>4.1</strong> We actively monitor and review reported content to prevent CSAE-related activities on our platform.</p>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>4.2</strong> We cooperate with child protection agencies and law enforcement to ensure the safety of minors.</p>
      
      <h2 style={sectionTitleStyle}>5. Legal Compliance</h2>
      <p style={subsectionStyle}><strong style={subsectionTitleStyle}>5.1</strong> We comply with all applicable local and international laws regarding CSAE prevention, including mandatory reporting obligations.</p>
      
      <h2 style={sectionTitleStyle}>6. Contact Information</h2>
      <p>If you have any concerns or need to report an issue, please contact us at:</p>
      <p>Email: abuse@salonim.live</p>
    </div>
  );
};

export default CSAEPolicy;