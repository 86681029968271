import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress, Typography } from "@mui/material";

const OIDCLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: event } = location.state || {};
    const { loginWithRedirect, isLoading } = useAuth0();

    const handleLogin = async () => {
        try {
            await loginWithRedirect({
                appState: { returnTo: event?.id ? `/events/${event.id}` : '/events' }
            });
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    React.useEffect(() => {
        handleLogin();
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
                Redirecting to login...
            </Typography>
        </Box>
    );
};

export default OIDCLogin;
