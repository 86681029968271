import React, { useState } from 'react';
import { Box, Typography, Chip, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface BadgeSelectionScreenProps {
    onNext: () => void;
    setBadges: (value: string[]) => void;
    selectedBadges: string[];
    onBack?: () => void; // Make it optional
    skipButton?: React.ReactNode;
}



const BadgeSelectionScreen: React.FC<BadgeSelectionScreenProps> = ({ onNext, setBadges, selectedBadges, skipButton }) => {
    const badgeOptions = [
        { label: "Book Lover", icon: "📚" },
        { label: "Science Enthusiast", icon: "🔬" },
        { label: "Nature Lover", icon: "🌳" },
        { label: "Philosophy & Thought", icon: "🧠" },
        { label: "Art Enthusiast", icon: "🎭" },
        { label: "Dreamer", icon: "🌌" },
        { label: "World Traveler", icon: "🌍" },
        { label: "Inventor & Creator", icon: "💡" },
        { label: "Film & Series Enthusiast", icon: "🎥" },
        { label: "Space Explorer", icon: "🚀" },
        { label: "Spiritual Seeker", icon: "🧘‍♂️" },
        { label: "Poetry & Words", icon: "✍️" },
        { label: "Tech & Gadgets", icon: "💻" },
        { label: "Design & Aesthetics", icon: "🎨" }
    ];

    const [selectedOptions, setSelectedOptions] = useState<string[]>([...selectedBadges]);

    const handleSelect = (badge: string) => {
        if (selectedOptions.includes(badge)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== badge));
        } else if (selectedOptions.length < 5) {
            setSelectedOptions([...selectedOptions, badge]);
        }
    };

    const handleNext = () => {
        setBadges([...selectedOptions]);
        onNext();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px',
                width: '100%',
                maxWidth: '420px',
                mx: 'auto',
                bgcolor: '#F8F9FA', // Soft background
                borderRadius: '20px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)', // Soft shadow effect
                boxSizing: 'border-box',
                textAlign: 'center',
            }}
        >
            {/* Header */}
            <Typography variant="h4" sx={{
                fontWeight: 700,
                fontSize: '32px',
                color: '#222',
                fontFamily: '"Poppins", sans-serif',
                marginBottom: '16px',
            }}>
                Choose what you're into
            </Typography>
            <Typography sx={{
                fontWeight: 500,
                fontSize: '18px',
                color: '#555',
                fontFamily: '"Poppins", sans-serif',
                marginBottom: '24px',
            }}>
                Help us match you with the best events and people.
            </Typography>

            {/* Badge Options */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '12px',
                    justifyContent: 'center',
                    mb: 3,
                }}
            >
                {badgeOptions.map((badge) => (
                    <Chip
                        key={badge.label}
                        label={`${badge.icon} ${badge.label}`}
                        onClick={() => handleSelect(badge.label)}
                        sx={{
                            cursor: 'pointer',
                            padding: '14px',
                            fontSize: '1.1rem',
                            fontWeight: selectedOptions.includes(badge.label) ? 'bold' : '500',
                            backgroundColor: selectedOptions.includes(badge.label) ? '#0056D2' : '#E9ECEF',
                            color: selectedOptions.includes(badge.label) ? '#FFFFFF' : '#555',
                            borderRadius: '25px',
                            border: selectedOptions.includes(badge.label) ? '2px solid #0056D2' : 'none',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: selectedOptions.includes(badge.label) ? '#0045A0' : '#DEE2E6',
                            },
                        }}
                    />
                ))}
            </Box>

            {/* Next Button */}
            <Button
                onClick={handleNext}
                sx={{
                    backgroundColor: '#FFFFFF', // White background
                    color: '#222', // Dark text
                    fontWeight: 700,
                    fontSize: '20px',
                    textTransform: 'none',
                    borderRadius: '24px',
                    padding: '18px 36px',
                    fontFamily: '"Poppins", sans-serif',
                    border: '2px solid #222', // Subtle border for definition
                    '&:hover': {
                        backgroundColor: '#F0F0F0',
                    },
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Next <ArrowForwardIcon sx={{ marginLeft: '10px', fontSize: '26px' }} />
            </Button>

           
        </Box>
    );
};

export default BadgeSelectionScreen;
