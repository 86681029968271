import { Timestamp } from 'firebase/firestore';

export interface PaymentSession {
  sessionId: string;
  userId: string;
  eventId: string;
  amount: number;
  status: 'pending' | 'success' | 'failed' | 'timeout';
  createdAt: Timestamp;
  expiresAt: Timestamp;
  errorCode?: string;
  errorMessage?: string;
  transactionId?: string;
}

export interface TranzilaWebhookData {
  data: string;
  sum: string;
  status: string;
  processor_response_code: string;
  processor_response_message: string;
  transaction_id?: string;
}

export interface PaymentStatus {
  code: string;
  message: string;
  action: 'success' | 'retry' | 'support' | 'partial';
  showSupport: boolean;
}

export const TRANZILA_STATUS_CODES: Record<string, PaymentStatus> = {
  '000': {
    code: '000',
    message: 'התשלום בוצע בהצלחה',
    action: 'success',
    showSupport: false
  },
  '777': {
    code: '777',
    message: 'הפעולה הושלמה בהצלחה',
    action: 'success',
    showSupport: false
  },
  '001': {
    code: '001',
    message: 'כרטיס חסום. אנא בדוק את מספר הכרטיס ונסה שוב',
    action: 'retry',
    showSupport: false
  },
  '002': {
    code: '002',
    message: 'כרטיס גנוב. אנא בדוק את מספר הכרטיס ונסה שוב',
    action: 'retry',
    showSupport: false
  },
  '003': {
    code: '003',
    message: 'יש ליצור קשר עם חברת האשראי לאישור העסקה',
    action: 'support',
    showSupport: true
  },
  '004': {
    code: '004',
    message: 'העסקה נדחתה. אנא פנה לבעל הכרטיס לבדיקה מול חברת האשראי',
    action: 'support',
    showSupport: true
  },
  '005': {
    code: '005',
    message: 'כרטיס מזויף. אנא בדוק את פרטי הכרטיס ונסה שוב',
    action: 'retry',
    showSupport: false
  },
  '006': {
    code: '006',
    message: 'מספר זהות או קוד CVV שגוי',
    action: 'retry',
    showSupport: false
  },
  '007': {
    code: '007',
    message: 'אימות 3D Secure נכשל',
    action: 'retry',
    showSupport: false
  },
  '008': {
    code: '008',
    message: 'אימות כתובת נכשל',
    action: 'retry',
    showSupport: false
  },
  '009': {
    code: '009',
    message: 'תקלה בתקשורת. אנא נסה שוב',
    action: 'retry',
    showSupport: false
  },
  '010': {
    code: '010',
    message: 'העסקה אושרה חלקית',
    action: 'partial',
    showSupport: true
  },
  '011': {
    code: '011',
    message: 'חוסר בנקודות/כוכבים/מיילים/הטבה אחרת',
    action: 'support',
    showSupport: true
  },
  '012': {
    code: '012',
    message: 'כרטיס לא מורשה לטרמינל זה',
    action: 'support',
    showSupport: true
  },
  '013': {
    code: '013',
    message: 'קוד יתרה שגוי',
    action: 'support',
    showSupport: true
  },
  'default': {
    code: 'unknown',
    message: 'אירעה שגיאה בעיבוד התשלום. אנא נסה שוב',
    action: 'retry',
    showSupport: true
  }
}; 