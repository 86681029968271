import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tabs, TabList, Tab } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Event, Person, Home, PeopleAlt } from '@mui/icons-material';
import './overrides/TabOverrides.css'; // Import the CSS file
import { Box, CircularProgress, Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { useAuth } from '../../context/AuthContext';
import { getUserEvents } from '../../shared/services/userService';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
`;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 768;

const TabLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [showAccessDialog, setShowAccessDialog] = useState(false);

    const handleConnectionsClick = async (e: React.MouseEvent) => {
        if (location.pathname === '/connections') return;
        
        e.preventDefault();
        setIsCheckingStatus(true);
        console.log('🔍 Checking user status for Connections access...');
        
        try {
            if (!user) {
                console.log('❌ No user found');
                setShowAccessDialog(true);
                return;
            }

            // Extract user ID safely
            const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
            console.log('Checking status for user:', userId);

            // Get user's phone number
            const userPhoneNumber = user?.phoneNumber;
            console.log('User phone number:', userPhoneNumber);

            // Check if user is an admin
            const isAdmin = userPhoneNumber === '+972524042005' || userPhoneNumber === '+972547678267';
            console.log('Is user admin?', isAdmin);

            if (isAdmin) {
                console.log('✅ User is recognized as admin, allowing access to Connections');
                navigate('/connections');
                return;
            }

            // Get user's events to check their status
            const userEvents = await getUserEvents(userId);
            console.log('User events:', userEvents);

            // Debug log to see event structure
            userEvents.forEach((event: any, index: number) => {
                console.log(`Event ${index + 1}:`, {
                    id: event.event_id,
                    status: event.status,
                    fullEvent: event
                });
            });

            // Check if user has any event with paid_in status that's happening now
            const paidInEvent = userEvents.find((event: any) => {
                if (event.status !== 'paid_in') return false;
                
                // Get the event details to check its date
                const eventDate = event.event_date?.seconds 
                    ? new Date(event.event_date.seconds * 1000)
                    : new Date(event.event_date);
                
                const now = new Date();
                const eventStart = new Date(eventDate);
                const eventEnd = new Date(eventDate);
                eventEnd.setHours(eventEnd.getHours() + 4); // Assuming events last 4 hours

                return now >= eventStart && now <= eventEnd;
            });

            console.log('Found paid_in event happening now:', paidInEvent);

            if (paidInEvent) {
                console.log('✅ User has an event with paid_in status that is happening now, allowing access');
                navigate('/connections');
            } else {
                console.log('❌ User does not have any events with paid_in status that are happening now');
                setShowAccessDialog(true);
            }
        } catch (error) {
            console.error('Error checking status:', error);
            setShowAccessDialog(true);
        } finally {
            setIsCheckingStatus(false);
        }
    };

    const getTabStyle = (path: string) => ({
        ...styles.tabStyle,
        iconStyle: location.pathname === path ? styles.activeIconStyle : styles.iconStyle,
        labelStyle: location.pathname === path ? styles.activeLabelStyle : styles.labelStyle,
    });

    return (
        <div style={styles.containerStyle}>
            {/* Content Area */}
            <div style={styles.contentStyle}>{children}</div>

            {/* Access Restricted Dialog */}
            <Dialog
                open={showAccessDialog}
                onClose={() => {
                    setShowAccessDialog(false);
                    navigate('/events');
                }}
                PaperProps={{
                    sx: {
                        borderRadius: '16px',
                        padding: '24px',
                        maxWidth: '90%',
                        width: '320px',
                        margin: '16px',
                        background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)'
                    }
                }}
            >
                <DialogContent>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                        <Box
                            sx={{
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                                backgroundColor: '#f5f5f5',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '0 auto 16px',
                                background: 'linear-gradient(45deg, #f5f5f5, #ffffff)'
                            }}
                        >
                            <PeopleAlt sx={{ fontSize: 32, color: '#9e9e9e' }} />
                        </Box>
                        <Typography variant="h6" sx={{ 
                            fontWeight: 600, 
                            mb: 1,
                            fontSize: '1.25rem',
                            color: '#1a73e8'
                        }}>
                            🎉 Almost There!
                        </Typography>
                        <Typography variant="body1" sx={{ 
                            color: '#5f6368',
                            mb: 1,
                            fontSize: '0.95rem'
                        }}>
                            This option unlocks when you arrive at the event.
                        </Typography>
                        <Typography variant="body2" sx={{ 
                            color: '#1a73e8',
                            fontStyle: 'italic',
                            fontSize: '0.9rem'
                        }}>
                            Can't wait to see you in person!
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setShowAccessDialog(false);
                            navigate('/events');
                        }}
                        sx={{
                            background: 'linear-gradient(45deg, #1a73e8, #0d47a1)',
                            color: 'white',
                            borderRadius: '20px',
                            textTransform: 'none',
                            px: 4,
                            py: 1,
                            fontSize: '0.95rem',
                            fontWeight: 500,
                            boxShadow: '0 2px 8px rgba(26, 115, 232, 0.3)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #1557b0, #0a3880)',
                                boxShadow: '0 4px 12px rgba(26, 115, 232, 0.4)'
                            }
                        }}
                    >
                        Go to Events
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Footer Tabs */}
            <div style={styles.footerTabsStyle}>
                <div style={styles.backgroundStyle}>
                    <Tabs>
                        <TabList style={styles.tabListStyle}>
                            {/* Home Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/about" style={styles.linkStyle}>
                                    <Home
                                        className="icon"
                                        style={
                                            location.pathname === '/about'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/about'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        About
                                    </span>
                                </Link>
                            </Tab>
                            <Tab style={styles.tabStyle}>
                                <Link 
                                    to="/connections" 
                                    style={styles.linkStyle}
                                    onClick={handleConnectionsClick}
                                >
                                    <Box sx={{ position: 'relative' }}>
                                        <PeopleAlt
                                            className="icon"
                                            style={
                                                location.pathname === '/connections'
                                                    ? styles.activeIconStyle
                                                    : styles.iconStyle
                                            }
                                        />
                                        {isCheckingStatus && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '24px',
                                                    height: '24px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                <CircularProgress size={16} />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <span
                                            style={
                                                location.pathname === '/connections'
                                                    ? styles.activeLabelStyle
                                                    : styles.labelStyle
                                            }
                                        >
                                            Connections
                                        </span>
                                        <Box
                                            sx={{
                                                width: '8px',
                                                height: '8px',
                                                borderRadius: '50%',
                                                backgroundColor: '#4CAF50',
                                                animation: `${blink} 1.5s infinite`,
                                            }}
                                        />
                                    </Box>
                                </Link>
                            </Tab>

                            {/* Events Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/events" style={styles.linkStyle}>
                                    <Event
                                        className="icon"
                                        style={
                                            location.pathname === '/events'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/events'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        Events
                                    </span>
                                </Link>
                            </Tab>

                            {/* Profile Tab */}
                            <Tab style={styles.tabStyle}>
                                <Link to="/profile" style={styles.linkStyle}>
                                    <Person
                                        className="icon"
                                        style={
                                            location.pathname === '/profile'
                                                ? styles.activeIconStyle
                                                : styles.iconStyle
                                        }
                                    />
                                    <span
                                        style={
                                            location.pathname === '/profile'
                                                ? styles.activeLabelStyle
                                                : styles.labelStyle
                                        }
                                    >
                                        Profile
                                    </span>
                                </Link>
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: isMobile ? '100%' : 'unset',
    },
    contentStyle: {
        flex: 1,
        overflow: 'auto',
        paddingBottom: '90px', // Increased padding to account for footer tabs
        width: isMobile ? '100%' : '375px',
        marginTop: '56px', // Add margin for the header
        height: 'calc(100vh - 56px)', // Adjust height to account for header
    },
    footerTabsStyle: {
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: isMobile ? '100%' : '375px',
        height: '70px',
        zIndex: 1000,
    },
    backgroundStyle: {
        width: '100%',
        height: '90px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.04)',
        borderRadius: '30px 30px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        padding: '0px',
        gap: '10px',
    },
    tabListStyle: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '0px',
    },
    tabStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontFamily: 'Arialsans-serif, Arial, sans-serif',
        fontWeight: '500',
    },
    linkStyle: {
        color: '#4867B7',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textDecoration: 'none',
        gap: '5px',
    },
    iconStyle: {
        fontSize: '24px',
        color: '#AAAAAA', // Default color for unselected icons
    },
    activeIconStyle: {
        fontSize: '24px',
        color: '#5D9BFC', // Active color for selected icons
    },
    labelStyle: {
        color: '#AAAAAA', // Default label color
    },
    activeLabelStyle: {
        color: '#4867B7', // Active label color
    },
};

export default TabLayout;