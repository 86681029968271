// src/components/FallbackProfilePage.tsx

import React, { useEffect } from 'react'; // Import React library and useEffect hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom for navigation
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';

// FallbackProfilePage component for users who are not connected
const FallbackProfilePage: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate function
  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();

  console.log('🔍 FallbackProfilePage State:', {
    isAuthenticated,
    isLoading,
    user,
    currentPath: window.location.pathname
  });

  // Handle navigation after authentication
  useEffect(() => {
    if (isAuthenticated && user && !isLoading) {
      console.log('👤 User authenticated, redirecting to profile:', {
        userId: user.sub,
        email: user.email
      });
      navigate('/profile');
    }
  }, [isAuthenticated, user, navigate, isLoading]);

  // Function to handle sign up button click
  const handleSignUp = async () => {
    console.log('🚀 Starting login process...');
    try {
      console.log('📝 Login parameters:', {
        returnTo: '/events',
        currentUrl: window.location.href
      });
      
      await loginWithRedirect({
        appState: { 
          returnTo: '/events',
          timestamp: Date.now() // Add timestamp to prevent state mismatch
        }
      });
      
      console.log('✅ Login redirect initiated');
    } catch (error) {
      console.error('❌ Login failed:', error);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}> {/* Center align text and add padding */}
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography> {/* Heading for the profile page */}
      <Typography variant="body1" gutterBottom>
        You are not connected. Please sign up to access your profile.
      </Typography> {/* Message for users who are not connected */}
      <Button
        onClick={handleSignUp} // Attach handleSignUp function to button click
        variant="contained"
        color="primary"
        disabled={isLoading}
        sx={{
          padding: '10px 20px', // Button padding
          fontSize: '16px', // Button font size
          textTransform: 'none',
          borderRadius: '5px', // Button border radius
        }}
      >
        {isLoading ? 'Signing up...' : 'Sign Up'}
      </Button>
    </Box>
  );
};

export default FallbackProfilePage; // Export FallbackProfilePage component