import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Alert,
    Snackbar,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(4),
    maxWidth: '400px',
    width: '100%',
    textAlign: 'center',
}));

const PhoneIconWrapper = styled(Box)(({ theme }) => ({
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
        fontSize: '40px',
        color: theme.palette.primary.contrastText,
    },
}));

interface PhoneVerificationScreenProps {
    onVerify: (phoneNumber: string) => void;
    onBack: () => void;
}

const PhoneVerificationScreen: React.FC<PhoneVerificationScreenProps> = ({
    onVerify,
    onBack,
}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showVerificationInput, setShowVerificationInput] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(10);
    const [generatedCode, setGeneratedCode] = useState<string>('');
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });

    const normalizePhoneNumber = (phone: string): string => {
        if (!phone) return '';
        
        // Remove any spaces or special characters
        const cleanPhone = phone.replace(/[\s-()]/g, '');
        
        // If it starts with 0, convert to +972 format
        if (cleanPhone.startsWith('0')) {
            return '+972' + cleanPhone.substring(1);
        }
        
        // If it's already in +972 format, return as is
        if (cleanPhone.startsWith('+972')) {
            return cleanPhone;
        }
        
        // If it's just numbers, assume it's a local number and add +972
        if (/^\d+$/.test(cleanPhone)) {
            return '+972' + cleanPhone.replace(/^0+/, '');
        }
        
        return cleanPhone;
    };

    const startResendCountdown = () => {
        setResendCountdown(10);
        const timer = setInterval(() => {
            setResendCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setIsResendDisabled(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handlePhoneSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Basic phone number validation
            const cleanPhone = phoneNumber.replace(/\D/g, '');
            if (cleanPhone.length < 10) {
                throw new Error('Please enter a valid phone number');
            }

            // Format phone number to international format
            const formattedPhone = normalizePhoneNumber(phoneNumber);

            // Generate a random 6-digit code
            const code = Math.floor(100000 + Math.random() * 900000).toString();
            setGeneratedCode(code);

            // Send verification code
            const response = await fetch('https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: formattedPhone,
                    text: `Your verification code is: ${code}`
                })
            });

            if (!response.ok) {
                throw new Error('Failed to send verification code');
            }

            setShowVerificationInput(true);
            setIsResendDisabled(true);
            startResendCountdown();
            setSnackbar({
                open: true,
                message: 'Verification code sent!',
                severity: 'success',
            });
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
            setSnackbar({
                open: true,
                message: err instanceof Error ? err.message : 'An error occurred',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleVerificationSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            if (verificationCode !== generatedCode) {
                throw new Error('Invalid verification code');
            }

            // Format phone number to international format
            const formattedPhone = normalizePhoneNumber(phoneNumber);
            onVerify(formattedPhone);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
            setSnackbar({
                open: true,
                message: err instanceof Error ? err.message : 'An error occurred',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <StyledBox>
            <PhoneIconWrapper>
                <PhoneIcon />
            </PhoneIconWrapper>

            <Typography variant="h5" component="h1" gutterBottom>
                Verify Your Phone Number
            </Typography>

            <Typography variant="body1" color="text.secondary" gutterBottom>
                Please enter your phone number to access your existing account
            </Typography>

            {error && (
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            )}

            {!showVerificationInput ? (
                <form onSubmit={handlePhoneSubmit} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="+972547678267 or 0547678267"
                        disabled={loading}
                        required
                        sx={{ mb: 2 }}
                    />

                    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                        <Button
                            variant="outlined"
                            onClick={onBack}
                            disabled={loading}
                            fullWidth
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={24} /> : 'Send Code'}
                        </Button>
                    </Box>
                </form>
            ) : (
                <form onSubmit={handleVerificationSubmit} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        label="Verification Code"
                        variant="outlined"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        placeholder="Enter 6-digit code"
                        disabled={loading}
                        required
                        sx={{ mb: 2 }}
                    />

                    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                        <Button
                            variant="outlined"
                            onClick={onBack}
                            disabled={loading}
                            fullWidth
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={24} /> : 'Verify Code'}
                        </Button>
                    </Box>

                    <Button
                        fullWidth
                        variant="text"
                        onClick={handlePhoneSubmit}
                        disabled={isResendDisabled || loading}
                        sx={{
                            mt: 2,
                            color: '#1976d2',
                        }}
                    >
                        {isResendDisabled ? `Resend code in ${resendCountdown}s` : 'Resend Code'}
                    </Button>
                </form>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </StyledBox>
    );
};

export default PhoneVerificationScreen; 