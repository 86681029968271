import React from 'react';
import { Box, Typography } from '@mui/material';

const StagingIndicator: React.FC = () => {
  // Only show in staging environment
  const isStaging = window.location.hostname === 'staging.salonim.live';
  
  if (!isStaging) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        backgroundColor: '#FFA500',
        color: 'white',
        padding: '4px 8px',
        borderRadius: '0 0 0 8px',
        zIndex: 9999,
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        Staging Mode
      </Typography>
    </Box>
  );
};

export default StagingIndicator; 