import React from 'react';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import QRCode from 'react-qr-code';

// Define types for props
interface EventProps {
    event_image: string;
    event_name: string;
    locationName: string;
    hall: string;
    event_date: Timestamp | Date | { seconds: number } | any;
}

interface UserProps {
    name: string;
}

interface BookingProps {
    seat: string;
    date: string;
    time: string;
    paymentAuth: string;
    paidPrice: number;
    qrCode: string;
    amount: string;
}

interface TicketProps {
    event: EventProps;
    user: UserProps;
    booking: BookingProps;
    onClose?: () => void;
}

const Ticket: React.FC<TicketProps> = ({ event, user, booking, onClose }) => {
    const styles = {
        container: {
            backgroundColor: '#ffffff',
            padding: '24px',
            borderRadius: '16px',
            maxWidth: '400px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
            position: 'relative' as const,
        },
        closeButton: {
            position: 'absolute' as const,
            top: '16px',
            right: '16px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            color: '#64748B',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: '32px',
            height: '32px',
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: '#F1F5F9',
                color: '#1E293B',
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#1E293B',
            margin: 0,
        },
        eventInfo: {
            display: 'flex',
            marginBottom: '24px',
            gap: '16px',
        },
        eventImage: {
            width: '80px',
            height: '80px',
            borderRadius: '12px',
            objectFit: 'cover' as const,
        },
        eventDetails: {
            display: 'flex',
            flexDirection: 'column' as const,
            flex: 1,
        },
        eventLabel: {
            fontSize: '12px',
            color: '#64748B',
            marginBottom: '4px',
        },
        eventTitle: {
            margin: '0 0 8px 0',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#1E293B',
        },
        eventLocation: {
            margin: 0,
            fontSize: '14px',
            color: '#64748B',
        },
        detailsSection: {
            backgroundColor: '#F8FAFC',
            borderRadius: '12px',
            padding: '16px',
            marginBottom: '24px',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
        },
        label: {
            fontSize: '14px',
            color: '#64748B',
        },
        value: {
            fontSize: '14px',
            color: '#1E293B',
            fontWeight: '500',
        },
        qrSection: {
            display: 'flex',
            flexDirection: 'column' as const,
            alignItems: 'center',
            gap: '12px',
            marginTop: '24px',
            padding: '16px',
            backgroundColor: '#F8FAFC',
            borderRadius: '12px',
        },
        qrCode: {
            padding: '16px',
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
        },
        footer: {
            marginTop: '24px',
            textAlign: 'center' as const,
            color: '#64748B',
            fontSize: '12px',
        },
    };

    return (
        <div style={styles.container}>
            {onClose && (
                <button 
                    onClick={onClose} 
                    style={styles.closeButton}
                    aria-label="Close ticket"
                >
                    ×
                </button>
            )}
            <div style={styles.header}>
                <h2 style={styles.title}>TICKET</h2>
            </div>

            <div style={styles.eventInfo}>
                <img src={event.event_image} alt={event.event_name} style={styles.eventImage} />
                <div style={styles.eventDetails}>
                    <span style={styles.eventLabel}>Event</span>
                    <h3 style={styles.eventTitle}>{event.event_name}</h3>
                    <p style={styles.eventLocation}>{event.locationName}</p>
                </div>
            </div>

            <div style={styles.detailsSection}>
                <div style={styles.row}>
                    <span style={styles.label}>Event Hall</span>
                    <span style={styles.value}>{event.hall || event.locationName}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>Date</span>
                    <span style={styles.value}>
                        {event.event_date?.seconds
                            ? format(new Date(event.event_date.seconds * 1000), 'dd/MM/yyyy')
                            : event.event_date instanceof Date
                            ? format(event.event_date, 'dd/MM/yyyy')
                            : format(new Date(event.event_date), 'dd/MM/yyyy')}
                    </span>
                </div>
                {booking.time && (
                    <div style={styles.row}>
                        <span style={styles.label}>Time</span>
                        <span style={styles.value}>{booking.time}</span>
                    </div>
                )}
                <div style={styles.row}>
                    <span style={styles.label}>Booking ID</span>
                    <span style={styles.value}>{booking.paymentAuth}</span>
                </div>
                <div style={styles.row}>
                    <span style={styles.label}>Price</span>
                    <span style={styles.value}>₪{booking.amount}</span>
                </div>
            </div>

            <div style={styles.qrSection}>
                <span style={styles.label}>Scan for Entry</span>
                <div style={styles.qrCode}>
                    <QRCode
                        value={booking.qrCode}
                        size={180}
                        level="H"
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    />
                </div>
                <span style={styles.label}>Present this QR code at the event</span>
            </div>

            <div style={styles.footer}>
                <p>This ticket is non-transferable and must be shown at entry</p>
            </div>
        </div>
    );
};

export default Ticket;
