import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface PurposeScreenProps {
    onNext: () => void;
    onBack?: () => void; // Make it optional
    setPurpose: (value: string[]) => void;
    selectedPurpose: string[];
    skipButton?: React.ReactNode;
}


const PurposeScreen: React.FC<PurposeScreenProps> = ({ onNext, setPurpose, selectedPurpose, skipButton }) => {
    const purposeOptions = [
        { label: "Connect with interesting people", icon: "💡" },
        { label: "Share knowledge", icon: "📚" },
        { label: "Find a romantic partner", icon: "💛" },
        { label: "Meet co-founders", icon: "🤝" },
        { label: "Learn & grow", icon: "🎓" },
    ];

    const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedPurpose);

    const handleSelect = (option: string) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
        );
    };

    const handleNext = () => {
        setPurpose(selectedOptions);
        onNext();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px',
                width: '100%',
                maxWidth: '420px',
                mx: 'auto',
                bgcolor: '#F8F9FA', // Replacing yellow with soft grey/white
                boxSizing: 'border-box',
                textAlign: 'center',
                borderRadius: '20px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)', // Soft shadow effect
            }}
        >
            {/* Header */}
            <Typography variant="h4" sx={{
                fontWeight: 700,
                fontSize: '34px',
                color: '#222',
                fontFamily: '"Poppins", sans-serif',
                marginBottom: '20px',
            }}>
                What are you looking for?
            </Typography>

            {/* Badges Grid */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px', width: '100%', marginBottom: '30px' }}>
                {purposeOptions.map((option) => (
                    <Box
                        key={option.label}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '12px 20px',
                            borderRadius: '24px',
                            bgcolor: selectedOptions.includes(option.label) ? '#0056D2' : '#E9ECEF',
                            border: selectedOptions.includes(option.label) ? '2px solid #0056D2' : 'none',
                            cursor: 'pointer',
                            fontSize: '1.2rem',
                            fontWeight: selectedOptions.includes(option.label) ? 'bold' : '500',
                            color: selectedOptions.includes(option.label) ? '#FFFFFF' : '#555',
                            fontFamily: '"Poppins", sans-serif',
                            transition: 'all 0.3s ease',
                            userSelect: 'none',
                            '&:hover': {
                                backgroundColor: selectedOptions.includes(option.label) ? '#0045A0' : '#DEE2E6',
                            },
                        }}
                        onClick={() => handleSelect(option.label)}
                    >
                        {option.icon} {option.label}
                    </Box>
                ))}
            </Box>

            {/* Next Button */}
            <Button
                onClick={handleNext}
                sx={{
                    backgroundColor: '#FFFFFF', // White background
                    color: '#222', // Dark text
                    fontWeight: 700,
                    fontSize: '20px',
                    textTransform: 'none',
                    borderRadius: '24px',
                    padding: '18px 36px',
                    fontFamily: '"Poppins", sans-serif',
                    border: '2px solid #222', // Subtle border for definition
                    '&:hover': {
                        backgroundColor: '#F0F0F0',
                    },
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Next <ArrowForwardIcon sx={{ marginLeft: '10px', fontSize: '26px' }} />
            </Button>

         
        </Box>
    );
};

export default PurposeScreen;
