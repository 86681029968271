import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { getEventById } from '../../shared/services/eventServices';
import PeopleJoined from '../../shared/components/PeopleJoined';

interface Event {
    id: string;
    event_name: string;
    event_date: Date;
    event_image: string;
    location: string;
    locationName: string;
    event_time?: string;
    guests?: Array<{
        user_id: string;
        status: string;
        username: string;
        image: string;
    }>;
    isUpcoming?: boolean;
    daysRemaining?: number | null;
}

interface Guest {
    user_id: string;
    status: string;
    username: string;
    image: string;
    eventId: string;
}

interface EventListProps {
    events: Event[];
    loading: boolean;
    onLoadMore: () => void;
    hasMore: boolean;
    lastEventRef: React.RefObject<HTMLDivElement>;
}

const EventList: React.FC<EventListProps> = ({ events, loading, onLoadMore, hasMore, lastEventRef }) => {
    const [guestData, setGuestData] = useState<{ [key: string]: Guest[] }>({});
    const [loadingGuests, setLoadingGuests] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const loadGuestData = async () => {
            for (const event of events) {
                if (!guestData[event.id] && !loadingGuests[event.id]) {
                    setLoadingGuests(prev => ({ ...prev, [event.id]: true }));
                    try {
                        const eventData = await getEventById(event.id);
                        if (eventData?.guests) {
                            const guests = eventData.guests.map(guest => ({
                                ...guest,
                                speaker_image: guest.image,
                                eventId: event.id
                            }));
                            setGuestData(prev => ({
                                ...prev,
                                [event.id]: guests
                            }));
                        }
                    } catch (error) {
                        console.error('Error loading guests for event:', event.id, error);
                    } finally {
                        setLoadingGuests(prev => ({ ...prev, [event.id]: false }));
                    }
                }
            }
        };
        loadGuestData();
    }, [events, guestData, loadingGuests]);

    const isEventInFuture = (eventDate: Date) => {
        return eventDate > new Date();
    };

    const formatDate = (eventDate: Date) => {
        const day = eventDate.getDate().toString().padStart(2, '0');
        const month = (eventDate.getMonth() + 1).toString().padStart(2, '0');
        const year = eventDate.getFullYear().toString().slice(2);
        return `${day}.${month}.${year}`;
    };

    if (loading && events.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (events.length === 0) {
        return (
            <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography>No events found</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: { xs: 2, sm: 2 },
            width: '100%',
            maxWidth: '100%',
            margin: 0,
            backgroundColor: '#f8f9fa',
            px: { xs: '16px', sm: 0 }
        }}>
            {events.map((event, index) => (
                <Link 
                    to={`/events/${event.id}`} 
                    key={event.id}
                    style={{ textDecoration: 'none', width: '100%', display: 'block' }}
                >
                    <Box
                        ref={index === events.length - 1 ? lastEventRef : null}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            width: '100%',
                            transition: 'all 0.2s ease-in-out',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            '&:hover': {
                                backgroundColor: '#fafafa',
                                transform: { xs: 'none', sm: 'translateY(-2px)' },
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                            }
                        }}
                    >
                        {/* Event Image Container */}
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            height: '200px',
                            padding: { xs: '8px', sm: '12px' },
                            boxSizing: 'border-box'
                        }}>
                            <Box sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '12px',
                                overflow: 'hidden'
                            }}>
                                <img
                                    src={event.event_image}
                                    alt={event.event_name}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: { xs: 20, sm: 24 },
                                    right: { xs: 20, sm: 24 },
                                    backgroundColor: isEventInFuture(event.event_date) ? '#4CAF50' : '#64748B',
                                    color: 'white',
                                    padding: '6px 16px',
                                    borderRadius: '20px',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    letterSpacing: '0.02em',
                                    fontFamily: "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
                                }}
                            >
                                {isEventInFuture(event.event_date) ? 
                                    (event.daysRemaining === 0 ? 'Today' :
                                     event.daysRemaining === 1 ? 'Tomorrow' :
                                     event.daysRemaining ? `In ${event.daysRemaining} days` :
                                     'Upcoming') :
                                    'Event Ended'}
                            </Box>
                        </Box>

                        {/* Event Content */}
                        <Box sx={{ p: { xs: 2, sm: 2.5 } }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
                                    fontWeight: 800,
                                    fontSize: { xs: '20px', sm: '24px' },
                                    color: '#0F172A',
                                    mb: 2,
                                    textAlign: 'right',
                                    lineHeight: 1.2,
                                    letterSpacing: '-0.02em'
                                }}
                            >
                                {event.event_name}
                            </Typography>

                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                gap: 1,
                                mb: 2
                            }}>
                                {/* Date */}
                                <Typography
                                    sx={{
                                        fontFamily: "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
                                        color: '#475569',
                                        fontSize: '15px',
                                        direction: 'rtl',
                                        fontWeight: 700,
                                        letterSpacing: '-0.01em',
                                        textAlign: 'right'
                                    }}
                                >
                                    {formatDate(event.event_date)}
                                    {event.event_time && ` • ${event.event_time}`}
                                </Typography>

                                {/* Location */}
                                <Typography
                                    sx={{
                                        fontFamily: "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
                                        color: '#475569',
                                        fontSize: '15px',
                                        direction: 'rtl',
                                        fontWeight: 700,
                                        letterSpacing: '-0.01em',
                                        textAlign: 'right'
                                    }}
                                >
                                    {event.locationName}
                                </Typography>
                            </Box>

                            {/* People Joined Section with Loading State */}
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                {loadingGuests[event.id] ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <PeopleJoined guests={guestData[event.id] || []} eventId={event.id} />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Link>
            ))}

            {/* Loading indicator for infinite scroll */}
            {loading && events.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                </Box>
            )}
        </Box>
    );
};

export default EventList;
