import React, { useRef, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Fade,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditIcon from '@mui/icons-material/Edit';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Cropper from 'react-easy-crop';
import imageCompression from 'browser-image-compression';

interface ImageUploadScreenProps {
  onNext: () => void;
  onImageUpload: (image: string | null) => void;
  onBack?: () => void;
  uploadedImage: string | null;
  skipButton?: React.ReactNode;
}

const ImageUploadScreen: React.FC<ImageUploadScreenProps> = ({
  onNext,
  onImageUpload,
  uploadedImage,
  skipButton,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);

  const chooseImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result as string);
        setCropDialogOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((_: any, croppedArea: any) => {
    setCroppedAreaPixels(croppedArea);
  }, []);

  const getCroppedImage = async (): Promise<File | null> => {
    if (!imageSrc || !croppedAreaPixels) return null;

    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => (image.onload = resolve));

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise<File | null>((resolve) => {
      canvas.toBlob(async (blob) => {
        if (!blob) return resolve(null);

        const file = new File([blob], `cropped-${Date.now()}.jpg`, {
          type: 'image/jpeg',
        });

        try {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          });
          resolve(compressedFile);
        } catch (error) {
          console.error('Error during image compression:', error);
          resolve(file);
        }
      }, 'image/jpeg');
    });
  };

  const uploadCroppedImage = async () => {
    setLoading(true);
    setUploadProgress(0);
    try {
      const croppedFile = await getCroppedImage();
      if (!croppedFile) {
        alert('Error cropping image. Please try again.');
        setLoading(false);
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, `images/${Date.now()}-${croppedFile.name}`);
      
      // Simulate upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 90) {
            clearInterval(uploadInterval);
            return prev;
          }
          return prev + 10;
        });
      }, 200);

      const snapshot = await uploadBytes(storageRef, croppedFile);
      clearInterval(uploadInterval);
      setUploadProgress(100);
      
      const downloadURL = await getDownloadURL(snapshot.ref);
      onImageUpload(downloadURL);
      setCropDialogOpen(false);
      
      // Reset progress after a short delay
      setTimeout(() => {
        setUploadProgress(0);
        onNext();
      }, 500);
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Upload failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCropDialog = () => {
    setCropDialogOpen(false);
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        width: '100%',
        maxWidth: '420px',
        mx: 'auto',
        bgcolor: '#F8F9FA',
        boxSizing: 'border-box',
        textAlign: 'center',
        borderRadius: '20px',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
        animation: 'fadeIn 0.5s ease-in-out',
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0,
            transform: 'translateY(20px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          fontSize: '32px',
          color: '#222',
          fontFamily: '"Poppins", sans-serif',
          marginBottom: '16px',
        }}
      >
        Time to put a face to your name
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: '#666',
          marginBottom: '32px',
          fontSize: '16px',
          lineHeight: '1.6',
        }}
      >
        Upload a profile picture to help others recognize you
      </Typography>

      <Box
        sx={{
          position: 'relative',
          width: '180px',
          height: '180px',
          borderRadius: '50%',
          overflow: 'hidden',
          cursor: 'pointer',
          backgroundColor: '#fff',
          border: '2px dashed #E9ECEF',
          transition: 'all 0.3s ease',
          '&:hover': {
            borderColor: '#0056D2',
            boxShadow: '0 4px 12px rgba(0, 86, 210, 0.1)',
          },
        }}
        onClick={chooseImage}
      >
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              zIndex: 2,
            }}
          >
            <CircularProgress
              variant="determinate"
              value={uploadProgress}
              size={60}
              thickness={4}
              sx={{ color: '#0056D2' }}
            />
            <Typography
              sx={{
                mt: 2,
                color: '#0056D2',
                fontWeight: 500,
              }}
            >
              {uploadProgress}%
            </Typography>
          </Box>
        ) : uploadedImage ? (
          <>
            <img
              src={uploadedImage}
              alt="Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                opacity: 0,
                transition: 'opacity 0.3s ease',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <EditIcon sx={{ color: '#fff', fontSize: 40 }} />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#666',
            }}
          >
            <AddAPhotoIcon sx={{ fontSize: 40, mb: 1 }} />
            <Typography sx={{ fontSize: '14px' }}>Tap to Upload</Typography>
          </Box>
        )}

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Box>

      <Dialog
        open={cropDialogOpen}
        onClose={handleCloseCropDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent
          sx={{
            position: 'relative',
            width: '100%',
            height: '70vh',
            backgroundColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            padding: 0,
          }}
        >
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              style={{
                containerStyle: {
                  width: '100%',
                  height: '100%',
                },
                cropAreaStyle: {
                  borderRadius: '50%',
                  background: 'rgba(0, 0, 0, 0.5)',
                },
              }}
            />
          )}

          <Box
            sx={{
              position: 'absolute',
              bottom: 20,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
            }}
          >
            <Button
              onClick={handleCloseCropDialog}
              variant="contained"
              sx={{
                padding: '12px 16px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                color: '#000',
                borderRadius: '30px',
                width: '48%',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={uploadCroppedImage}
              variant="contained"
              disabled={loading}
              sx={{
                padding: '12px 16px',
                backgroundColor: '#0056D2',
                color: 'white',
                borderRadius: '30px',
                width: '48%',
                '&:hover': {
                  backgroundColor: '#0045A8',
                },
              }}
            >
              {loading ? 'Uploading...' : 'Save'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: 3, width: '100%' }}>
        <Button
          onClick={onNext}
          variant="contained"
          disabled={loading}
          sx={{
            width: '100%',
            py: 1.5,
            borderRadius: '12px',
            backgroundColor: '#0056D2',
            '&:hover': {
              backgroundColor: '#0045A8',
            },
            '&.Mui-disabled': {
              backgroundColor: '#E9ECEF',
              color: '#666',
            },
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ImageUploadScreen;
