import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRouter from './Router';
import Header from './Features/Profile/components/Header';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/poppins'; // Import the font

// Unregister any existing service workers to prevent caching issues
serviceWorkerRegistration.unregister();

const theme = createTheme({
  typography: {
    fontFamily: '"Arialsans-serif" !important', // Set default font family
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: 0,
          fontFamily: '"Arialsans-serif" !important', // Override the font family
          fontSize: '24px',
          lineHeight: 1.235,
          letterSpacing: '0.00735em',
          marginBottom: '20px',
        },
      },
    },
  },
});

// Hide the address bar on mobile devices
const hideAddressBar = () => {
  if (window.innerWidth < 1024) {
    // Timeout to ensure the scroll happens after rendering
    setTimeout(() => {
      window.scrollTo(0, 1);  // Scroll the page slightly to hide the address bar
    }, 0);
  }
};

const AppInitializer = () => {
  React.useEffect(() => {
    // Hide the address bar when the component mounts
    hideAddressBar();

    // Add any additional setup here, if needed
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '100%', backgroundColor: '#ffffff', marginBottom: '90px'}}>
        <AppRouter />
      </div>
    </ThemeProvider>
  );
};

// Create root without Strict Mode
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Render the app with Auth0Provider
root.render(
  <Auth0Provider
    domain="auth.salonim.live"
    clientId="YWo8ceK29t7dwvK8NVJ3XKAVT74Zn1oM"
    authorizationParams={{
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      prompt: 'login',
    }}
    useRefreshTokens={false}
    cacheLocation="memory"
  >
    <AuthProvider>
      <AppInitializer />
    </AuthProvider>
  </Auth0Provider>
);

reportWebVitals();
