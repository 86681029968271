import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, CircularProgress } from '@mui/material';
import { getBalancedEventUsers } from '../services/userService';

interface PeopleJoinedProps {
    guests: any[];
    eventId: string;
}

const DISPLAY_AVATARS = 7; // Show 7 avatars
const FADE_AVATARS = 5; // Number of faded avatars to show

const PeopleJoined: React.FC<PeopleJoinedProps> = ({ guests, eventId }) => {
    const [randomUsers, setRandomUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                setError(null);
                console.log('Fetching users for event:', eventId);
                const users = await getBalancedEventUsers(eventId);
                console.log('Fetched users:', users);
                console.log('Total users length:', users.length);
                console.log('Faded users:', users.slice(DISPLAY_AVATARS, DISPLAY_AVATARS + FADE_AVATARS).length);
                setRandomUsers(users || []);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }
        };

        if (eventId) {
            fetchUsers();
        }
    }, [eventId]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!randomUsers.length) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                    No people joined yet
                </Typography>
            </Box>
        );
    }

    const fadedUsers = randomUsers.slice(DISPLAY_AVATARS, DISPLAY_AVATARS + FADE_AVATARS);
    console.log('Rendering faded users:', fadedUsers.length);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            {/* Main visible avatars */}
            {randomUsers.slice(0, DISPLAY_AVATARS).map((user, index) => (
                <Avatar
                    key={user.id || index}
                    src={user.image}
                    alt={user.username || user.name || 'User'}
                    sx={{
                        width: 32,
                        height: 32,
                        border: '2px solid white',
                        marginLeft: index > 0 ? -1 : 0,
                        zIndex: DISPLAY_AVATARS - index,
                        bgcolor: 'grey.300',
                    }}
                />
            ))}
            
            {/* Faded avatars for the crowd effect */}
            {fadedUsers.length > 0 && (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        marginLeft: -1,
                        position: 'relative',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            right: -40,
                            top: 0,
                            bottom: 0,
                            width: 80,
                            background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))',
                            zIndex: DISPLAY_AVATARS + 1,
                        }
                    }}
                >
                    {fadedUsers.map((user, index) => (
                        <Avatar
                            key={user.id || index}
                            src={user.image}
                            alt={user.username || user.name || 'User'}
                            sx={{
                                width: 32,
                                height: 32,
                                border: '2px solid white',
                                marginLeft: -1,
                                opacity: 1 - (index * 0.15),
                                filter: `blur(${index * 1}px)`,
                                zIndex: DISPLAY_AVATARS - FADE_AVATARS - index,
                                bgcolor: 'grey.300',
                            }}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default PeopleJoined;