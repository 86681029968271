import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { auth } from "../shared/services/firebaseConfig";
import { fetchUserByDocumentId, fetchUserByPhoneNumber, updateUserDocument } from "../shared/services/userService";
import { User } from "firebase/auth";
import { useAuth0 } from '@auth0/auth0-react';

// Add phone number validation function
const isValidPhoneNumber = (str: string): boolean => {
    // Remove any non-digit characters
    const cleaned = str.replace(/\D/g, '');
    // Check if it's a valid length (assuming international format)
    return cleaned.length >= 10 && cleaned.length <= 15;
};

interface AuthContextProps {
    user: User | any | null;
    loading: boolean;
    logout: () => Promise<void>;
    updateUser: (updatedUser: any) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
    user: null,
    loading: true,
    logout: async () => { },
    updateUser: async () => { },
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<User | any | null>(null);
    const [loading, setLoading] = useState(true);
    const hasRedirected = useRef(false); // ✅ Prevent multiple redirects

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
            console.log("🔄 Firebase auth state changed:", firebaseUser);

            if (!firebaseUser) {
                setUser(null);
                console.log("🚫 No user logged in.");
                setLoading(false);
                return;
            }

            try {
                // First try to get user by ID
                let salonimUser = await fetchUserByDocumentId(firebaseUser.uid);

                // If not found, check by phone number
                if (!salonimUser) {
                    const userPhoneNumber = firebaseUser.phoneNumber || 
                        (firebaseUser.displayName && isValidPhoneNumber(firebaseUser.displayName) ? firebaseUser.displayName : null);
                    
                    if (userPhoneNumber) {
                        console.log("📞 Checking by phone number:", userPhoneNumber);
                        salonimUser = await fetchUserByPhoneNumber(userPhoneNumber);
                        
                        if (salonimUser) {
                            // Update the current user's document with the existing user's data
                            await updateUserDocument(firebaseUser.uid, {
                                ...salonimUser,
                                phoneNumber: userPhoneNumber // Ensure phone number is updated
                            });
                        }
                    }
                }

                // Set user state with combined data
                setUser({
                    ...firebaseUser,
                    ...salonimUser,
                });

                if (salonimUser) {
                    console.log("✅ User found and updated:", salonimUser);
                    localStorage.removeItem("OnboardingProcess");
                    // Only redirect to events if we're on the root path
                    if (window.location.pathname === '/') {
                        window.location.href = '/events';
                    }
                } else {
                    console.log("❌ No Salonim user found.");

                    const currentPath = window.location.pathname;
                    const onboardingProcess = localStorage.getItem("OnboardingProcess");

                    if (!hasRedirected.current && currentPath !== "/onboarding" && onboardingProcess !== "true") {
                        hasRedirected.current = true;
                        localStorage.setItem("OnboardingProcess", "true");
                        window.location.href = "/onboarding";
                    }
                }
            } catch (error) {
                console.error("❌ Error fetching user:", error);
            }

            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const logout = async () => {
        try {
            console.log('🔄 Starting logout process...');
            // Sign out from Firebase
            await auth.signOut();
            setUser(null);
            
            // Clear all auth-related storage
            localStorage.clear();  // Clear all localStorage
            sessionStorage.clear();  // Clear all sessionStorage
            
            // Clear any auth-specific items if they exist
            localStorage.removeItem("OnboardingProcess");
            localStorage.removeItem("auth0.is.authenticated");
            localStorage.removeItem("auth0.application.clientId");
            localStorage.removeItem("auth0spa");
            
            // Construct Auth0 logout URL with returnTo parameter
            const domain = "auth.salonim.live";
            const clientId = "YWo8ceK29t7dwvK8NVJ3XKAVT74Zn1oM";
            const returnTo = encodeURIComponent(window.location.origin);
            const logoutUrl = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}&federated`;
            
            console.log('✅ Redirecting to Auth0 logout URL:', logoutUrl);
            window.location.href = logoutUrl;
        } catch (error) {
            console.error('❌ Error during logout:', error);
            // Still redirect to home even if there's an error
            window.location.href = "/";
        }
    };

    const updateUser = async (updatedUser: any) => {
        setUser((prevUser: any | null) => ({ ...prevUser, ...updatedUser }));
    };

    return (
        <AuthContext.Provider value={{ user, loading, logout, updateUser }}>
            {children}
        </AuthContext.Provider>
    );
};
