import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  InputAdornment,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WorkIcon from '@mui/icons-material/Work';
import EditIcon from '@mui/icons-material/Edit';
import MessageModal from '../../../../shared/components/MessageModal';

interface InfoScreenProps {
  onNext: () => void;
  onBack: () => void;
  onUpdate: (field: string, value: string) => void;
  bio: string;
  occupation: string;
  socialMediaUrl: string;
}

const InfoScreen: React.FC<InfoScreenProps> = ({
  onNext,
  onBack,
  onUpdate,
  bio,
  occupation,
  socialMediaUrl,
}) => {
  const [bioError, setBioError] = useState('');
  const [afterRegisterModal, setAfterRegisterModal] = useState(false);

  const handleBioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onUpdate('bio', value);
    setBioError(value.trim() === '' ? 'Bio cannot be empty' : '');
  };

  const handleOccupationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate('occupation', event.target.value);
  };

  const handleSocialMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate('socialMediaUrl', event.target.value);
  };

  const handleNext = () => {
    if (bio.trim() === '') {
      setBioError('Bio cannot be empty');
      return;
    }
    onNext();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        width: '100%',
        maxWidth: '420px',
        mx: 'auto',
        bgcolor: '#F8F9FA',
        boxSizing: 'border-box',
        textAlign: 'center',
        borderRadius: '20px',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
        animation: 'fadeIn 0.5s ease-in-out',
        '@keyframes fadeIn': {
          '0%': {
            opacity: 0,
            transform: 'translateY(20px)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          fontSize: '32px',
          color: '#222',
          fontFamily: '"Poppins", sans-serif',
          marginBottom: '16px',
        }}
      >
        Tell us about yourself
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: '#666',
          marginBottom: '32px',
          fontSize: '16px',
          lineHeight: '1.6',
        }}
      >
        Share your story and connect with others
      </Typography>

      <Paper
        elevation={0}
        sx={{
          width: '100%',
          p: 3,
          mb: 3,
          borderRadius: '16px',
          backgroundColor: '#fff',
          border: '1px solid #E9ECEF',
          transition: 'all 0.3s ease',
          '&:hover': {
            borderColor: '#0056D2',
            boxShadow: '0 4px 12px rgba(0, 86, 210, 0.1)',
          },
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: '#222',
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <WorkIcon sx={{ color: '#0056D2' }} />
            What do you do?
          </Typography>
          <TextField
            fullWidth
            value={occupation}
            onChange={handleOccupationChange}
            placeholder="e.g., Software Engineer, Designer, Entrepreneur"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: '#F8F9FA',
                '&:hover': {
                  backgroundColor: '#F1F3F5',
                },
              },
            }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: '#222',
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <InstagramIcon sx={{ color: '#E4405F' }} />
            Instagram Handle
          </Typography>
          <TextField
            fullWidth
            value={socialMediaUrl}
            onChange={handleSocialMediaChange}
            placeholder="@username"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InstagramIcon sx={{ color: '#E4405F' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: '#F8F9FA',
                '&:hover': {
                  backgroundColor: '#F1F3F5',
                },
              },
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: '#222',
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <EditIcon sx={{ color: '#0056D2' }} />
            Your Bio
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={bio}
            onChange={handleBioChange}
            placeholder="Tell us about yourself..."
            error={!!bioError}
            helperText={bioError}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: '#F8F9FA',
                '&:hover': {
                  backgroundColor: '#F1F3F5',
                },
              },
            }}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          mt: 2,
        }}
      >
        <Button
          onClick={handleNext}
          variant="contained"
          disabled={!!bioError}
          sx={{
            width: '100%',
            py: 1.5,
            borderRadius: '12px',
            backgroundColor: '#0056D2',
            '&:hover': {
              backgroundColor: '#0045A8',
            },
            '&.Mui-disabled': {
              backgroundColor: '#E9ECEF',
              color: '#666',
            },
          }}
        >
          Next
        </Button>
      </Box>

      <MessageModal
        open={afterRegisterModal}
        onClose={() => setAfterRegisterModal(false)}
        header="Oops! 😅"
        message="Please fill in all required fields"
        onActionClick={() => setAfterRegisterModal(false)}
      />
    </Box>
  );
};

export default InfoScreen;
