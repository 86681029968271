import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme specifically for the Gender page
const genderTheme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});


interface GenderScreenProps {
  onNext: () => void;        // Function to handle the next button click
  onBack: () => void;        // Function to handle the back button click
  setGender: (gender: string) => void; // Function to set the selected gender
  gender: string;            // Current selected gender
  name: string;              // User's name
  skipButton?: React.ReactNode; // Optional skip button (could be a <Button /> or any React node)
}

const GenderScreen: React.FC<GenderScreenProps> = ({
  onNext,
  onBack,
  setGender,
  gender,
  name,
  skipButton,
}) => {
  const [selectedGender, setSelectedGender] = useState(gender);

  // Function to handle gender selection
  const handleGenderSelect = (value: string) => {
    setSelectedGender(value);
    setGender(value);
    onNext(); // Move to the next step immediately
  };
  

  return (
    <ThemeProvider theme={genderTheme}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '20px',
        width: '100%',
        maxWidth: '393px',
        margin: '0 auto',
        backgroundColor: '#FFFFFF',
        minHeight: '100%',
        borderRadius: '20px',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
      }}
    >
      {/* Progress Indicator */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Box
          sx={{
            width: '56px',
            height: '4px',
            backgroundColor: '#000000',
            borderRadius: '9999px',
          }}
        />
      </Box>

      {/* Title and Subtitle */}
      <Box sx={{ padding: '0 0 20px' }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '38px', marginBottom: '12px' }}
        >
          {name} is a great name {/* Display user's name */}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#313131' }}>
          We're glad you're here. Please select the gender.
        </Typography>
      </Box>

      <Box sx={{ width: '100%', maxWidth: '360px', display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '40px' }}>
  
  {/* Woman Option */}
  <Box
    onClick={() => handleGenderSelect('Women')}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      width: '100%',
      maxWidth: '320px',
      backgroundColor: selectedGender === 'Women' ? '#007AFF' : '#F2F2F2',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': { backgroundColor: selectedGender === 'Women' ? '#005BBB' : '#E0E0E0' },
    }}
  >
    <Typography
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
        fontSize: '22px',
        color: selectedGender === 'Women' ? '#ffffff' : '#000000',
        textAlign: 'center',
      }}
    >
      Woman
    </Typography>
  </Box>

  {/* Man Option */}
  <Box
    onClick={() => handleGenderSelect('Men')}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px',
      width: '100%',
      maxWidth: '320px',
      backgroundColor: selectedGender === 'Men' ? '#007AFF' : '#F2F2F2',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': { backgroundColor: selectedGender === 'Men' ? '#005BBB' : '#E0E0E0' },
    }}
  >
    <Typography
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '700',
        fontSize: '22px',
        color: selectedGender === 'Men' ? '#ffffff' : '#000000',
        textAlign: 'center',
      }}
    >
      Man
    </Typography>
  </Box>
</Box>


      {/* Navigation Buttons */}
 
    </Box>
    </ThemeProvider>
  );
};

export default GenderScreen;