import React, { ReactNode, useState } from 'react';
import { Box, Typography, TextField, IconButton, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface IntroScreenProps {
    onNext: () => void;
    setFirstName: (firstName: string) => void;
    setBirthday: (birthday: { day: string; month: string; year: string }) => void;
    firstName: string;
    birthday: { day: string; month: string; year: string };
    skipButton?: ReactNode; // Skip button callback
}

const IntroScreen: React.FC<IntroScreenProps> = ({
    onNext,
    setFirstName,
    setBirthday,
    firstName,
    birthday,
    skipButton
}) => {
    const [errors, setErrors] = useState<{ firstName?: string; birthday?: string }>({});

    const validate = () => {
        const newErrors: { firstName?: string; birthday?: string } = {};
    
        // Validate first name
        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required.';
        }
    
        // Validate birthday fields
        if (!birthday.day.trim() || !birthday.month.trim() || !birthday.year.trim()) {
            newErrors.birthday = 'Birthday is required.';
        } else {
            // Convert inputs to numbers
            const day = parseInt(birthday.day, 10);
            const month = parseInt(birthday.month, 10);
            const year = parseInt(birthday.year, 10);
            const currentYear = new Date().getFullYear();
    
            // Check if values are valid numbers
            if (isNaN(day) || isNaN(month) || isNaN(year)) {
                newErrors.birthday = 'Please enter valid numbers.';
            } else {
                // Check ranges
                if (month < 1 || month > 12) {
                    newErrors.birthday = 'Month must be between 1 and 12.';
                } else if (day < 1 || day > 31) {
                    newErrors.birthday = 'Day must be between 1 and 31.';
                } else if (year < 1900 || year > currentYear) {
                    newErrors.birthday = `Year must be between 1900 and ${currentYear}.`;
                } else {
                    // Check specific month lengths
                    const daysInMonth = new Date(year, month, 0).getDate();
                    if (day > daysInMonth) {
                        newErrors.birthday = `Invalid day for ${month}/${year}. This month has ${daysInMonth} days.`;
                    }
    
                    // Check if date is in the future
                    const birthDate = new Date(year, month - 1, day);
                    if (birthDate > new Date()) {
                        newErrors.birthday = 'Birthday cannot be in the future.';
                    }
    
                    // Check minimum age (13 years)
                    const minAgeDate = new Date();
                    minAgeDate.setFullYear(minAgeDate.getFullYear() - 13);
                    if (birthDate > minAgeDate) {
                        newErrors.birthday = 'You must be at least 13 years old.';
                    }
                }
            }
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    // Add input validation for birthday fields
    const handleBirthdayChange = (field: 'day' | 'month' | 'year', value: string) => {
        // Only allow numbers
        if (value !== '' && !/^\d+$/.test(value)) {
            return;
        }
    
        // Add max length restrictions
        if ((field === 'day' && value.length <= 2) ||
            (field === 'month' && value.length <= 2) ||
            (field === 'year' && value.length <= 4)) {
            setBirthday({ ...birthday, [field]: value });
        }
    };

    const handleSubmit = () => {
        if (validate()) {
            onNext();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '24px',
                width: '100%',
                maxWidth: '393px',
                margin: '0 auto',
                backgroundColor: '#F8F9FA',
                borderRadius: '20px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
                boxSizing: 'border-box',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '38px', marginBottom: '40px' }}>
                Oh hey! Let's start with an intro.
            </Typography>

            {/* Full Name Field */}
            <Typography sx={{ marginBottom: '8px' }}>Your full name</Typography>
            <TextField
                placeholder="Full Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!!errors.firstName}
                helperText={errors.firstName}
                fullWidth
                sx={{ marginBottom: '36px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            />

            {/* Birthday Fields */}
            <Typography sx={{ marginBottom: '8px' }}>Your birthday</Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '8px', width: '100%' }}>
                <TextField
                    placeholder="Day"
                    value={birthday.day}
                    onChange={(e) => handleBirthdayChange('day', e.target.value)}
                    error={!!errors.birthday}
                    inputProps={{ maxLength: 2 }}
                    sx={{ flex: 1 }}
                />
                <TextField
                    placeholder="Month"
                    value={birthday.month}
                    onChange={(e) => handleBirthdayChange('month', e.target.value)}
                    error={!!errors.birthday}
                    inputProps={{ maxLength: 2 }}
                    sx={{ flex: 1 }}
                />
                <TextField
                    placeholder="Year"
                    value={birthday.year}
                    onChange={(e) => handleBirthdayChange('year', e.target.value)}
                    error={!!errors.birthday}
                    inputProps={{ maxLength: 4 }}
                    sx={{ flex: 1 }}
                />
            </Box>
            {errors.birthday && (
                <Typography color="error" sx={{ fontSize: '12px', mt: 1 }}>
                    {errors.birthday}
                </Typography>
            )}

            <Typography sx={{ fontSize: '12px', color: '#888', mt: 1 }}>
                It's never too early to count down
            </Typography>

            {/* Next Button */}
            <Box sx={{ width: '100%', mt: 4 }}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                        py: 2,
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#1565c0'
                        }
                    }}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default IntroScreen;