import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams,useSearchParams  } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    IconButton,
    TextField,
    Container,
    Paper,
    Chip,
    Divider,
    CircularProgress,
    Modal,
    Fade,
    Tooltip,
} from '@mui/material';
import { LocationOn, CalendarToday, AccessTime, Group, Info, Search, HourglassEmpty } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { addEventToUser, getLiverDataById, getUserEvents, updateUserDocument } from '../../shared/services/userService';
import CloseIcon from '@mui/icons-material/Close';
import { functions } from '../../shared/services/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { format } from 'date-fns';
import WhatsAppButton from '../../shared/components/WhatsappBtn';
import { getUserTicket } from '../../shared/services/transactionService';
import Ticket from './components/Ticket';
import { useNavigate } from 'react-router-dom'; // Use navigate for routing
import MessageModal from '../../shared/components/MessageModal';
import ImageSlider from '../../shared/components/ImageSlider';
import { isBefore } from 'date-fns'; // Import a helper from date-fns to compare dates
import PeopleJoined from '../../shared/components/PeopleJoined';
import { addUserToEvent, getEventById, removeUserFromEvent } from '../../shared/services/eventServices';
import { Console } from 'console';
import QRCode from 'react-qr-code'; // QR Code library
import { Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../shared/services/firebaseConfig';
import { getStandardizedUser, getUserId, standardizeUserId } from '../../shared/utils/userHelpers';

// Add interface for Event type
interface EventData {
    id: string;
    guests?: Array<{
        user_id: string;
        status: string;
        username: string;
        image: string;
    }>;
    [key: string]: any;
}

const EventDetail = () => {
    const { event_id } = useParams(); // Get event ID and isRegister from the route
    const [searchParams] = useSearchParams(); // Extract query parameters
    const paymentRoute = searchParams.get('p'); // p=5 means show pay now button
    console.log('Payment Route:', paymentRoute); // Debug log
    
    const location = useLocation(); // React Router hook for location
    const { isRegister = false } = location.state || {};
    const [event, setEvent] = useState<any>(null);
    const [liver, setLiverData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [afterRegisterModal, setAfterRegisterModal] = useState<boolean>(false);
    const { user } = useAuth();
    const [openCancelPolicyModal, setOpenCancelPolicyModal] = useState(false);
    const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
    const [openPaymentModal, setPaymentModal] = useState<boolean>(false);
    const [approvedPaymentModal, setApprovePaymentModal] = useState<boolean>(false);
    const [state, setState] = useState<string>('loading');
    const [cardNumber, setCardNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState<any>(null);
    const [userPaid, setUserPaid] = useState<any>(null);
    const navigate = useNavigate(); // React Router hook for navigation
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [showOverlay, setShowOverlay] = useState(false); // Overlay state
    const [paymentMessage, setPaymentMessage] = useState("Processing your payment... ⏳");
    const [messageModal, setMessageModal] = useState({
        open: false,
        header: "",
        message: "",
    });
    const [pendingStatusModal, setPendingStatusModal] = useState(false);
    const [isCheckingStatus, setIsCheckingStatus] = useState(true);
    const [buttonState, setButtonState] = useState({
        text: 'Checking Status...',
        loading: true
    });
    const [openPhoneVerificationModal, setOpenPhoneVerificationModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationInput, setShowVerificationInput] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(10);
    const [isVerifying, setIsVerifying] = useState(false);
    const [verificationError, setVerificationError] = useState('');
    const [generatedCode, setGeneratedCode] = useState<string>('');

    const normalizePhoneNumber = (phone: string): string => {
        if (!phone) return '';
        
        // Remove any spaces or special characters
        const cleanPhone = phone.replace(/[\s\-\(\)]/g, '');
        
        // If it starts with 0, convert to +972 format
        if (cleanPhone.startsWith('0')) {
            return '+972' + cleanPhone.substring(1);
        }
        
        // If it's already in +972 format, return as is
        if (cleanPhone.startsWith('+972')) {
            return cleanPhone;
        }
        
        // If it's just numbers, assume it's a local number and add +972
        if (/^\d+$/.test(cleanPhone)) {
            return '+972' + cleanPhone.replace(/^0+/, '');
        }
        
        return cleanPhone;
    };
    
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [ticketLoading, setTicketLoading] = useState(false);

   const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
   

// Generate the barcode content dynamically
const barcodeData = `${event_id}:${user?.uid || ''}`;

    const calcPrice = () => {
        if (!event) return 0;
    
        const userPhoneNumber = normalizePhoneNumber(user?.phoneNumber || '');
    
        if (event.PriceByUser && Array.isArray(event.PriceByUser)) {
            const userPriceEntry = event.PriceByUser.find(
                (entry: { UserId: string; Price: number }) => 
                    normalizePhoneNumber(entry.UserId) === userPhoneNumber
            );
    
            if (userPriceEntry) {
                return userPriceEntry.Price; // Return custom price if found
            }
        }
    
        return event?.OriginalPrice || 0; // Default to original price
    };
    
    

    const calcPriceText = () => {
        return event?.PriceText;
    };

    const handleEmailSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
    
        try {
          
            // Update the user's document with the email
            await updateUserDocument(user.uid, { email });
            user.email = email; // Update the local user object with the email
            console.log('handleEmailSubmit email'+user.user.email);
            setOpenEmailModal(false); // Close the email modal
            handleOpenModal('register'); // Add 'register' argument
        } catch (error) {
            console.error('Error updating email:', error);
            setOpenEmailModal(false); // Close modal even if there is an error
        }
    };
    
    // Add loading state
    const [isRequestLoading, setIsRequestLoading] = useState(false);

    const updateButtonText = () => {
        console.log('Updating button text. Current state:', state);
        console.log('Payment Route:', paymentRoute);
        
        if (paymentRoute === '5') {
            setButtonState(prev => ({ ...prev, text: 'Pay Now', loading: false }));
            return;
        }
        
        if (state === 'paid' || state === 'paid_in') {
            setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
            return;
        }
        
        if (event?.status === 'sold') {
            setButtonState(prev => ({ ...prev, text: 'Sold Out', loading: false }));
            return;
        }

        switch (state) {
            case 'pending':
                setButtonState(prev => ({ ...prev, text: 'Pending Approval', loading: false }));
                break;
            case 'approved':
                setButtonState(prev => ({ ...prev, text: 'Complete Payment', loading: false }));
                break;
            case 'register':
            case 'canceled':
            default:
                setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
        }
    };

    // Combine all status checks into one function
    const checkAllStatuses = async () => {
        if (!event_id || !user) {
            setIsCheckingStatus(false);
            setState('register');
            return;
        }
        
        try {
            setIsCheckingStatus(true);
            setState('loading');
            
            const standardUser = getStandardizedUser(user);
            console.log('Checking all statuses for user:', standardUser.uid);

            // First check user's event status
            const userEvents = await getUserEvents(standardUser.uid);
            const eventExists = userEvents.filter((event: any) => event.event_id === event_id);

            if (eventExists.length > 0) {
                console.log('Found event in user events:', eventExists[0]);
                const eventStatus = eventExists[0].status;
                
                // If event is paid, set paid state regardless of ticket
                if (eventStatus === 'paid' || eventStatus === 'paid_in') {
                    console.log('User has paid event - setting paid state');
                    setUserPaid(eventExists[0]);
                    setState('paid');
                    setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
                    setIsCheckingStatus(false);
                    return;
                }
                
                setState(eventStatus);
                
                if (eventStatus === 'pending') {
                    setButtonState(prev => ({ ...prev, text: 'Pending Approval', loading: false }));
                } else if (eventStatus === 'approved') {
                    setButtonState(prev => ({ ...prev, text: 'Complete Payment', loading: false }));
                } else if (eventStatus === 'canceled') {
                    setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                } else {
                    setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                }
            }

            // Then check ticket status
            const userPaid: any = await getUserTicket(event_id, standardUser.uid);
            console.log('Payment status check:', userPaid);

            if (userPaid && userPaid.status === "active") {
                console.log('User has active ticket - setting paid state');
                setUserPaid(userPaid);
                setState('paid');
                setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
                setIsCheckingStatus(false);
                return;
            }

            // Finally check event guest status
            const eventData = await getEventById(event_id) as EventData;
            if (eventData) {
                const guestStatus = eventData.guests?.find((guest) => guest.user_id === standardUser.uid)?.status;
                console.log('Guest status from event:', guestStatus);

                if (guestStatus === 'approved') {
                    console.log('User is approved in event - setting approved state');
                    setState('approved');
                    setButtonState(prev => ({ ...prev, text: 'Complete Payment', loading: false }));
                    setIsCheckingStatus(false);
                    return;
                }
            }

        } catch (error) {
            console.error('Error checking statuses:', error);
            setState('register');
            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
        } finally {
            setIsCheckingStatus(false);
        }
    };

    // Update useEffect to use combined check
    useEffect(() => {
        if (event_id && user) {
            checkAllStatuses();
        } else {
            setIsCheckingStatus(false);
            setState('register');
        }
    }, [event_id, user]);

    // Add phone number validation function
    const isValidPhoneNumber = (phone: string): boolean => {
        // Remove any spaces or special characters
        const cleanPhone = phone.replace(/[\s\-\(\)]/g, '');
        
        // Check if it's a valid Israeli phone number format
        // Format 1: +972 followed by 9 digits
        // Format 2: 0 followed by 9 digits
        const israeliFormat = /^(\+972|0)[1-9]\d{8}$/;
        
        // Check if it's not just text (contains at least one number)
        const hasNumbers = /\d/.test(cleanPhone);
        
        return israeliFormat.test(cleanPhone) && hasNumbers;
    };

    const handlePhoneSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('📱 handlePhoneSubmit called with phone:', phoneNumber);

        if (!phoneNumber) {
            console.log('❌ No phone number provided');
            setVerificationError('Please enter a phone number');
            return;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            console.log('❌ Invalid phone number format:', phoneNumber);
            setVerificationError('Please enter a valid Israeli phone number (e.g., +972547678267 or 0547678267)');
            return;
        }

        try {
            console.log('🔄 Starting phone verification process');
            setIsVerifying(true);
            setVerificationError('');

            // Generate a random 6-digit code
            const code = Math.floor(100000 + Math.random() * 900000).toString();
            console.log('🔑 Generated verification code:', code);
            setGeneratedCode(code);

            // Format phone number to international format
            const formattedPhone = normalizePhoneNumber(phoneNumber);
            console.log('📞 Formatted phone number:', formattedPhone);

            // Send verification code
            console.log('📤 Sending verification code...');
            const response = await fetch('https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: formattedPhone,
                    text: `Your verification code is: ${code}`
                })
            });

            if (!response.ok) {
                console.error('❌ Failed to send verification code. Response:', response);
                throw new Error('Failed to send verification code');
            }

            console.log('✅ Verification code sent successfully');
            setShowVerificationInput(true);
            setIsResendDisabled(true);
            startResendCountdown();
            toast.success('Verification code sent!');
        } catch (error) {
            console.error('❌ Error in phone verification process:', error);
            setVerificationError('Failed to send verification code. Please try again.');
        } finally {
            setIsVerifying(false);
        }
    };

    const startResendCountdown = () => {
        setResendCountdown(10);
        const timer = setInterval(() => {
            setResendCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setIsResendDisabled(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handleVerificationSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log('🔍 handleVerificationSubmit called with code:', verificationCode);

        if (!verificationCode) {
            console.log('❌ No verification code provided');
            return;
        }

        try {
            setIsVerifying(true);
            setVerificationError('');

            console.log('🔄 Comparing codes:', {
                entered: verificationCode,
                generated: generatedCode
            });

            // Compare the entered code with the generated code
            if (verificationCode !== generatedCode) {
                console.log('❌ Code mismatch');
                throw new Error('Invalid verification code');
            }

            console.log('✅ Code verified successfully');

            // Format phone number to international format
            const formattedPhone = normalizePhoneNumber(phoneNumber);
            console.log('📞 Using formatted phone:', formattedPhone);

            // Update user's phone number in your auth context and database
            if (user) {
                const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
                const standardizedUserId = standardizeUserId(userId);
                console.log('👤 Updating user:', standardizedUserId);
                
                // Get the user's document first
                const userDocRef = doc(db, 'users', standardizedUserId);
                const userDocSnap = await getDoc(userDocRef);
                
                if (userDocSnap.exists()) {
                    console.log('📝 Updating user document with phone number');
                    await updateDoc(userDocRef, { 
                        phoneNumber: formattedPhone,
                        displayName: user.displayName || formattedPhone
                    });

                    // Update the local user object
                    if (user.user) {
                        user.user.phoneNumber = formattedPhone;
                    } else {
                        user.phoneNumber = formattedPhone;
                    }

                    console.log('✅ User document updated successfully');
                    setOpenPhoneVerificationModal(false);
                    
                    // If we're in payment route, navigate to payment
                    if (paymentRoute === '5') {
                        console.log('💳 Navigating to payment route');
                        navigate('/payment/' + event.id, { 
                            state: { 
                                data: {
                                    eventPrice: calcPrice(),
                                    event_id: event.id,
                                    name: event.event_name,
                                    date: event.event_date
                                } 
                            } 
                        });
                    } else {
                        // Otherwise proceed with normal registration flow
                        console.log('📋 Proceeding with event registration');
                        try {
                            await addEventToUser(standardizedUserId, event_id || '');
                            console.log('✅ Event added to user');
                            
                            await addUserToEvent(event_id || '', user);
                            console.log('✅ User added to event');
                            
                            setState('pending');
                            setButtonState(prev => ({ ...prev, text: 'Pending Approval', loading: false }));
                            setAfterRegisterModal(true);
                            console.log('✨ Registration process completed successfully');
                        } catch (error) {
                            console.error('❌ Error during registration:', error);
                            try {
                                await removeUserFromEvent(event_id || '', standardizedUserId);
                                console.log('🔄 Cleaned up failed registration');
                            } catch (cleanupError) {
                                console.error('❌ Error during cleanup:', cleanupError);
                            }
                            setMessageModal({
                                open: true,
                                header: "Registration Error",
                                message: "There was an error processing your registration. Please try again.",
                            });
                            setButtonState(prev => ({ ...prev, loading: false }));
                        }
                    }
                }
            }
        } catch (error) {
            console.error('❌ Error in verification process:', error);
            setVerificationError('Invalid verification code. Please try again.');
            setButtonState(prev => ({ ...prev, loading: false }));
        } finally {
            setIsVerifying(false);
        }
    };

    const handleClosePhoneModal = () => {
        setOpenPhoneVerificationModal(false);
        setShowVerificationInput(false);
        setPhoneNumber('');
        setVerificationCode('');
        setVerificationError('');
        setGeneratedCode('');
        setButtonState(prev => ({ ...prev, loading: false }));
    };

    const isValidPhoneNumberString = (phone: string | null | undefined): boolean => {
        if (!phone) return false;
        
        // Remove any spaces or special characters
        const cleanPhone = phone.replace(/[\s\-\(\)]/g, '');
        
        // Check if it's a valid Israeli phone number format
        // Format 1: +972 followed by 9 digits
        // Format 2: 0 followed by 9 digits
        const israeliFormat = /^(\+972|0)[1-9]\d{8}$/;
        
        return israeliFormat.test(cleanPhone);
    };

    const handleOpenModal = async (clickEvent: React.MouseEvent | string) => {
        const state = typeof clickEvent === 'string' ? clickEvent : 'register';
        console.log('🚀 handleOpenModal called with state:', state);
        
        if (!user) {
            console.log('❌ No user found, opening login modal');
            setOpenModal(true);
            return;
        }

        const standardUser = getStandardizedUser(user);
        console.log('👤 User info:', {
            uid: standardUser.uid,
            phoneNumber: standardUser.phoneNumber,
            displayName: standardUser.displayName
        });

        // Check if user has a valid phone number
        if (!standardUser.phoneNumber || !isValidPhoneNumberString(standardUser.phoneNumber)) {
            console.log('📱 No valid phone number found, opening phone verification modal');
            setOpenPhoneVerificationModal(true);
            return;
        }

        // If user is approved, navigate to payment
        if (state === 'approved' && event_id && event) {
            console.log('💳 User is approved, navigating to payment page');
            navigate('/payment/' + event_id, { 
                state: { 
                    data: {
                        eventPrice: calcPrice(),
                        event_id: event_id,
                        name: event.event_name,
                        date: event.event_date
                    } 
                } 
            });
            return;
        }

        if (state === 'register') {
            try {
                console.log('🎯 Starting registration process for event:', event_id);
                setButtonState(prev => ({ ...prev, text: 'Processing...', loading: true }));
                setIsRequestLoading(true);

                if (event_id) {
                    console.log('➡️ Adding user to event...');
                    await addUserToEvent(event_id, user);
                    console.log('✅ User added to event successfully');
                    
                    console.log('➡️ Adding event to user...');
                    await addEventToUser(standardUser.uid, event_id);
                    console.log('✅ Event added to user successfully');
                    
                    setState('pending');
                    setButtonState({ text: 'Pending Approval', loading: false });
                    
                    setMessageModal({
                        open: true,
                        header: "Success",
                        message: "Your registration request has been submitted successfully."
                    });
                    console.log('✨ Registration process completed successfully');
                }
            } catch (error) {
                console.error('❌ Error in registration process:', error);
                setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                setMessageModal({
                    open: true,
                    header: "Error",
                    message: "Failed to register for event. Please try again."
                });
            } finally {
                setIsRequestLoading(false);
            }
        } else if (state === 'canceled') {
            try {
                setButtonState(prev => ({ ...prev, text: 'Processing...', loading: true }));
                setIsRequestLoading(true);
                if (event_id) {
                    await removeUserFromEvent(event_id, standardUser.uid);
                    await updateUserEventStatus(standardUser.uid, event_id, 'canceled');
                    setState('canceled');
                    setButtonState({ text: 'Request to Join', loading: false });
                    setMessageModal({
                        open: true,
                        header: "Success",
                        message: "Your registration has been canceled successfully."
                    });
                }
            } catch (error) {
                console.error('Error canceling registration:', error);
                setButtonState(prev => ({ ...prev, text: 'Cancel Registration', loading: false }));
                setMessageModal({
                    open: true,
                    header: "Error",
                    message: "Failed to cancel registration. Please try again."
                });
            } finally {
                setIsRequestLoading(false);
            }
        }
    };
    
  
    

    
    useEffect(() => {
    
        // Check if localStorage already has a selected event ID
        const savedEventId = localStorage.getItem('selectedEventId');

        // Only save event_id if localStorage is empty
        if (!savedEventId && event_id) {
            localStorage.setItem('selectedEventId', event_id);
            console.log(`Event ID ${event_id} saved to localStorage.`);
        }
    }, []);
    

// When needed, update the message dynamically

useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("full_redirect") === "1") {
        setShowOverlay(true); // Show overlay

        setTimeout(() => {
            const newUrl = window.location.href.replace(/[?&]full_redirect=1/, '');

            try {
                
                if (window.top && window.top !== window.self) {
                    // Force redirect outside of iframe
                    window.top.location.replace(newUrl);
                } else {
                    // Normal redirect if already top-level
                    window.location.replace(newUrl);
                    
                }
                if (typeof window !== "undefined" && window.top !== null && window.top !== window.self) {
                    // Force redirect the top window
                    window.top!.location.href = window.location.href;
                }
                
            } catch (error) {
                console.error("Error accessing window.top:", error);
                window.location.replace(newUrl);
            }

            
        }, 4000); // 4 seconds delay
    }
}, []);

    
    

    useEffect(() => {
        // Retrieve selectedEventId from localStorage
        const selectedEventId = localStorage.getItem('selectedEventId');
        
        // Check if localStorage already has a selected event ID
        const autoregisterv = localStorage.getItem('autoregister');

        // Check if the event_id exists in selectedEventId and user is logged in
        if (selectedEventId && autoregisterv && user) {
            console.log(`Auto-registering for event: ${event_id}`);

            const autoRegister = async () => {
                try {
                    // Extract user ID safely
                    const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
                    
                    if (!userId) {
                        console.error('No valid user ID found for auto-registration');
                        return;
                    }

                    // First check if user has paid
                    const userPaid = await getUserTicket(selectedEventId, userId);
                    console.log('Payment status check:', userPaid);

                    if (userPaid && userPaid.status === "active") {
                        console.log('User has paid - setting paid state');
                        setUserPaid(userPaid);
                        setState('paid');
                        setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
                        localStorage.removeItem('selectedEventId');
                        localStorage.removeItem('autoregister');
                        return;
                    }

                    // If not paid, check user's event status
                    const userEvents = await getUserEvents(userId);
                    const eventExists = userEvents.filter((event: any) => event.event_id === selectedEventId);
                    
                    if (eventExists.length > 0) {
                        console.log('Found event in user events:', eventExists[0]);
                        const eventStatus = eventExists[0].status;
                        setState(eventStatus);
                        
                        // Update button text based on status
                        if (eventStatus === 'pending') {
                            setButtonState(prev => ({ ...prev, text: 'Pending Approval', loading: false }));
                        } else if (eventStatus === 'approved') {
                            setButtonState(prev => ({ ...prev, text: 'Complete Payment', loading: false }));
                        } else if (eventStatus === 'paid' || eventStatus === 'paid_in') {
                            setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
                        } else if (eventStatus === 'canceled') {
                            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                        } else {
                            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                        }
                    } else {
                        console.log('No existing status found - setting to register');
                        setState('register');
                        setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                    }
                    
                    localStorage.removeItem('selectedEventId');
                    localStorage.removeItem('autoregister');
                    
                } catch (error) {
                    console.error("Error auto-registering user:", error);
                }
            };

            autoRegister();
        }
    }, [event_id, user]);
    

    useEffect(() => {
        const fetchEvent = async () => {
            if (!event_id) return;
            
            setLoading(true);
            try {
                const eventData = await getEventById(event_id);
                if (eventData) {
                    setEvent(eventData);
                    console.log('Event data loaded:', eventData);
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            } finally {
                setLoading(false);
            }
        };

        // Initial fetch
        fetchEvent();

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                console.log("Tab is focused again. Refreshing data...");
                fetchEvent();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("focus", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("focus", handleVisibilityChange);
        };
    }, [event_id]); // Only depend on event_id

    // Separate useEffect for user-related checks
    useEffect(() => {
        const checkEventInUser = async () => {
            if (!event_id || !user) return;
            
            try {
                const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
                const standardizedUserId = standardizeUserId(userId);
                
                if (!standardizedUserId) {
                    console.log('No valid user ID found');
                    return;
                }

                console.log('Checking events for user:', standardizedUserId);
                const userEvents = await getUserEvents(standardizedUserId);
                
                if (userEvents && Array.isArray(userEvents)) {
                    const eventExists = userEvents.filter((event: any) => event.event_id === event_id);

                    if (eventExists.length > 0) {
                        console.log('Found event in user events:', eventExists[0]);
                        const eventStatus = eventExists[0].status;
                        // Don't change state if it's already approved
                        if (state !== 'approved') {
                            setState(eventStatus);
                        }
                        
                        // Update button text based on status
                        if (eventStatus === 'pending') {
                            setButtonState(prev => ({ ...prev, text: 'Pending Approval', loading: false }));
                        } else if (eventStatus === 'approved') {
                            setButtonState(prev => ({ ...prev, text: 'Complete Payment', loading: false }));
                        } else if (eventStatus === 'paid' || eventStatus === 'paid_in') {
                            setUserPaid(eventExists[0]);
                            setButtonState(prev => ({ ...prev, text: 'Show Ticket 🎟️', loading: false }));
                        } else if (eventStatus === 'canceled') {
                            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                        } else {
                            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                        }

                        if (eventStatus === 'register') {
                            setAfterRegisterModal(true);
                        }
                    } else {
                        // If no event found, set to register state
                        if (state !== 'approved') {
                            setState('register');
                            setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                        }
                    }
                }
            } catch (error) {
                console.error('Error checking event in user:', error);
                // On error, set to register state only if not approved
                if (state !== 'approved') {
                    setState('register');
                    setButtonState(prev => ({ ...prev, text: 'Request to Join', loading: false }));
                }
            }
        };

        checkEventInUser();
    }, [event_id, user]);

    // Add a new useEffect to update button text whenever state changes
    useEffect(() => {
        updateButtonText();
    }, [state]);

    useEffect(() => {
        const fetchLivers = async () => {
            if (!event) {
                console.log("No event data available yet");
                return;
            }

            console.log("Event data received", event);
            const liverArray = [];
            
            if (Array.isArray(event.livers)) {
                for (const speaker of event.livers) {
                    try {
                        const liverData: any = await getLiverDataById(speaker);
                        if (liverData) {
                            const liver = {
                                image: liverData.speaker_image,
                                title: liverData.inspiring_topic,
                                description: "12-13 Mar 2024",
                                location: "New York, USA",
                                tag: liverData.topics
                            };
                            liverArray.push(liver);
                        }
                    } catch (error) {
                        console.error(`Error fetching data for speaker ${speaker}:`, error);
                    }
                }
            }
            
            setLiverData(liverArray);
        };

        fetchLivers();
    }, [event]);

    if (loading) {
        return <Typography></Typography>;
    }

    if (!event) {
        return <Typography>Event not found</Typography>;
    }

    const handleCancelRegistration = async () => {
        try {
            const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;

            await removeUserFromEvent(event_id || '', userId);
            setState('canceled'); // Set state to canceled instead of register
            setButtonState(prev => ({ ...prev, text: 'Request to Join' })); // Immediately update button text
            setMessageModal({
                open: true,
                header: "Registration Canceled",
                message: `Your event registration has been successfully canceled.
Thank you very much, and we hope to see you at future events! 😊`,
            });
        } catch (error) {
            console.error('Error canceling registration:', error);
            setMessageModal({
                open: true,
                header: "Error",
                message: "Failed to cancel registration. Please try again.",
            });
        }
    };

    const handlePaymentSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(true);
        setMessage(''); // Reset message

        try {
            // Call mock payment function
            const mockPayment = httpsCallable(functions, 'mockPayment');
     // Extract user ID safely
     const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;

            const result: any = await mockPayment({
                user_id: userId,
                event_id: event_id,
                cardNumber: '1234-5678-9012-3456',
                amount: 100,
            });

            // Handle result

            // Display the result message
            setMessage(result.data);
            setPaymentModal(false);
            setApprovePaymentModal(true);
        } catch (error) {
            console.error('Error processing payment:', error);
            setMessage('An error occurred during payment processing.');
        } finally {
            setLoading(false);
        }
    };

    const handleDoneRequest = () => {
        setAfterRegisterModal(false);
        if (state === 'register' || state === 'canceled') {
            setState('pending');
        }
        if (state === 'approved') {
            // Navigate to payment with proper event data
            const eventData = {
                event_id: event.id,
                name: event.name,
                date: event.date,
                eventPrice: event.price || 0
            };
            navigate(`/payment/${event.id}`, { 
                state: { data: eventData },
                replace: true // Use replace to avoid back button issues
            });
        }
    };

    // Add checkPaymentStatus function
    const checkPaymentStatus = async (): Promise<boolean> => {
        try {
            const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
            if (!event_id || !userId) {
                console.error('Missing event_id or userId for payment check');
                return false;
            }

            const ticketData = await getUserTicket(event_id, userId);
            return Boolean(ticketData && ticketData.status === "active");
        } catch (error) {
            console.error('Error checking payment status:', error);
            return false;
        }
    };

    const handleShowTicket = async () => {
        try {
            setTicketLoading(true);
            
            // Extract user ID safely
            const userId = user?.user?.uid || user?.uid || (user as any)?.multiFactor?.user?.uid;
            
            if (!event_id || !userId) {
                console.error('Missing event_id or userId', { event_id, userId });
                setMessageModal({
                    open: true,
                    header: "Error",
                    message: "Could not find ticket information. Please try again.",
                });
                return;
            }

            // Open modal with ticket data
            setOpenTicketModal(true);
            setTicketLoading(false);
        } catch (error) {
            console.error('Error showing ticket:', error);
            setMessageModal({
                open: true,
                header: "Error",
                message: "Failed to load ticket. Please try again.",
            });
            setOpenTicketModal(false);
        } finally {
            setTicketLoading(false);
        }
    };

    const handleApproveModal = () => {
        setOpenApproveModal(false);
        setApprovePaymentModal(false);
    };


    const isEventInPast = event?.event_date?.seconds
        ? isBefore(new Date(event.event_date.seconds * 1000), new Date())
        : isBefore(new Date(event.event_date), new Date());

    // Add updateUserEventStatus function
    const updateUserEventStatus = async (userId: string, eventId: string, status: string) => {
        try {
            const userRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userRef);
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const events = userData.events || [];
                
                const updatedEvents = events.map((event: any) => 
                    event.event_id === eventId 
                        ? { ...event, status }
                        : event
                );
                
                await updateDoc(userRef, { events: updatedEvents });
            }
        } catch (error) {
            console.error('Error updating user event status:', error);
            throw error;
        }
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            background: '#FFFFFF',
        }}>
            {/* Hero Section */}
            <Box sx={{
                position: 'relative',
                height: '45vh',
                width: '100%',
                overflow: 'hidden',
            }}>
                <img
                    src={event.event_image}
                    alt={event.event_name}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                {/* Gradient Overlay */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    padding: '24px 20px',
                }}>
                    <Typography 
                        variant="h4" 
                        sx={{ 
                            color: 'white',
                            fontSize: '28px',
                            fontWeight: 700,
                            textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            mb: 2
                        }}
                    >
                        {event.event_name}
                    </Typography>
                    
                    {/* Price Badge */}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 1
                    }}>
                        <Chip
                            label={`₪${calcPrice()}`}
                            sx={{
                                backgroundColor: 'white',
                                color: '#1976d2',
                                fontWeight: 700,
                                fontSize: '16px',
                                height: '32px',
                                '& .MuiChip-label': {
                                    px: 2
                                }
                            }}
                        />
                        {calcPriceText() && (
                            <Typography sx={{ color: 'white', fontSize: '14px' }}>
                                {calcPriceText()}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Content Section */}
            <Box sx={{ px: 2.5, py: 3, maxWidth: '600px', margin: '0 auto' }}>
                {/* Date & Location Card */}
                <Box sx={{
                    backgroundColor: '#F8FAFC',
                    borderRadius: '16px',
                    p: 2.5,
                    mb: 3
                }}>
                    {/* Date */}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 2.5,
                    }}>
                        <Box sx={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '12px',
                            backgroundColor: '#E3F2FD',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CalendarToday sx={{ color: '#1976d2', fontSize: '24px' }} />
                        </Box>
                        <Box>
                            <Typography sx={{ 
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#1E293B',
                                mb: 0.5
                            }}>
                                {event.event_date?.seconds ? 
                                    format(new Date(event.event_date.seconds * 1000), 'EEEE, MMMM dd, yyyy') :
                                    format(new Date(event.event_date), 'EEEE, MMMM dd, yyyy')}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', color: '#64748B' }}>
                                {event.event_time || 'Time to be announced'}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Location */}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <Box sx={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '12px',
                            backgroundColor: '#E3F2FD',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <LocationOn sx={{ color: '#1976d2', fontSize: '24px' }} />
                        </Box>
                        <Box>
                            <Typography sx={{ 
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#1E293B',
                                mb: 0.5
                            }}>
                                {event.locationName}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', color: '#64748B' }}>
                                {event.locationDetails}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Action Button */}
                {!isEventInPast && (
                    <Box sx={{ mb: 4 }}>
                        {isCheckingStatus || state === 'loading' ? (
                            <Button
                                variant="contained"
                                fullWidth
                                disabled
                                sx={{
                                    backgroundColor: '#E2E8F0',
                                    color: '#94A3B8',
                                    py: 2,
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }}
                            >
                                <CircularProgress size={20} sx={{ color: '#94A3B8' }} />
                                Checking Status...
                            </Button>
                        ) : !user && paymentRoute === '5' ? (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleOpenModal('register')}
                                disabled={event.status === 'sold'}
                                sx={{
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    py: 2,
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: '0 4px 6px rgba(25, 118, 210, 0.12)',
                                    '&:hover': {
                                        backgroundColor: '#1565c0'
                                    }
                                }}
                            >
                                Pay Now
                            </Button>
                        ) : !user ? (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleOpenModal('register')}
                                disabled={event.status === 'sold'}
                                sx={{
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    py: 2,
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: '0 4px 6px rgba(25, 118, 210, 0.12)',
                                    '&:hover': {
                                        backgroundColor: '#1565c0'
                                    }
                                }}
                            >
                                Request to Join
                            </Button>
                        ) : (state === 'paid' || state === 'paid_in') ? (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleShowTicket}
                                disabled={ticketLoading}
                                sx={{
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                    py: 2,
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: '0 4px 6px rgba(25, 118, 210, 0.12)',
                                    '&:hover': {
                                        backgroundColor: '#1565c0'
                                    }
                                }}
                            >
                                {ticketLoading ? 'Loading...' : 'Show Ticket 🎟️'}
                            </Button>
                        ) : (
                            <Box sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        // Determine the correct action based on current state
                                        const action = state === 'approved' ? 'approved' : 'register';
                                        handleOpenModal(action);
                                    }}
                                    disabled={event.status === 'sold' || buttonState.loading || state === 'pending'}
                                    sx={{
                                        backgroundColor: state === 'approved' ? '#2E7D32' : '#1976d2',
                                        color: 'white',
                                        py: 2,
                                        borderRadius: '12px',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        boxShadow: '0 4px 6px rgba(25, 118, 210, 0.12)',
                                        '&:hover': {
                                            backgroundColor: state === 'approved' ? '#1B5E20' : '#1565c0'
                                        },
                                        '&.Mui-disabled': {
                                            backgroundColor: '#E2E8F0',
                                            color: '#94A3B8'
                                        },
                                        position: 'relative',
                                        '& .MuiCircularProgress-root': {
                                            position: 'absolute',
                                            left: '50%',
                                            marginLeft: '-12px'
                                        }
                                    }}
                                >
                                    {buttonState.loading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        buttonState.text
                                    )}
                                </Button>

                                {/* Info Text with Tooltip - Below the button */}
                                {buttonState.text === 'Pending Approval' && (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center', 
                                        mt: 1 
                                    }}>
                                        <Typography
                                            onClick={() => setPendingStatusModal(true)}
                                            sx={{ 
                                                fontSize: '12px',
                                                color: '#64748B',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                '&:hover': {
                                                    color: '#1976d2'
                                                }
                                            }}
                                        >
                                            What does this mean?
                                        </Typography>
                                    </Box>
                                )}

                                {/* Cancel Registration - Show only when state is pending */}
                                {state === 'pending' && (
                                    <Typography
                                        component="span"
                                        onClick={() => {
                                            if (!buttonState.loading) {
                                                handleOpenModal('canceled');
                                            }
                                        }}
                                        sx={{
                                            display: 'inline-block',
                                            color: '#94A3B8',
                                            fontSize: '13px',
                                            mt: 2,
                                            cursor: buttonState.loading ? 'not-allowed' : 'pointer',
                                            textDecoration: 'none',
                                            transition: 'all 0.2s ease',
                                            '&:hover': {
                                                color: '#EF4444',
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        Cancel registration
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                )}

                {/* Attendees Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography sx={{ 
                        fontSize: '18px',
                        fontWeight: 700,
                        color: '#1E293B',
                        mb: 2
                    }}>
                        Who's Coming?
                    </Typography>
                    {event_id && <PeopleJoined guests={event.guests} eventId={event_id} />}
                </Box>

                {/* About Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography sx={{ 
                        fontSize: '18px',
                        fontWeight: 700,
                        color: '#1E293B',
                        mb: 2
                    }}>
                        About This Event
                    </Typography>
                    <Typography
                        sx={{
                            direction: /[\u0590-\u05FF]/.test(event.description) ? 'rtl' : 'ltr',
                            color: '#64748B',
                            fontSize: '15px',
                            lineHeight: 1.6
                        }}
                        dangerouslySetInnerHTML={{ __html: event.description }}
                    />
                </Box>

                {/* Speakers Section */}
                {liver && liver.length > 0 && (
                    <Box sx={{ mb: 4 }}>
                        <Typography sx={{ 
                            fontSize: '18px',
                            fontWeight: 700,
                            color: '#1E293B',
                            mb: 2
                        }}>
                            Featured Speakers
                        </Typography>
                        <ImageSlider imageCards={liver} />
                    </Box>
                )}
            </Box>

            {/* Pending Status Modal */}
            <Modal 
                open={pendingStatusModal} 
                onClose={() => setPendingStatusModal(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center'
                }}>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                        onClick={() => setPendingStatusModal(false)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box sx={{ 
                        mb: 3,
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Box sx={{
                            width: '64px',
                            height: '64px',
                            borderRadius: '50%',
                            backgroundColor: '#E3F2FD',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HourglassEmpty sx={{ 
                                fontSize: '32px',
                                color: '#1976d2'
                            }} />
                        </Box>
                    </Box>

                    <Typography variant="h6" sx={{ 
                        fontWeight: 600,
                        mb: 2,
                        color: '#1E293B'
                    }}>
                        Request Under Review
                    </Typography>

                    <Typography sx={{ 
                        color: '#64748B',
                        mb: 3,
                        fontSize: '14px',
                        lineHeight: 1.6
                    }}>
                        Your request is still being reviewed. You will receive a notification when your registration is approved.
                        <br /><br />
                        Expected response time: Up to 2 days before the event.
                    </Typography>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setPendingStatusModal(false)}
                        sx={{
                            backgroundColor: '#1976d2',
                            color: 'white',
                            py: 1.5,
                            borderRadius: '12px',
                            fontSize: '14px',
                            fontWeight: 600,
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#1565c0'
                            }
                        }}
                    >
                        Got it
                    </Button>
                </Box>
            </Modal>

            {/* Ticket Modal */}
            <Modal
                open={openTicketModal}
                onClose={() => !ticketLoading && setOpenTicketModal(false)}
                aria-labelledby="ticket-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    maxWidth: '400px',
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                    outline: 'none'
                }}>
                    {ticketLoading ? (
                        <Box sx={{ 
                            p: 4, 
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <CircularProgress size={40} />
                            <Typography variant="body1">
                                Loading your ticket...
                            </Typography>
                        </Box>
                    ) : event ? (
                        <Ticket
                            event={{
                                event_image: event.event_image || '',
                                event_name: event.event_name || '',
                                locationName: event.locationName || '',
                                hall: event.locationDetails || '',
                                event_date: event.event_date
                            }}
                            user={{
                                name: user?.displayName || user?.phoneNumber || 'Guest'
                            }}
                            booking={{
                                seat: 'Salon',
                                date: new Date().toLocaleDateString(),
                                time: event.event_time || '',
                                paymentAuth: 'N/A',
                                paidPrice: calcPrice(),
                                qrCode: `${event_id}:${userId}`,
                                amount: calcPrice().toString()
                            }}
                            onClose={() => setOpenTicketModal(false)}
                        />
                    ) : (
                        <Box sx={{ 
                            p: 4, 
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                        }}>
                            <Typography variant="body1" color="error">
                                Could not load ticket. Please try again.
                            </Typography>
                            <Button 
                                variant="contained" 
                                onClick={() => setOpenTicketModal(false)}
                            >
                                Close
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>

            {/* Login Modal */}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
            >
                <Fade in={openModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '400px',
                        bgcolor: 'background.paper',
                        borderRadius: '16px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                        p: 4,
                        outline: 'none'
                    }}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                            onClick={() => setOpenModal(false)}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h6" sx={{ 
                            fontWeight: 600,
                            mb: 2,
                            color: '#1E293B',
                            textAlign: 'center'
                        }}>
                            Login Required
                        </Typography>

                        <Typography sx={{ 
                            color: '#64748B',
                            mb: 3,
                            fontSize: '14px',
                            lineHeight: 1.6,
                            textAlign: 'center'
                        }}>
                            Please log in to register for this event.
                        </Typography>

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpenModal(false);
                                navigate('/verification');
                            }}
                            sx={{
                                backgroundColor: '#1976d2',
                                color: 'white',
                                py: 1.5,
                                borderRadius: '12px',
                                fontSize: '14px',
                                fontWeight: 600,
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#1565c0'
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            {/* Phone Verification Modal */}
            <Modal
                open={openPhoneVerificationModal}
                onClose={() => !isVerifying && handleClosePhoneModal()}
                closeAfterTransition
            >
                <Fade in={openPhoneVerificationModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '400px',
                        bgcolor: 'background.paper',
                        borderRadius: '16px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                        p: 4,
                        outline: 'none'
                    }}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                            onClick={() => !isVerifying && handleClosePhoneModal()}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h6" sx={{ 
                            fontWeight: 600,
                            mb: 2,
                            color: '#1E293B',
                            textAlign: 'center'
                        }}>
                            Verify Your Phone Number
                        </Typography>

                        <Typography sx={{ 
                            color: '#64748B',
                            mb: 3,
                            fontSize: '14px',
                            lineHeight: 1.6,
                            textAlign: 'center'
                        }}>
                            Please enter your phone number to receive a verification code.
                        </Typography>

                        {!showVerificationInput ? (
                            <form onSubmit={handlePhoneSubmit}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    variant="outlined"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your phone number"
                                    sx={{ mb: 3 }}
                                    disabled={isVerifying}
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    disabled={isVerifying || !phoneNumber}
                                    sx={{
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                        py: 1.5,
                                        borderRadius: '12px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#1565c0'
                                        }
                                    }}
                                >
                                    {isVerifying ? 'Sending...' : 'Send Code'}
                                </Button>
                            </form>
                        ) : (
                            <form onSubmit={handleVerificationSubmit}>
                                <TextField
                                    fullWidth
                                    label="Verification Code"
                                    variant="outlined"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    placeholder="Enter 6-digit code"
                                    sx={{ mb: 3 }}
                                    disabled={isVerifying}
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    disabled={isVerifying || !verificationCode}
                                    sx={{
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                        py: 1.5,
                                        borderRadius: '12px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        mb: 2,
                                        '&:hover': {
                                            backgroundColor: '#1565c0'
                                        }
                                    }}
                                >
                                    {isVerifying ? 'Verifying...' : 'Verify Code'}
                                </Button>
                                <Button
                                    fullWidth
                                    variant="text"
                                    onClick={handlePhoneSubmit}
                                    disabled={isResendDisabled || isVerifying}
                                    sx={{
                                        color: '#1976d2',
                                        fontSize: '14px',
                                        textTransform: 'none',
                                    }}
                                >
                                    {isResendDisabled ? `Resend code in ${resendCountdown}s` : 'Resend Code'}
                                </Button>
                            </form>
                        )}

                        {verificationError && (
                            <Typography sx={{ 
                                color: '#EF4444',
                                mt: 2,
                                textAlign: 'center',
                                fontSize: '14px'
                            }}>
                                {verificationError}
                            </Typography>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default EventDetail;