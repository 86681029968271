import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CardMedia,
  Chip,
  CircularProgress,
  IconButton,
  Divider,
  GlobalStyles,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchUserByDocumentId } from '../../shared/services/userService';

interface User {
  firstName: string;
  purpose: string[];
  bio: string;
  badges: string[];
  image: string;
  occupation?: string;
}

const ViewUserProfile: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const firestoreData = await fetchUserByDocumentId(userId || '');
        if (firestoreData) {
          // Map Firestore data to our User interface
          const mappedUser: User = {
            firstName: firestoreData.firstName || '',
            purpose: firestoreData.purpose || [],
            bio: firestoreData.bio || '',
            badges: firestoreData.badges || [],
            image: firestoreData.image || '/profileDefault.png',
            occupation: firestoreData.occupation,
          };
          setUserData(mappedUser);
        } else {
          console.log('User does not exist');
          navigate('/ConnectionsPage'); // Redirect if user not found
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/ConnectionsPage'); // Redirect on error
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId, navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return null;
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: { fontFamily: "'Poppins', sans-serif !important" },
          '*': { fontFamily: "'Poppins', sans-serif !important" },
        }}
      />
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#fff',
          position: 'relative',
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate('/ConnectionsPage')}
          sx={{
            position: 'fixed',
            top: 16,
            left: 16,
            zIndex: 9999,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            width: 40,
            height: 40,
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            '@media (max-width: 600px)': {
              top: 8,
              left: 8,
              width: 36,
              height: 36,
            }
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 24, color: '#000' }} />
        </IconButton>

        {/* Profile Image Section */}
        <Box
          sx={{
            width: '100%',
            height: 300,
            position: 'relative',
            backgroundColor: '#fff',
            overflow: 'hidden',
            mb: 2,
            zIndex: 1,
          }}
        >
          {!imageLoaded && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <CardMedia
            component="img"
            image={userData.image}
            alt={userData.firstName || "Profile"}
            onLoad={() => setImageLoaded(true)}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: imageLoaded ? 'block' : 'none',
            }}
          />
        </Box>

        {/* Profile Content */}
        <Box sx={{ px: 2, mt: 2 }}>
          <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
            {userData.firstName}
          </Typography>

          {userData.occupation && (
            <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
              {userData.occupation}
            </Typography>
          )}

          {/* About Me Section */}
          <Box mb={2}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              About me
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ direction: 'rtl', fontSize: '1.1rem' }}>
              {userData.bio || 'No bio available'}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Goals Section */}
          <Box mb={2}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Goals
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {userData.purpose.map((goal, index) => (
                <Chip key={index} label={goal} sx={{ fontSize: '0.85rem' }} />
              ))}
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Skills Section */}
          <Box mb={2}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Skills
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {userData.badges.map((badge, index) => (
                <Chip key={index} label={badge} sx={{ fontSize: '0.85rem' }} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewUserProfile; 