import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Html5QrcodeScanner } from "html5-qrcode";
import axios from "axios";

const BarcodeScanner = ({ onClose }: { onClose: () => void }) => {
  const scannerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const scannerInstance = useRef<Html5QrcodeScanner | null>(null);

  useEffect(() => {
    let scanner: Html5QrcodeScanner | null = null;

    const initializeScanner = () => {
      if (!scannerRef.current) return;

      scanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        false
      );

      scanner.render(
        async (decodedText) => {
          if (processing) return; // Prevent multiple scans while processing

          setProcessing(true); // Show "Processing..."
          setError(null);
          setLoading(true);

          try {
            console.log("Scanned Barcode:", decodedText);
            const [eventId, userId] = decodedText.split(":");
            if (!eventId || !userId) throw new Error("Invalid barcode format");

            await axios.post(
              "https://us-central1-salonim-29dcf.cloudfunctions.net/UserInTheEvent",
              { data: `${userId}_${eventId}` }
            );

            alert("✅ Check-in successful!");
            onClose(); // Close modal on success
          } catch (err) {
            console.error("Error:", err);
            setError("❌ Failed to check-in. Try again.");
            setProcessing(false); // Allow scanning again if it fails
          } finally {
            setLoading(false);
          }
        },
        (error) => console.warn("Scan Error:", error)
      );
    };

    const timeoutId = setTimeout(initializeScanner, 500);

    return () => {
      clearTimeout(timeoutId);
      scanner?.clear();
    };
  }, [onClose, processing]);

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "12px",
          textAlign: "center",
          boxShadow: 24,
        }}
      >
        {/* Close Button */}
        <IconButton sx={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          Scan Ticket Barcode
        </Typography>

        {/* Scanner */}
        {!processing ? (
          <Box
            ref={scannerRef}
            id="reader"
            sx={{
              width: "100%",
              height: "250px",
              minHeight: "250px",
              backgroundColor: "#eee",
              mb: 2,
            }}
          />
        ) : (
          <Typography sx={{ mb: 2 }}>Processing...</Typography>
        )}

        {/* Loading Spinner */}
        {loading && <CircularProgress sx={{ mb: 2 }} />}

        {/* Error Message */}
        {error && <Typography color="error">{error}</Typography>}

        {/* Close Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, borderRadius: "8px" }}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default BarcodeScanner;
