// src/eventsService.js
import { auth, db } from './firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from 'firebase/storage';
import {
    collection,
    addDoc,
    getDocs,
    getDoc,
    setDoc,
    doc,
    updateDoc,
    deleteDoc,
    query,
    where,
    orderBy,
    limit,
    getFirestore,
    startAfter,
} from 'firebase/firestore';
import axios from 'axios';

// Reference to the 'events' collection
const userCollection = collection(db, 'users');


const storage = getStorage(); // Initialize Firebase Storage


// Define the Person type based on ProfilePage
// Define the Person type
interface Person {
    name: string;
    title: string;
    image: string;
    status: 'connect' | 'connected' | 'pending';
    backgroundImage?: string;
    uid: string;
    gender?: string;
    profession?: string;
    skills?: string[];
    goals?: string[];
  }
  
// Add this variable at the top level to track the last document
let lastVisibleDoc: any = null;

interface FilterOptions {
  gender?: string[];
  skills?: string[];
  goals?: string[];
}

export const fetchUsers = async (page: number, filters?: FilterOptions): Promise<Person[]> => {
  const usersPerPage = 10;

  try {
    const usersCollectionRef = collection(db, 'users');
    let usersQuery;

    // Start with base query
    let baseQuery = query(usersCollectionRef);

    // Add filters if they exist
    if (filters) {
      const conditions = [];
      
      if (filters.gender && filters.gender.length > 0) {
        console.log('Applying gender filter:', filters.gender);
        conditions.push(where('gender', 'in', filters.gender));
      }
      
      // Apply only one array filter at a time (prefer skills over goals)
      if (filters.skills && filters.skills.length > 0) {
        console.log('Applying skills filter:', filters.skills);
        conditions.push(where('badges', 'array-contains-any', filters.skills));
      } else if (filters.goals && filters.goals.length > 0) {
        console.log('Applying goals filter:', filters.goals);
        conditions.push(where('purpose', 'array-contains-any', filters.goals));
      }

      // Apply conditions to the query
      if (conditions.length > 0) {
        baseQuery = query(usersCollectionRef, ...conditions);
      } else {
        // Only add ordering if there are no array filters
        baseQuery = query(usersCollectionRef, orderBy('firstName'));
      }
    } else {
      // If no filters, use ordering
      baseQuery = query(usersCollectionRef, orderBy('firstName'));
    }

    // Add pagination
    if (page === 1) {
      usersQuery = query(baseQuery, limit(usersPerPage));
      lastVisibleDoc = null;
    } else {
      if (!lastVisibleDoc) {
        return [];
      }
      usersQuery = query(baseQuery, startAfter(lastVisibleDoc), limit(usersPerPage));
    }

    const snapshot = await getDocs(usersQuery);

    // Get all users from the query
    let users: Person[] = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        name: data.firstName || '',
        title: data.title || '',
        image: data.image || '/profileDefault.png',
        status: data.status || 'connect',
        backgroundImage: data.backgroundImage,
        uid: doc.id,
        gender: data.gender,
        skills: data.badges || [],
        goals: data.purpose || []
      };
    });

    // Apply the second array filter in memory if needed
    if (filters?.goals && filters.goals.length > 0 && filters?.skills && filters.skills.length > 0) {
      console.log('Applying goals filter in memory:', filters.goals);
      users = users.filter(user => 
        filters.goals!.some(goal => (user.goals || []).includes(goal))
      );
    }

    // Update the last visible document
    if (!snapshot.empty) {
      lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
    }

    // Log the results for debugging
    console.log('Query results:', users);

    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};
  



export const getDownloadImageUrl = async (file: Blob | Uint8Array | ArrayBuffer) => {
    if (!file) return null;
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `images/temp/${(file as File).name}`);

    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(snapshot.ref);

        return downloadURL;
    } catch (error) {
        console.error('Error uploading file and getting download URL:', error);
        return null;
    }
};

export const uploadImageAndSaveToFirestore = async (userId: any, file: Blob | Uint8Array | ArrayBuffer) => {
    if (!file) return;

    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `images/${userId}/${(file as File).name}`);

    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Now update Firestore with the file's download URL
        const userDocRef = doc(db, `users/${userId}`);
        await updateDoc(userDocRef, {
            image: downloadURL, // Save the download URL in Firestore
        });
        console.log('File uploaded and Firestore updated successfully');
    } catch (error) {
        console.error('Error uploading file and saving to Firestore:', error);
    }
};

export const addSalonToUser = async (userId: any, salonDescription: string, location: string) => {
    try {
        const salonDocRef = await addDoc(collection(db, 'salons'), {
            userId: userId,
            description: salonDescription,
            location: location
        });
        console.log('Salon added to Firestore:', salonDocRef.id);

        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const salons = userDocSnap.data()?.salons || [];
            salons.push(salonDocRef.id);
            await updateDoc(userDocRef, {
                salons: salons
            });
            console.log('Salon added to user successfully');
        } else {
            console.error('No such user document!');
        }
    } catch (error) {
        console.error('Error adding salon to user:', error);
        throw error;
    }
};

export const addEventToUser = async (userId: any, eventId: string) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            const events = userDocSnap.data()?.events || [];
            
            // Check if the event already exists for the user
            const isEventAdded = events.some((event: any) => event.event_id === eventId);

            if (!isEventAdded) {
                const eventItem = {
                    event_id: eventId,
                    status: 'pending',
                };
                events.push(eventItem);

                // Update the document with the new event list
                await updateDoc(userDocRef, {
                    events: events
                });
                
                console.log('Event added to user successfully');
            } else {
                console.log('User already has this event');
            }
        } else {
            console.error('No such user document!');
        }
    } catch (error) {
        console.error('Error adding event to user:', error);
        throw error;
    }
};


// Function to add email document
export const sendEmail = async (userId: string, qrcode: any) => {
    try {

        const storage = getStorage();
        const storageRef = ref(storage, `qrcodes/userId/${Date.now()}-qrcode.png`);

        const file = await uploadString(storageRef, qrcode.split(',')[1], 'base64', { contentType: 'image/png' });

        // Upload the file to Firebase Storage
        const downloadURL = await getDownloadURL(storageRef);

        await addDoc(collection(db, "emails"), {
            to: "avihairing@gmail.com",
            subject: "You got approved to the event",
            text: "Congratulations! You have been invited to the event. Please choose your preferred location, time, and place.",
            html: `
            <html>
            <body>
            <h1>Congratulations!</h1>
            <p>You have been invited to the event. Please scan qa code for event details and payment.</p>
            <img src="${downloadURL}" alt="QR Code">
            </body>
            </html>
            `
        });
        console.log('Email document added to Firestore');
    } catch (error) {
        console.error('Error adding document: ', error);
    }
};

// Function to add email document
export const sendSMS = async (userPhoneNumber: string, qrcode: any) => {
    try {

        await addDoc(collection(db, "sms"), {
            to: '+972543260783',
            subject: "בזמן שאתה ישן לך אני סידרתי שליחת sms מהאפליקציה",
            text: "מקווה שאתה ישן טוב אחרי הארוחה",
        });
        console.log('Email document added to Firestore');
    } catch (error) {
        console.error('Error adding document: ', error);
    }
};

export const deleteUserAndData = async (userId: string) => {
    try {
        // Delete the user's Firestore document
        const userDocRef = doc(db, 'users', userId);
        await deleteDoc(userDocRef);
        console.log('User document deleted successfully.');
    } catch (error) {
        console.error('Error deleting user and related data:', error);
        throw error;
    }
};



export const getUserEvents = async (userId: string = getUserId() || "") => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const events = userDocSnap.data()?.events || [];
            return events;
        } else {
            console.error('No such user document!');
            return [];
        }
    } catch (error) {
        console.error('Error fetching user events:', error);
        return [];
    }
};

const getUserId = (): string | null => {
    if (auth.currentUser) {
        // Attempt to extract UID from different possible locations
        const userId = auth.currentUser.uid || (auth.currentUser as any)?.multiFactor?.user?.uid;

        if (userId) {
            console.log("Extracted User ID:", userId);
            return userId;
        }
    }

    console.warn('User is not signed in or UID is missing.');
    return null;
};



export const fetchUserByDocumentId = async (userId: string) => {
    try {

        const docRef = await doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching user document:', error);
        return null;
    }
};

export const fetchUserByPhoneNumber = async (phone: string) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('phoneNumber', '==', phone));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data(); // Return first matching user
    }
    return null; // No user found
};

export const setUserEmptyDoc = async (userId: string) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        await setDoc(userDocRef, {}); // Add an empty user document
        console.log('Empty user document set successfully');
    } catch (error) {
        console.error('Error setting empty user document:', error);
        throw error;
    }
};

export const updateUserDocument = async (userId: string, data: any) => {
    try {
      // If data.image exists, ensure it's a valid string.
      if (data.image) {
        if (typeof data.image !== 'string') {
          console.warn("Expected data.image to be a string but got:", data.image);
          delete data.image;
        } else if (data.image.indexOf('http') === -1) {
          // If needed, add any logic here for non-URL strings.
          console.log("Image string does not contain 'http'.");
        }
      }
  
      const docRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(docRef);
  
      if (!userDocSnap.exists()) {
        await setDoc(docRef, data); // Create a new document if it doesn't exist
      } else {
        await updateDoc(docRef, { ...data });
      }
  
      return (await getDoc(docRef)).data();
    } catch (error) {
      console.error('Error updating user document:', error);
      throw error;
    }
  };
  
  



export const getUserByID = async (id: any) => {
    try {
        
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            try {
                const userDocRef = doc(db, 'users', userId); // Assuming 'users' is your collection
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    console.log('User Document:', userDocSnap.data());
                    return userDocSnap.data();
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user document:', error);
            }
        } else {
            console.error('No user is signed in.');
        }
    } catch (error) {
        console.error('Error fetching user by document:', error);
        throw error; // You can also handle this error differently if needed
    }
};


/**
 * Retrieves liver data by liver ID.
 *
 * This function fetches a document from the 'Livers' collection in the database
 * using the provided liver ID. If the document exists, it returns the data;
 * otherwise, it logs an error and returns null.
 *
 * @param {string} liverId - The ID of the liver document to retrieve.
 * @returns {Promise<Object | null>} A promise that resolves to the liver data if the document exists, or null if it does not.
 * @throws Will throw an error if there is an issue fetching the liver document.
 */
export const getLiverDataById = async (liverRef: any) => {
    try {
        const liverDocSnap = await getDoc(liverRef);
        if (liverDocSnap.exists()) {
            return liverDocSnap.data();
        } else {
            console.error('No such liver document!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching liver document:', error);
        throw error;
    }
};

/*
export const getRandomLivers = async () => {
   try {
        const liversCollection = collection(db, 'Livers');
        const liversSnapshot = await getDocs(liversCollection);
        const livers = liversSnapshot.docs.map(doc => doc.data());

        // Shuffle the array to get random livers
        for (let i = livers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [livers[i], livers[j]] = [livers[j], livers[i]];
        }

        // Return the first 10 livers from the shuffled array
        return livers.slice(0, 0);
    } catch (error) {
        console.error('Error fetching random livers:', error);
        throw error;
    }
};

*/

// Add at the top of the file, after the imports
let isPeopleCacheDisabled = false;

export const togglePeopleCache = () => {
    isPeopleCacheDisabled = !isPeopleCacheDisabled;
    return isPeopleCacheDisabled;
};

export const isPeopleCacheEnabled = () => !isPeopleCacheDisabled;

interface User {
    id: string;
    image?: string;
    gender?: 'Man' | 'Women';
}

export const getRandomLivers = async (eventId: string, limit: number = 10): Promise<User[]> => {
    try {
        // If cache is disabled, fetch fresh data from the users collection
        if (isPeopleCacheDisabled) {
            console.log('People cache is disabled, fetching fresh data from users collection');
            const usersCollectionRef = collection(db, 'users');
            const usersSnapshot = await getDocs(usersCollectionRef);
            const allUsers = usersSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            })) as User[];
            
            // Filter out users with valid photos and only women
            const validUsers = allUsers.filter(user => {
                const photoUrl = user.image;
                if (!photoUrl || photoUrl.length === 0) return false;
                if (photoUrl.includes('undefined') || photoUrl.includes('null')) return false;
                if (/\d+\.png$/i.test(photoUrl)) return false;
                if (/image\d*\.png$/i.test(photoUrl)) return false;
                if (/avatar\d*\.png$/i.test(photoUrl)) return false;
                if (/default\d*\.png$/i.test(photoUrl)) return false;
                if (/user\d*\.png$/i.test(photoUrl)) return false;
                // Only include women
                if (!user.gender || user.gender !== 'Women') return false;
                return true;
            });

            // Shuffle the array
            const shuffleArray = (array: User[]) => {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
            };

            shuffleArray(validUsers);

            // Return the requested number of women
            return validUsers.slice(0, limit);
        }

        // If cache is enabled, use the existing guests from the event
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (!eventDocSnap.exists()) {
            console.error('No event found with the specified event_id');
            return [];
        }

        const eventData = eventDocSnap.data();
        let guests = (eventData?.guests || []) as User[];

        // Filter to only include women
        guests = guests.filter(guest => guest.gender === 'Women');

        // Shuffle the array
        const shuffleArray = (array: User[]) => {
            for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
        }
        };

        shuffleArray(guests);

        // Return the requested number of women
        return guests.slice(0, limit);
    } catch (error) {
        console.error('Error fetching random users from event:', error);
        throw error;
    }
};

interface EventUser {
    id: string;
    user_id?: string;
    image?: string;
    gender?: 'Men' | 'Women';
    status?: 'paid' | 'approved' | 'paid_in' | 'liver';
    username?: string;
    liver_name?: string;
    liver_image?: string;
    liver_gender?: 'Men' | 'Women';
}

// Add at the top of the file, after the imports
interface CacheData {
    users: EventUser[];
    timestamp: number;
}

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const eventCache: { [key: string]: CacheData } = {};

const isCacheValid = (eventId: string): boolean => {
    const cache = eventCache[eventId];
    if (!cache) return false;
    return Date.now() - cache.timestamp < CACHE_DURATION;
};

const updateCache = (eventId: string, users: EventUser[]) => {
    eventCache[eventId] = {
        users,
        timestamp: Date.now()
    };
};

export const getBalancedEventUsers = async (eventId: string): Promise<EventUser[]> => {
    try {
        // Check if we have valid cached data
        if (isCacheValid(eventId)) {
            console.log('Using cached data for event:', eventId);
            return eventCache[eventId].users;
        }

        console.log('Cache expired or not found, fetching fresh data for event:', eventId);
        
        // First, get users from the event with specific statuses
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (!eventDocSnap.exists()) {
            console.error('No event found with the specified event_id:', eventId);
            return [];
        }

        const eventData = eventDocSnap.data();
        let eventUsers = (eventData?.guests || []) as EventUser[];
        console.log('Found event users:', eventUsers.length);

        // Filter users with valid statuses
        eventUsers = eventUsers.filter(user => 
            user.status === 'paid' || 
            user.status === 'approved' || 
            user.status === 'paid_in'
        );
        console.log('Filtered event users with valid statuses:', eventUsers.length);

        // Fetch user details from the users collection to get gender information
        const userDetails = await Promise.all(
            eventUsers.map(async (user) => {
                try {
                    if (!user.user_id) return user;
                    const userDocRef = doc(db, 'users', user.user_id);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        return {
                            ...user,
                            gender: userData.gender,
                            image: userData.image || user.image
                        };
                    }
                    return user;
                } catch (error) {
                    console.error('Error fetching user details:', error);
                    return user;
                }
            })
        );

        // Function to check if a photo URL is valid
        const hasValidPhoto = (user: EventUser) => {
            const photoUrl = user.image;
            if (!photoUrl || photoUrl.length === 0) return false;
            if (photoUrl.includes('undefined') || photoUrl.includes('null')) return false;
            return true;
        };

        // Separate users by photo validity
        const usersWithPhotos = userDetails.filter(hasValidPhoto);
        const usersWithoutPhotos = userDetails.filter(user => !hasValidPhoto(user));
        console.log('Users with valid photos:', usersWithPhotos.length);

        // Always fetch from livers to ensure we have enough users
        console.log('Fetching from livers collection to ensure enough users');
        const liversCollectionRef = collection(db, 'Livers');
        const liversSnapshot = await getDocs(liversCollectionRef);
        const livers = liversSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            status: 'liver' as const
        })) as EventUser[];

        // Filter livers with valid photos
        const validLivers = livers.filter(hasValidPhoto);
        console.log('Valid livers with photos:', validLivers.length);

        // Combine all users
        const allUsers = [...userDetails, ...validLivers];
        
        // Shuffle the arrays
        const shuffleArray = (array: EventUser[]) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        // Get 12 users (7 visible + 5 faded)
        const selectedUsers = shuffleArray(allUsers)
            .slice(0, 12);

        // If we still don't have 12 users, create placeholder users
        if (selectedUsers.length < 12) {
            const remainingNeeded = 12 - selectedUsers.length;
            const placeholderUsers = Array(remainingNeeded).fill(null).map((_, index) => ({
                id: `placeholder-${index}`,
                image: '/profileDefault.png',
                gender: 'Women' as const,
                status: 'paid' as const
            }));
            selectedUsers.push(...placeholderUsers);
        }

        console.log('Final selected users:', selectedUsers.length);
        
        // Update cache with the new data
        updateCache(eventId, selectedUsers);
        
        return selectedUsers;

    } catch (error) {
        console.error('Error in getBalancedEventUsers:', error);
        throw error;
    }
};

export const sendWhatsAppMessage = async (phoneNumber: string, firstName: string, eventName: string, eventDate: string, eventLocation: string, eventLink: string) => {
    try {
        // Construct WhatsApp message
        const message = `🎉 Congratulations! You're all set for ${eventName}!

Your payment has been confirmed and you're officially on the guest list! 

📅 Event Details:
${eventDate}
${eventLocation}

🔗 Your Event Link: ${eventLink}

Please show this message at the entrance to check in.

Can't wait to see you there! ✨`;

        // Send WhatsApp message
        const payload = { to: phoneNumber, message };
        console.log('Payload being sent to sendwhatsapmsg:', payload);

        const sendWhatsappMsg = await axios.post(
            `https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json`,
            payload
        );
        console.log('Message sent successfully with ID:', sendWhatsappMsg.data);
        return sendWhatsappMsg.data;
    } catch (error) {
        console.error('Failed to send WhatsApp message:', error);
        throw error;
    }
};

// Add this new function
export const sendPaymentConfirmationWhatsApp = async (userId: string, eventId: string) => {
    try {
        // Get user details
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (!userDoc.exists()) {
            throw new Error('User not found');
        }
        const userData = userDoc.data();
        const phoneNumber = userData.phone;
        const firstName = userData.firstName || userData.name || 'there';

        // Get event details
        const eventDoc = await getDoc(doc(db, 'Events', eventId));
        if (!eventDoc.exists()) {
            throw new Error('Event not found');
        }
        const eventData = eventDoc.data();
        const eventName = eventData.eventName;
        const eventDate = eventData.eventDate;
        const eventLocation = eventData.eventLocation;
        const eventLink = `https://salonim.co.il/event/${eventId}`;

        // Send WhatsApp message
        await sendWhatsAppMessage(
            phoneNumber,
            firstName,
            eventName,
            eventDate,
            eventLocation,
            eventLink
        );

        console.log('Payment confirmation WhatsApp message sent successfully');
    } catch (error) {
        console.error('Error sending payment confirmation WhatsApp:', error);
        throw error;
    }
};

export const sendPaymentSuccessWhatsApp = async (messageText: string, phoneNumber: string) => {
    try {
        // Send WhatsApp message
        const payload = { to: phoneNumber, message: messageText };
        await axios.post(
            'https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json',
            payload
        );

        console.log('✅ WhatsApp message sent successfully');
        return true;
    } catch (error) {
        console.error('❌ Error sending WhatsApp message:', error);
        throw error;
    }
};






