import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

interface ExistingUserCheckScreenProps {
    onYes: () => void;
    onNo: () => void;
}

const ExistingUserCheckScreen: React.FC<ExistingUserCheckScreenProps> = ({ onYes, onNo }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '24px',
                width: '100%',
                maxWidth: '393px',
                margin: '0 auto',
                backgroundColor: '#F8F9FA',
                borderRadius: '20px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.05)',
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    width: '120px',
                    height: '120px',
                    borderRadius: '50%',
                    backgroundColor: '#E3F2FD',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3,
                    animation: 'pulse 2s infinite',
                }}
            >
                <PhoneIphoneIcon sx={{ fontSize: 60, color: '#1976d2' }} />
            </Box>

            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '32px',
                    marginBottom: '16px',
                    textAlign: 'center',
                }}
            >
                Welcome to Salonim Live!
            </Typography>

            <Typography
                sx={{
                    color: '#64748B',
                    mb: 4,
                    fontSize: '16px',
                    maxWidth: '400px',
                    mx: 'auto',
                    textAlign: 'center',
                }}
            >
                Are you already a user on Salonim Live?
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                    maxWidth: '400px',
                }}
            >
                <Button
                    variant="contained"
                    onClick={onYes}
                    sx={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        py: 1.5,
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: 600,
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#1565c0'
                        }
                    }}
                >
                    Yes, I'm an existing user
                </Button>

                <Button
                    variant="outlined"
                    onClick={onNo}
                    sx={{
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        py: 1.5,
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: 600,
                        textTransform: 'none',
                        '&:hover': {
                            borderColor: '#1565c0',
                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                        }
                    }}
                >
                    No, I'm new here
                </Button>
            </Box>
        </Box>
    );
};

export default ExistingUserCheckScreen; 