import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
import InstagramLandingPage from './components/InstagramLandingPage';
import Events from './pages/Events';
import ConnectionsPage from './pages/ConnectionsPage';
import TabLayout from './shared/components/TabLayout';
import EventDetail from './Features/Event/EventDetail';
import About from './pages/About';
import AboutSalonimLive from './pages/AboutSalonimLive';
import PhoneVerificationModal from './shared/components/PhoneVerificationModal';
import Wizard from './Features/Onboarding/wizard/Wizard';
import FallbackProfilePage from './shared/components/FallbackProfilePage'; 
import { useAuth } from './context/AuthContext';
import StickyHeader from './Features/Profile/components/StickyHeader';
import { hotjar } from 'react-hotjar';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DeletePolicy from './pages/DeletePolicy';
import MyEvent from './Features/Event/MyEvent';
import ProfilePage from './Features/Profile/Profile';
import PaymentPage from './pages/Payment';
import CSAE from './pages/CSAE';
import ViewUserProfile from './Features/Profile/ViewUserProfile';
import AuthCallback from './shared/components/AuthCallback';
import { Snackbar, Button } from '@mui/material';
import { APP_VERSION, BUILD_TIMESTAMP } from './version';
import PaymentProcessing from './pages/PaymentProcessing';

const Layout: React.FC = () => {
  const location = useLocation();
  const isTabHidden = location.pathname === '/onboarding' || location.pathname === '/payment-processing';

  useEffect(() => {
    hotjar.initialize({ id: 5222814, sv: 6 });
  }, []);

  useEffect(() => {
    hotjar.stateChange(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      {!isTabHidden && <StickyHeader />}
      <Outlet />
      {!isTabHidden && <TabLayout children={undefined} />}
    </div>
  );
};

const AppRouter: React.FC = () => {
  const { user } = useAuth();

  useEffect(() => {
    // Check for new version every minute
    const checkVersion = async () => {
      try {
        const response = await fetch('/version.json?t=' + Date.now());
        if (!response.ok) return;
        
        const data = await response.json();
        if (data.buildTimestamp > BUILD_TIMESTAMP) {
          // Clear cache and reload immediately
          if ('caches' in window) {
            caches.keys().then(names => {
              names.forEach(name => {
                caches.delete(name);
              });
            });
          }
          window.location.reload();
        }
      } catch (error) {
        console.error('Error checking version:', error);
      }
    };

    checkVersion();
    const interval = setInterval(checkVersion, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          {/* Valid routes */}
          <Route path="/" element={<AuthCallback />} />
          
          {user ? (
            <>
              <Route path="/profile" element={<ProfilePage user={user} />} />
              <Route path="/events/management/:event_id" element={<MyEvent />} />
              <Route path="/payment/:event_id" element={<PaymentPage />} />
              <Route path="/events" element={<Events />} />
            </>
          ) : (
            <>
              <Route path="/profile" element={<FallbackProfilePage />} />
              <Route path="/events" element={<Events />} />
            </>
          )}
          <Route path="/onboarding" element={<Wizard />} />
          <Route path="/about" element={<About />} />
          <Route path="/connections" element={<ConnectionsPage />} />
          <Route path="/user/:userId" element={<ViewUserProfile />} />
          <Route path="/about-salonim-live" element={<AboutSalonimLive />} />
          <Route path="/verification" element={<PhoneVerificationModal />} />
          <Route path="/events/:event_id" element={<EventDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/DeletePolicy" element={<DeletePolicy />} />
          <Route path="/CSAE" element={<CSAE />} />
          <Route path="/payment" element={<FallbackProfilePage />} />
          <Route path="/payment-processing" element={<PaymentProcessing />} />

          {/* Catch-all route - redirect to events page */}
          <Route path="*" element={<Navigate to="/events" replace />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
