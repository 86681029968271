// Helper functions to safely get user data regardless of auth provider structure

/**
 * Standardizes a user ID to ensure consistent format across the application
 * @param userId The user ID to standardize
 * @returns The standardized user ID
 */
export const standardizeUserId = (userId: string | null | undefined): string => {
    if (!userId) return '';
    
    // If it already has a provider prefix with pipe, return as is
    if (userId.includes('|')) return userId;
    
    // Handle SMS IDs
    if (userId.startsWith('sms')) return `sms|${userId.substring(3)}`;
    
    // Handle Google OAuth2 IDs
    if (userId.startsWith('google-oauth2')) return `google-oauth2|${userId.substring(13)}`;
    
    // For any other format, return as is
    return userId;
};

/**
 * Gets a standardized user ID from a user object
 */
export const getUserId = (user: any): string => {
    console.log('🔍 getUserId input:', {
        user,
        uid: user?.uid,
        userUid: user?.user?.uid,
        multiFactor: user?.multiFactor?.user?.uid
    });

    // Try to get the user ID from various possible locations
    const userId = user?.uid || user?.user?.uid || user?.multiFactor?.user?.uid;
    
    if (!userId) {
        console.log('❌ No user ID found in user object');
        return '';
    }

    // If the ID is already in the correct format, return it
    if (userId.includes('|')) {
        console.log('✅ User ID already in correct format:', userId);
        return userId;
    }

    // Standardize the ID
    const standardizedId = standardizeUserId(userId);
    console.log('🔄 Standardized user ID:', standardizedId);
    return standardizedId;
};

export const getUserDisplayName = (user: any): string => {
    return user?.displayName || // Firebase structure
           user?._delegate?.displayName || // Auth0 structure
           user?._delegate?.name || // Auth0 Google name
           user?.name || // Direct name
           ''; // fallback empty string
};

export const getUserPhoto = (user: any): string => {
    return user?.photoURL || // Firebase structure
           user?._delegate?.photoURL || // Auth0 structure
           user?.picture || // Some auth providers
           ''; // fallback empty string
};

export const getUserEmail = (user: any): string => {
    return user?.email || // Firebase structure
           user?._delegate?.email || // Auth0 structure
           ''; // fallback empty string
};

export const getUserPhone = (user: any): string => {
    return user?.phoneNumber || // Firebase structure
           user?._delegate?.phoneNumber || // Auth0 structure
           ''; // fallback empty string
};

// Function to get standardized user object
export const getStandardizedUser = (user: any) => {
    const uid = getUserId(user);
    console.log('🔍 Standardizing user:', { 
        originalUser: user,
        extractedUid: uid,
        hasUid: Boolean(uid),
        uidType: uid ? typeof uid : 'none',
        uidStartsWith: uid ? uid.substring(0, 4) : 'none'
    });

    return {
        uid,
        displayName: getUserDisplayName(user),
        photoURL: getUserPhoto(user),
        email: getUserEmail(user),
        phoneNumber: getUserPhone(user),
        // Add any other properties that might be needed
        badges: user?._delegate?.badges || user?.badges || [],
        purpose: user?._delegate?.purpose || user?.purpose || [],
        bio: user?._delegate?.bio || user?.bio || '',
        events: user?._delegate?.events || user?.events || []
    };
}; 