import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, Container, Paper, Fade, Zoom, Stack } from '@mui/material';
import { doc, onSnapshot, Timestamp, getDoc } from 'firebase/firestore';
import { db, auth } from '../shared/services/firebaseConfig';
import { PaymentSession, TRANZILA_STATUS_CODES } from '../types/payment';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../context/AuthContext';
import { getStandardizedUser, standardizeUserId } from '../shared/utils/userHelpers';
import { styled } from '@mui/material/styles';
import { sendPaymentSuccessWhatsApp } from '../shared/services/userService';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  textAlign: 'center',
  maxWidth: 400,
  width: '100%',
  margin: '0 auto',
}));

const StatusIcon = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: 25,
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 600,
}));

const PaymentProcessing: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const sessionId = searchParams.get('sessionId');
  const urlStatus = searchParams.get('status');
  const eventId = searchParams.get('eventId');
  const [paymentSession, setPaymentSession] = useState<PaymentSession | null>(null);
  const [showSupport, setShowSupport] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [authChecks, setAuthChecks] = useState(0);
  const [countdown, setCountdown] = useState<number>(3);
  const [showTimeout, setShowTimeout] = useState(false);

  // Get standardized user
  const standardizedUser = getStandardizedUser(user);
  const standardizedUserId = standardizeUserId(standardizedUser.uid);

  // Get status color based on payment status and error code
  const getStatusColor = React.useCallback((): string => {
    if (!paymentSession?.errorCode) return '#1976d2'; // Blue for processing

    const statusCode = TRANZILA_STATUS_CODES[paymentSession.errorCode];
    if (!statusCode) return '#757575'; // Grey for unknown

    switch (statusCode.action) {
      case 'success':
        return '#2e7d32'; // Green
      case 'retry':
        return '#f57c00'; // Orange
      case 'support':
        return '#d32f2f'; // Red
      default:
        return '#757575'; // Grey
    }
  }, [paymentSession?.errorCode]);

  // Validate user ID
  const validateUserId = (userId: string): boolean => {
    if (!userId) {
      console.log('❌ No user ID available');
      setShowSupport(true);
      return false;
    }
    return true;
  };

  // Create error payment session
  const createErrorSession = React.useCallback((errorCode: string, errorMessage: string): PaymentSession => ({
    sessionId: sessionId || 'unknown',
    status: 'failed',
    errorCode,
    errorMessage,
    userId: standardizedUserId,
    eventId: eventId || '',
    amount: 0,
    createdAt: Timestamp.now(),
    expiresAt: Timestamp.fromDate(new Date(Date.now() + 60000))
  }), [sessionId, standardizedUserId, eventId]);

  // Debug log for auth state
  useEffect(() => {
    console.log('👤 Auth State Update:', {
      loading,
      user: !!user,
      standardizedUser,
      authChecks,
      currentUser: auth.currentUser,
      isAuthReady
    });
  }, [loading, user, standardizedUser, authChecks, isAuthReady]);

  // Handle auth state
  useEffect(() => {
    if (loading) {
      console.log('⏳ Auth is still loading...');
      return;
    }

    if (!user && authChecks < 3) {
      console.log('🔄 Waiting for auth state...', { attempt: authChecks + 1 });
      setAuthChecks(prev => prev + 1);
      return;
    }

    const currentUserId = standardizedUserId;
    if (currentUserId) {
      console.log('✅ Auth is ready with userId:', currentUserId);
      setIsAuthReady(true);
    } else {
      console.log('⚠️ No user ID available after auth checks');
      // If we've tried 3 times and still no user, show support
      if (authChecks >= 3) {
        setShowSupport(true);
      }
    }
  }, [loading, user, standardizedUserId, authChecks]);

  // Send WhatsApp message when status=success in URL
  useEffect(() => {
    if (urlStatus === 'success' && standardizedUserId && eventId) {
      console.log('📱 Starting WhatsApp message process:', {
        urlStatus,
        userId: standardizedUserId,
        eventId,
        timestamp: new Date().toISOString()
      });

      // Get user details from Firestore
      const getUserDetails = async () => {
        try {
          console.log('🔍 Fetching user details for:', {
            userId: standardizedUserId,
            path: `users/${standardizedUserId}`,
            timestamp: new Date().toISOString()
          });

          const userDoc = await getDoc(doc(db, 'users', standardizedUserId));
          
          if (!userDoc.exists()) {
            console.error('❌ User document not found:', {
              userId: standardizedUserId,
              exists: userDoc.exists(),
              timestamp: new Date().toISOString()
            });
            return;
          }

          const userData = userDoc.data();
          console.log('📋 User data retrieved:', {
            userId: standardizedUserId,
            hasFirstName: !!userData?.firstName,
            hasName: !!userData?.name,
            hasPhone: !!userData?.phoneNumber,
            timestamp: new Date().toISOString()
          });

          const firstName = userData?.firstName || userData?.name || 'there';
          const phoneNumber = userData?.phoneNumber;

          if (!phoneNumber) {
            console.error('❌ No phone number found for user:', {
              userId: standardizedUserId,
              firstName,
              timestamp: new Date().toISOString()
            });
            return;
          }

          // Construct the message text
          const messageText = `היי ${firstName}! התשלום שלך התקבל בהצלחה! 🎉\n\nלחץ כאן לכניסה לאירוע:\n\n https://salonim.live/events/${eventId}`;

          // Log the message payload before sending
          console.log('📤 WhatsApp message payload:', {
            to: phoneNumber,
            text: messageText,
            timestamp: new Date().toISOString()
          });

          try {
            await sendPaymentSuccessWhatsApp(messageText, phoneNumber);
            console.log('✅ WhatsApp message sent successfully:', {
              userId: standardizedUserId,
              phoneNumber,
              eventId,
              timestamp: new Date().toISOString()
            });
          } catch (error: any) {
            console.error('❌ Error sending WhatsApp message:', {
              error: error?.message || 'Unknown error',
              userId: standardizedUserId,
              phoneNumber,
              eventId,
              timestamp: new Date().toISOString(),
              stack: error?.stack
            });
          }
        } catch (error: any) {
          console.error('❌ Error fetching user details:', {
            error: error?.message || 'Unknown error',
            userId: standardizedUserId,
            timestamp: new Date().toISOString(),
            stack: error?.stack
          });
        }
      };

      getUserDetails();
    } else {
      console.log('⏳ Waiting for required data to send WhatsApp:', {
        hasUrlStatus: !!urlStatus,
        hasUserId: !!standardizedUserId,
        hasEventId: !!eventId,
        urlStatus,
        timestamp: new Date().toISOString()
      });
    }
  }, [urlStatus, standardizedUserId, eventId]);

  // Listen for payment session updates
  useEffect(() => {
    if (!sessionId) {
      console.log('❌ No sessionId found, showing error');
      setPaymentSession(createErrorSession('SESSION_NOT_FOUND', 'Session not found'));
      setShowSupport(true);
      return;
    }

    // If we have a success status in URL, set initial state
    if (urlStatus === 'success') {
      console.log('✅ Success status found in URL');
      setPaymentSession({
        sessionId,
        status: 'success' as const,
        errorCode: '',
        errorMessage: '',
        userId: standardizedUserId,
        eventId: '',
        amount: 0,
        createdAt: Timestamp.now(),
        expiresAt: Timestamp.fromDate(new Date(Date.now() + 60000))
      });
      return;
    }

    // Don't start monitoring until auth is ready and we have a valid user ID
    if (!isAuthReady || !validateUserId(standardizedUserId)) {
      console.log('⏳ Waiting for authentication to be ready:', {
        isAuthReady,
        hasUserId: !!standardizedUserId
      });
      return;
    }

    console.log('🎯 Starting payment session monitoring:', { 
      sessionId, 
      userId: standardizedUserId,
      isAuthReady
    });

    // Listen for payment session updates
    const unsubscribe = onSnapshot(
      doc(db, 'payment_sessions', sessionId),
      async (doc) => {
        if (doc.exists()) {
          const data = doc.data() as PaymentSession;
          console.log('📥 Payment Session Update:', {
            sessionId,
            status: data.status,
            errorCode: data.errorCode,
            userId: data.userId,
            currentUserId: standardizedUserId
          });
          
          setPaymentSession(data);
          
          if (data.status === 'success') {
            console.log('✅ Payment Success Detected');
            try {
              // Get the event ID from sessionStorage or URL param
              const storedEventId = window.sessionStorage.getItem('paymentEventId') || eventId;
              if (storedEventId && standardizedUserId) {
                console.log('📱 Sending WhatsApp confirmation message');
                await sendPaymentSuccessWhatsApp(standardizedUserId, storedEventId);
                console.log('✅ WhatsApp message sent successfully');
              }
            } catch (error) {
              console.error('❌ Error sending WhatsApp message:', error);
              // Don't show error to user, just log it
            }
          }
          
          if (data.status === 'failed') {
            console.log('❌ Payment Failed');
            setShowSupport(true);
          }
        } else {
          console.log('❌ Payment session not found in Firestore');
          setPaymentSession(createErrorSession('SESSION_NOT_FOUND', 'Session not found'));
          setShowSupport(true);
        }
      },
      (error) => {
        console.error('❌ Error monitoring payment session:', error);
        setPaymentSession(createErrorSession('PERMISSION_ERROR', 'Checking payment status...'));
        setShowSupport(false);
      }
    );

    return () => unsubscribe();
  }, [sessionId, urlStatus, isAuthReady, standardizedUserId, createErrorSession, eventId]);

  // Handle successful payment redirect
  useEffect(() => {
    if (paymentSession?.status === 'success') {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            // Get the event ID from sessionStorage
            const storedEventId = window.sessionStorage.getItem('paymentEventId');
            const targetEventId = storedEventId || eventId;
            
            // Clean up and redirect
            window.sessionStorage.removeItem('paymentEventId');
            clearInterval(timer);
            
            if (targetEventId) {
              window.top?.location.replace(`/events/${targetEventId}`);
            } else {
              window.top?.location.replace('/events');
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [paymentSession?.status, eventId]);

  const handleRetry = (): void => {
    // Get the event ID from sessionStorage or URL param
    const storedEventId = window.sessionStorage.getItem('paymentEventId') || eventId;
    console.log('🔄 Redirecting to event:', storedEventId);
    
    if (storedEventId) {
      // Redirect to event page
      window.top?.location.replace(`/events/${storedEventId}`);
    } else {
      // Fallback to events page if no event ID is found
      window.top?.location.replace('/events');
    }
  };

  const handleReturnToEvent = (): void => {
    // Get the event ID from sessionStorage
    const storedEventId = window.sessionStorage.getItem('paymentEventId');
    console.log('🔄 Returning to event:', storedEventId || eventId);
    
    // Use stored event ID if available, fallback to URL param, then default to events page
    if (storedEventId) {
      window.sessionStorage.removeItem('paymentEventId'); // Clean up
      window.top?.location.replace(`/events/${storedEventId}`);
    } else if (eventId) {
      window.top?.location.replace(`/events/${eventId}`);
    } else {
      window.top?.location.replace('/events');
    }
  };

  const handleWhatsAppSupport = (): void => {
    console.log('💬 Opening WhatsApp support chat');
    const message = encodeURIComponent(
      `שלום, אני צריך עזרה עם תשלום. מזהה עסקה: ${sessionId}`
    );
    // Open WhatsApp in a new window/tab
    window.open(`https://wa.me/972XXXXXXXXX?text=${message}`, '_blank');
  };

  // Add custom error messages for special cases
  const getErrorStatus = (errorCode: string): { message: string; action: string; showSupport: boolean } => {
    console.log('🔍 Getting error status for code:', errorCode);
    
    // Handle success case
    if (paymentSession?.status === 'success') {
      return {
        message: 'התשלום בוצע בהצלחה!',
        action: 'success',
        showSupport: false
      };
    }

    if (errorCode === 'SESSION_NOT_FOUND') {
      return {
        message: 'לא נמצא מידע על העסקה. אנא נסה שוב.',
        action: 'retry',
        showSupport: true
      };
    }
    if (errorCode === 'LISTENER_ERROR') {
      return {
        message: 'אירעה שגיאה במעקב אחר סטטוס התשלום. אנא נסה שוב.',
        action: 'retry',
        showSupport: true
      };
    }
    if (errorCode === 'PERMISSION_ERROR') {
      return {
        message: 'ממתין לעדכון סטטוס התשלום...',
        action: 'wait',
        showSupport: false
      };
    }
    return TRANZILA_STATUS_CODES[errorCode] || {
      message: 'אירעה שגיאה לא צפויה',
      action: 'support',
      showSupport: true
    };
  };

  // Add timeout warning after 10 seconds
  useEffect(() => {
    if (!paymentSession) {
      const timeoutId = setTimeout(() => {
        console.log('⚠️ Payment status check timeout');
        setShowTimeout(true);
      }, 10000); // 10 seconds

      return () => clearTimeout(timeoutId);
    }
  }, [paymentSession]);

  if (!paymentSession) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh"
          textAlign="center"
        >
          <Fade in timeout={1000}>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 2,
                width: '100%',
                maxWidth: 400,
                backgroundColor: '#fff',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CircularProgress size={60} sx={{ color: '#1976d2', mb: 2 }} />
              <Typography variant="h6" sx={{ color: '#1a237e', fontWeight: 600 }}>
                ממתין לעדכון סטטוס התשלום...
              </Typography>
              <Typography variant="body2" sx={{ color: '#666', mt: 1 }}>
                אנא המתן בזמן שאנו מטפלים בתשלום שלך
              </Typography>

              {showTimeout && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" sx={{ color: '#d32f2f', mb: 2 }}>
                    נראה שיש בעיה בתשלום
                    <br />
                    אתה יכול לחזור לדף האירוע ולנסות שוב.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReturnToEvent}
                    startIcon={<ArrowBackIcon />}
                    fullWidth
                    sx={{
                      mt: 2,
                      borderRadius: '25px',
                      textTransform: 'none',
                      fontWeight: 600
                    }}
                  >
                    חזור לדף האירוע
                  </Button>
                </Box>
              )}
            </Paper>
          </Fade>
        </Box>
      </Container>
    );
  }

  const status = getErrorStatus(paymentSession?.errorCode || '');
  const statusColor = getStatusColor();

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="80vh"
        textAlign="center"
      >
        <Zoom in timeout={1000}>
          <StyledPaper elevation={3}>
            <StatusIcon sx={{ bgcolor: `${statusColor}15` }}>
              {paymentSession.status === 'success' ? (
                <CheckCircleIcon sx={{ fontSize: 40, color: statusColor }} />
              ) : (
                <ErrorIcon sx={{ fontSize: 40, color: statusColor }} />
              )}
            </StatusIcon>

            <Typography variant="h5" gutterBottom>
              {paymentSession.status === 'success' ? 'Payment Successful!' : 'Payment Failed'}
            </Typography>

            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              {status.message}
            </Typography>

            {paymentSession.status === 'success' ? (
              <>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                  מועבר לדף האירוע בעוד {countdown} שניות...
                </Typography>
                <CircularProgress size={24} sx={{ mt: 2, color: '#2e7d32' }} />
              </>
            ) : (
              <Stack spacing={2} sx={{ mt: 2 }}>
                {status.action === 'retry' && (
                  <ActionButton
                    variant="contained"
                    color="error"
                    onClick={handleRetry}
                    fullWidth
                  >
                    Try Again
                  </ActionButton>
                )}
                <ActionButton
                  variant="outlined"
                  color="primary"
                  onClick={handleReturnToEvent}
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                >
                  Return to Event
                </ActionButton>
                {(status.showSupport || showSupport) && (
                  <ActionButton
                    variant="outlined"
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                    onClick={handleWhatsAppSupport}
                    fullWidth
                  >
                    Contact Support
                  </ActionButton>
                )}
              </Stack>
            )}
          </StyledPaper>
        </Zoom>
      </Box>
    </Container>
  );
};

export default PaymentProcessing; 