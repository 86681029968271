import React, { useEffect, useState } from "react";
import { useAuth } from '../context/AuthContext';
import { useLocation, useParams } from "react-router-dom";
import StagingIndicator from '../shared/components/StagingIndicator';
import { db } from '../shared/services/firebaseConfig';
import { collection, addDoc, setDoc, doc, Timestamp } from 'firebase/firestore';
import { PaymentSession } from '../types/payment';
import { standardizeUserId, getStandardizedUser } from '../shared/utils/userHelpers';
import { auth } from '../shared/services/firebaseConfig';

// Declare jQuery globally for TypeScript
declare global {
  interface Window {
    jQuery?: any;
    $?: any;
    $n?: any;
  }
}

const PaymentPage = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const { event_id_route } = useParams();
  const { event_id } = location.state?.data || event_id_route || "";

  // Get the current domain
  const currentDomain = window.location.hostname;
  const isStaging = currentDomain === 'staging.salonim.live';
  const baseUrl = isStaging ? 'https://staging.salonim.live' : 'https://salonim.live';

  useEffect(() => {
    // Scroll to top immediately on page load
    window.scrollTo(0, 0);
  
    // Define a handler to scroll to top on window focus
    const handleFocus = () => {
      window.scrollTo(0, 0);
    };
  
    window.addEventListener('focus', handleFocus);
  
    const loadJQuery = () => {
      return new Promise<void>((resolve) => {
        if (window.jQuery) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        script.onload = () => resolve();
        document.head.appendChild(script);
      });
    };

    const loadTranzilaScript = () => {
      return new Promise<void>((resolve) => {
        const script = document.createElement("script");
        script.src = `https://direct.tranzila.com/js/tranzilanapple_v3.js?v=${Date.now()}`;
        script.onload = () => resolve();
        document.head.appendChild(script);
      });
    };

    const loadScripts = async () => {
      await loadJQuery();
      await loadTranzilaScript();
      window.$n = window.jQuery?.noConflict(true);
    };

    loadScripts();

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      console.log('⚠️ No user available yet:', { user });
      return;
    }
    
    const createPaymentSession = async () => {
      // Verify auth state first
      const currentUser = auth.currentUser;
      console.log('🔐 Auth State Check:', {
        isUserAuthenticated: !!user,
        isAuthCurrentUserAvailable: !!currentUser,
        authCurrentUser: {
          uid: currentUser?.uid,
          providerId: currentUser?.providerId,
          isAnonymous: currentUser?.isAnonymous,
          emailVerified: currentUser?.emailVerified,
        },
        authStateUser: user
      });

      if (!currentUser) {
        console.error('❌ User not properly authenticated');
        return;
      }

      console.log('🔄 Starting payment session creation...', {
        rawUser: user,
        auth: auth.currentUser,
        event_id,
        locationState: location.state?.data
      });

      const sessionId = `payment_${Math.floor(Date.now() / 1000)}${Math.floor(Math.random() * 1000)}_${Math.random().toString(36).substring(2, 8)}`;
      console.log('📝 Generated session ID:', sessionId);
      
      try {
        // Get standardized user
        const standardizedUser = getStandardizedUser(user);
        console.log('👤 Standardized user:', {
          original: user,
          standardized: standardizedUser,
          uid: standardizedUser.uid,
          phoneNumber: standardizedUser.phoneNumber,
          authUid: currentUser.uid
        });

        // Verify the standardized ID matches auth
        if (currentUser.uid !== standardizedUser.uid) {
          console.warn('⚠️ User ID mismatch:', {
            authUid: currentUser.uid,
            standardizedUid: standardizedUser.uid
          });
        }

        const standardizedUserId = standardizeUserId(standardizedUser.uid);
        console.log('🆔 User ID processing:', {
          originalUid: standardizedUser.uid,
          standardizedUid: standardizedUserId,
          authCurrentUser: currentUser.uid,
          matches: standardizedUserId === currentUser.uid
        });

        if (!standardizedUserId) {
          console.error('❌ No valid user ID found after standardization');
          return;
        }

        // Wait a moment to ensure auth state is fully propagated
        await new Promise(resolve => setTimeout(resolve, 1000));

        const paymentSession: PaymentSession = {
          sessionId,
          userId: standardizedUserId,
          eventId: event_id,
          amount: location.state.data.eventPrice,
          status: 'pending',
          createdAt: Timestamp.now(),
          expiresAt: Timestamp.fromDate(new Date(Date.now() + 60000))
        };

        console.log('💾 Attempting to create payment session:', {
          session: paymentSession,
          isAuthenticated: !!currentUser,
          currentAuthUid: currentUser.uid,
          standardizedUid: standardizedUserId
        });

        await setDoc(doc(db, 'payment_sessions', sessionId), paymentSession);
        console.log('✅ Payment session created successfully');

        const successUrl = `${baseUrl}/payment-processing?sessionId=${sessionId}&status=success&eventId=${event_id}`;
        const failUrl = `${baseUrl}/payment-processing?sessionId=${sessionId}&status=failed&eventId=${event_id}`;
        const notifyUrl = `https://us-central1-salonim-29dcf.cloudfunctions.net/tranzillaWebhook?data=${standardizedUserId}_${event_id}_${encodeURIComponent(sessionId)}`;

        // Store event ID in sessionStorage
        window.sessionStorage.setItem('paymentEventId', event_id);

        console.log('🔗 Generated URLs:', {
          success: successUrl,
          fail: failUrl,
          notify: notifyUrl
        });

        const iframeUrl = new URL('https://direct.tranzila.com/fxpsalonimhi/iframenew.php');
        iframeUrl.searchParams.append('time', Math.floor(Date.now() / 1000).toString());
        iframeUrl.searchParams.append('nologo', '1');
        iframeUrl.searchParams.append('currency', '1');
        iframeUrl.searchParams.append('notify_url_address', notifyUrl);
        iframeUrl.searchParams.append('sum', location.state.data.eventPrice.toString());
        iframeUrl.searchParams.append('success_url_address', successUrl);
        iframeUrl.searchParams.append('fail_url_address', failUrl);
        iframeUrl.searchParams.append('bit_pay', '1');
        iframeUrl.searchParams.append('google_pay', '1');
        iframeUrl.searchParams.append('allowpaymentrequest', 'true');

        console.log('🖼️ Setting iframe URL:', iframeUrl.toString());
        setIframeUrl(iframeUrl.toString());
      } catch (error) {
        console.error('❌ Error creating payment session:', error);
        // Log additional context about the error
        console.error('Error context:', {
          user,
          standardizedUser: getStandardizedUser(user),
          auth: auth.currentUser,
          sessionId,
          event_id,
          error: error instanceof Error ? {
            name: error.name,
            message: error.message,
            stack: error.stack
          } : error
        });
      }
    };

    createPaymentSession();
  }, [user, baseUrl, event_id, location.state?.data?.eventPrice]);

  if (!user) {
    return <p>Loading payment...</p>;
  }

  return (
    <div style={{ 
      width: "100%",
      height: "calc(100vh - 90px)", // Subtract header height plus safe margin
      marginTop: "90px", // Space for header plus safe margin
      backgroundColor: "#fff",
      overflow: "hidden"
    }}>
      {isStaging && <StagingIndicator />}
      {iframeUrl ? (
        <iframe
          id="payment-iframe"
          allow="payment; cross-origin-isolated"
          sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
          src={iframeUrl}
          style={{ 
            width: "100%", 
            height: "100%", 
            border: "none",
            display: "block"
          }}
          title="Payments"
        />
      ) : (
        <div style={{ 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center", 
          height: "100%" 
        }}>
          <p>Loading payment form...</p>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
