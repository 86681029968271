// src/eventsService.js
import { auth, db } from './firebaseConfig';
import {
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where,
} from 'firebase/firestore';

export const checkIfUserPaidForEvent = async (eventID: string, userId: string) => {
    try {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            try {
                const userDocRef = doc(db, 'transactions', `${userId}_${eventID}`); // Assuming 'users' is your collection
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    console.log('Transaction Document:', userDocSnap.data());
                    return userDocSnap.data();
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching user document:', error);
            }
        } else {
            console.error('No user is signed in.');
        }
    } catch (error) {
        console.error('Error fetching user by document:', error);
        throw error; // You can also handle this error differently if needed
    }
};

export const getUserTicket = async (eventID: string, userId: string) => {
    try {
        if (!userId || !eventID) {
            console.error('Missing userId or eventID');
            return null;
        }

        console.log('Checking ticket for:', { eventID, userId });

        try {
            // Try with direct document ID format
            const userDocRef = doc(db, 'transactions', `${eventID}_${userId}`);
            console.log('Looking up ticket at path:', userDocRef.path);
            
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const ticketData = userDocSnap.data();
                console.log('Found ticket:', ticketData);
                return ticketData;
            }

            // If not found with eventID_userId, try userId_eventID format
            const alternateDocRef = doc(db, 'transactions', `${userId}_${eventID}`);
            console.log('Trying alternate path:', alternateDocRef.path);
            
            const alternateDocSnap = await getDoc(alternateDocRef);

            if (alternateDocSnap.exists()) {
                const ticketData = alternateDocSnap.data();
                console.log('Found ticket in alternate format:', ticketData);
                return ticketData;
            }

            console.log('No ticket found in either format');
            return null;
        } catch (error) {
            console.error('Error fetching ticket:', error);
            return null;
        }
    } catch (error) {
        console.error('Error in getUserTicket:', error);
        return null;
    }
};

