import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Container, CircularProgress, Modal, Typography } from '@mui/material';
import EventList from '../Features/Event/EventList';
import { getEvents } from '../shared/services/eventServices';
import { useAuth } from '../context/AuthContext';
import Ticket from '../Features/Event/components/Ticket';
import InstagramLandingPage from '../components/InstagramLandingPage';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

interface Event {
    id: string;
    event_name: string;
    event_date: Date;
    event_image: string;
    location: string;
    locationName: string;
    locationDetails?: string;
    event_time?: string;
    description?: string;
    guests?: Array<{
        user_id: string;
        status: string;
        username: string;
        image: string;
    }>;
    status?: string;
    isUpcoming?: boolean;
    daysRemaining?: number | null;
}

const Events: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const { user } = useAuth();
    const lastEventRef = useRef<HTMLDivElement>(null);
    const ITEMS_PER_PAGE = 10;
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [showInstagram, setShowInstagram] = useState(false);

    // Check if v=1 parameter is present
    const isInstagramView = searchParams.get('v') === '1';

    // Handle Instagram view and URL parameter removal
    useEffect(() => {
        if (isInstagramView && !showInstagram) {
            setShowInstagram(true);
            // Remove the v=1 parameter from URL
            const newPath = location.pathname;
            navigate(newPath, { replace: true });
        }
    }, [isInstagramView, showInstagram, location.pathname, navigate]);

    const loadMoreEvents = useCallback(async () => {
        if (loading || !hasMore) return;

        try {
            console.log('Loading more events, page:', page);
            setLoading(true);
            const newEvents = await getEvents(undefined, undefined, page + 1, ITEMS_PER_PAGE);
            
            if (Array.isArray(newEvents) && newEvents.length > 0) {
                // Check for duplicates before adding new events
                const existingIds = new Set(events.map(event => event.id));
                const uniqueNewEvents = newEvents.filter(event => !existingIds.has(event.id));
                
                if (uniqueNewEvents.length > 0) {
                    setEvents(prevEvents => [...prevEvents, ...uniqueNewEvents]);
                    setPage(prevPage => prevPage + 1);
                    setHasMore(uniqueNewEvents.length === ITEMS_PER_PAGE);
                } else {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error loading events:', error);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, events, page]);

    // Initial load effect
    useEffect(() => {
        const loadInitialEvents = async () => {
            try {
                setLoading(true);
                const initialEvents = await getEvents(undefined, undefined, 1, ITEMS_PER_PAGE);
                if (Array.isArray(initialEvents) && initialEvents.length > 0) {
                    setEvents(initialEvents);
                    setHasMore(initialEvents.length === ITEMS_PER_PAGE);
                } else {
                    setHasMore(false);
                }
            } catch (error) {
                console.error('Error loading initial events:', error);
            } finally {
                setLoading(false);
            }
        };

        loadInitialEvents();
    }, []);

    // Intersection Observer for infinite scroll
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const lastEntry = entries[0];
                if (lastEntry.isIntersecting && hasMore && !loading) {
                    console.log('Last event is visible, loading more events');
                    loadMoreEvents();
                }
            },
            { threshold: 0.1 }
        );

        const currentRef = lastEventRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasMore, loading, loadMoreEvents]);

    useEffect(() => {
        loadMoreEvents();
    }, [loadMoreEvents]);

    // If Instagram view is requested, show InstagramLandingPage
    if (showInstagram) {
        return <InstagramLandingPage />;
    }

    return (
        <Container 
            maxWidth="sm" 
            disableGutters
            sx={{ 
                padding: 0,
                marginTop: { xs: '72px', sm: '80px' },
                minHeight: 'calc(100vh - 72px)',
                backgroundColor: '#f8f9fa',
                overflowX: 'hidden',
                '& > *': {
                    maxWidth: '100%'
                }
            }}
        >
            <EventList
                events={events}
                loading={loading}
                onLoadMore={loadMoreEvents}
                hasMore={hasMore}
                lastEventRef={lastEventRef}
            />
            {/* Ticket Modal */}
            <Modal
                open={openTicketModal}
                onClose={() => setOpenTicketModal(false)}
                aria-labelledby="ticket-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    maxWidth: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: 24,
                    p: 0,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    outline: 'none'
                }}>
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Loading ticket...</Typography>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default Events;
